/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTooltipContainer
 *
 * @restrict EA
 *
 * @description
 * Helper component for {@link halyk-ebanking-shared.directive:cbTooltip cb-tooltip}.
 */

angular.module( 'halyk-ebanking-shared').directive('cbTooltipContainer', function(pathService, tooltips, events, $timeout) {
	return {
        restrict: 'AE',
        replace: true,
        templateUrl: pathService.generateTemplatePath() + ebLayoutModule.path + '/directives/cbTooltip/cbTooltipContainer.html',
        link: function(scope, element, attrs) {

            scope.caretOffset = 10;
            scope.edgeOffset = 15;
            scope.hideTimeout = 500;

            // Dynamically change tooltip's position
            window.addEventListener("resize", function() {
                if(scope.visible) {
                    scope.tooltipShowHandler(null, scope.tooltipData);
                }
            });

            scope.tooltipShowHandler = function(event, tooltipData) {

                scope.hiding = false;
                scope.tooltipData = tooltipData;
                scope.header = tooltipData.header;
                scope.text = tooltipData.text;
                scope.viewUrl = tooltipData.viewUrl;
                scope.side = tooltipData.side;
                scope.handler = tooltipData.handler;
                scope.icon = tooltipData.icon;
                scope.iconBB = tooltipData.icon.getBoundingClientRect();
                scope.visible = true;

                scope.$apply();

                var tooltipWidth = element[0].offsetWidth;
                var tooltipHeight = element[0].offsetHeight;
                var iconTop = scope.getRootOffsetTop(scope.icon, 0);
                var iconLeft = scope.getRootOffsetLeft(scope.icon, 0);

                var body = document.body,
                    html = document.documentElement,
                    height = Math.min(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight),
                    width = Math.min(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);

                // If doesn't fit in current side, set side to 'top' or 'bottom'
                if (scope.side == "left" && (iconLeft - tooltipWidth - scope.caretOffset < scope.edgeOffset) ||
                    scope.side == "right" && (iconLeft + scope.iconBB.width + tooltipWidth + scope.caretOffset > width - scope.edgeOffset)) {
                    scope.side = "top";
                }
                if (scope.side == "top" && (iconTop - tooltipHeight - scope.caretOffset < scope.edgeOffset)) {
                    scope.side = "bottom";
                } else if (scope.side == "bottom" && (iconTop + scope.iconBB.height + tooltipHeight + scope.caretOffset > height - scope.edgeOffset)) {
                    scope.side = "top";
                }

                if (scope.side == "top" || scope.side == "bottom") {
                    scope.left = iconLeft + scope.iconBB.width/2 - tooltipWidth/2;

                    // If doesn't fit horizontally to the right, put in the middle of the screen
                    if (iconLeft + scope.iconBB.width/2 + tooltipWidth/2 > width - scope.edgeOffset) {
                        scope.difference = iconLeft + scope.iconBB.width/2 - width/2;
                        scope.left = width/2 - tooltipWidth/2;

                        // Set arrow position
                        angular.element(document.querySelector(".eb-tooltip-caret .bef"))[0].style.left = (50 + scope.difference / tooltipWidth * 100).toString() + "%";
                        angular.element(document.querySelector(".eb-tooltip-caret .aft"))[0].style.left = (50 + scope.difference / tooltipWidth * 100).toString() + "%";
                    } else if (iconLeft + scope.iconBB.width/2 - tooltipWidth/2 < scope.edgeOffset) {
                        // If doesn't fit horizontally to the left and the caret can be placed, put in the middle of the screen
                        if ((width - tooltipWidth) / 2 < iconLeft - 10) {
                            scope.difference = iconLeft + scope.iconBB.width/2 - (width - tooltipWidth) / 2;
                            scope.left = width/2 - tooltipWidth/2;
                        } else {
                            scope.difference = iconLeft + scope.iconBB.width/2 - scope.edgeOffset;
                            scope.left = scope.edgeOffset;
                        }

                        // Set arrow position
                        angular.element(document.querySelector(".eb-tooltip-caret .bef"))[0].style.left = (scope.difference / tooltipWidth * 100).toString() + "%";
                        angular.element(document.querySelector(".eb-tooltip-caret .aft"))[0].style.left = (scope.difference / tooltipWidth * 100).toString() + "%";
                    } else {
                        // Set default arrow position
                        angular.element(document.querySelector(".eb-tooltip-caret .bef"))[0].style.left = "50%";
                        angular.element(document.querySelector(".eb-tooltip-caret .aft"))[0].style.left = "50%";
                    }

                    // Tooltip's vertical position
                    if (scope.side == "top") {
                        var tooltipTop = iconTop - tooltipHeight - scope.caretOffset;
                    } else {
                        var tooltipTop = iconTop + scope.iconBB.height + scope.caretOffset;
                    }
                } else {
                    // If side is 'left' or 'right', reset arrow's vertical position
                    angular.element(document.querySelector(".eb-tooltip-caret .bef"))[0].style.left = "";
                    angular.element(document.querySelector(".eb-tooltip-caret .aft"))[0].style.left = "";

                    var tooltipTop = iconTop + scope.iconBB.height/2 - tooltipHeight/2;

                    // Tooltip's horizontal position
                    if (scope.side == "left") {
                        scope.left = iconLeft - tooltipWidth - scope.caretOffset;
                    } else {
                        scope.left = iconLeft + scope.iconBB.width + scope.caretOffset;
                    }
                }

                // Limit tooltip's vertical position
                scope.top = Math.max(0, tooltipTop);

                // Set tooltip's position
                scope.positionStyle = {
                    top: scope.top.toString() + "px",
                    left: scope.left.toString() + "px",
                    opacity: 1
                };

                scope.$apply();
            };

            scope.tooltipHide = function() {
                if(scope.hiding) {
                    scope.header = null;
                    scope.text = null;
                    scope.viewUrl = null;
                    scope.positionStyle = {
                        top: "0px",
                        left: "0px",
                        opacity: 0
                    };
                    scope.top = 0;
                    scope.left = 0;
                    scope.visible = false;
                }
                scope.hiding = false;
            };

            scope.tooltipHideHandler = function(event, tooltipData) {
                scope.hiding = true;
                if (scope.handler == "click") {
                    scope.tooltipHide();
                } else {
                    $timeout(scope.tooltipHide, scope.hideTimeout);
                }
            };

            scope.mouseoverHandler = function() {
                if (scope.handler != "click") {
                    scope.hiding = false;
                }
            };

            scope.mouseoutHandler = function() {
                if (scope.handler != "click") {
                    tooltips.hideTooltip();
                }
            };

            scope.closeTooltip = function() {
                tooltips.hideTooltip();
            };

            scope.getRootOffsetLeft = function(elem, val){
                if (elem.offsetParent === null) {
                    return val + elem.offsetLeft;
                } return scope.getRootOffsetLeft(elem.offsetParent, val + elem.offsetLeft);
            };

            scope.getRootOffsetTop = function (elem, val){
                if (elem.offsetParent === null) {
                    return val + elem.offsetTop;
                } return scope.getRootOffsetTop(elem.offsetParent, val + elem.offsetTop);
            };

            scope.$on('$stateChangeStart', function() {
                scope.closeTooltip();
            });

            events.listener(scope, "eb.layout.tooltipShow", scope.tooltipShowHandler);
            events.listener(scope, "eb.layout.tooltipHide", scope.tooltipHideHandler);

            element.bind("mouseover", scope.mouseoverHandler);
            element.bind("mouseout", scope.mouseoutHandler);

            scope.tooltipHide();
        }
    };
});
