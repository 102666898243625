/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbCollapse
 * @restrict EA
 * @scope
 *
 * @description
 * A container to be collapsed and rised by user actions.
 *
 * @param {expression} onOpen Action to be executed when the container is rised.
 * @param {boolean=} isCollapsed Initial state.
 * @param {boolean=} hideCollapseBar Flag indicating that collapse bar should be hidden.
 * @param {object} ebPromise Promise to resolve collapse contents.
 */

angular.module( 'halyk-ebanking-shared').directive('cbCollapse', function(pathService, ebTheming, $window, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
        	collapseTrigger: '=', // TODO not used any more!!!
        	ebName: "@", // TODO not used any more!!!
        	ebModel: "=", // TODO not used any more!!!
            onOpen: "&",
            isCollapsed: "=?",
            hideCollapseBar: "=?",
            promise: '=ebPromise'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbCollapse/cbCollapse.html",
        link: function postLink(scope, element, attrs) {
            ebTheming.applyTheme(element);

            scope.selectable = angular.isDefined(attrs.selectable) ? true : false;
            if (scope.isCollapsed == undefined) {
                scope.isCollapsed = true;
            }
            scope.toggleCollapse = function () {
                scope.isCollapsed = !scope.isCollapsed;
                if (!scope.isCollapsed) {
                    scope.onOpen();
                }
            }

            //scroll down when expanding

            var container;
            var heightListenerOffFn;
            var heightHandlerOffFn;

            var onExpandComplete = function(){
                if(heightListenerOffFn) heightListenerOffFn();
                if(heightHandlerOffFn) heightHandlerOffFn();
                container = null;
            };

            var isExpadedCompletely = function(){
                if(angular.element(container).hasClass('in')){
                    onExpandComplete();
                }
            };

            var heightListener = function () {
                scope.containerHeight = element[0].getBoundingClientRect().height;
            };

            var heightHandler = function (h) {
                var wH = $window.innerHeight;
                var wOffset = $window.pageYOffset;
                var top = element[0].getBoundingClientRect().top;
                var absoluteY = wOffset + top + h;
                var anchor = absoluteY - wH;
                if (top + h > wH) {
                    $window.scrollTo(0, anchor);

                }
                $timeout(isExpadedCompletely);
            };

            var expandBeginOffFn = scope.$watch('isCollapsed', function (isCollapsed, prev) {
                if (!isCollapsed) {
                    container = element[0].querySelector('.collapse-container');
                    heightListenerOffFn = scope.$watch(heightListener);
                    heightHandlerOffFn = scope.$watch('containerHeight', heightHandler);
                }
            });

            scope.$on('$destroy', function () {
                onExpandComplete();
                expandBeginOffFn();
            });

        }


    };
});
