/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbMakePaymentDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module( 'halyk-ebanking-shared').directive( 'cbMakePaymentDialog' , function ( pathService, viewStateService ) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            title: '@ebTitle',
            fromAccount: '=',
            paymentList: '=',
            close: '&ebOnClose',
            callback: '&',
            isLegalEntity: '=',
            isIndividualCustomer: '='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbMakePaymentDialog/cbMakePaymentDialog.html"
		,link: function postLink ( scope , element , attrs ) {		
			scope.wrapCallback = function (sms) {
				return scope.callback ( { sms : sms } ).then ( function ( result ) { return result; } ); 
			}
		} 
    };
});
