angular.module( 'halyk-ebanking-shared').factory('isNullCheckService', function(  ){
    
    function isNullOrWhiteSpace ( inputString ) {

        if ( inputString == null || inputString === undefined )
            return true;

        var withoutWhiteSpace = inputString.toString().replace ( /\s+/g , '' );

        if ( withoutWhiteSpace == '' )
            return true;
        return false; 
    };

    function isNotNullOrWhiteSpace ( inputString ) {

        return isNullOrWhiteSpace ( inputString ) == false;
    };

    function isNotEmptyArray ( inputObject ) {

        return angular.isArray ( inputObject ) && inputObject.length > 0;
    };

    return {
         /**
            * @ngdoc function
            * @name isNullOrWhiteSpace
            * @methodOf halyk-ebanking-shared.service:isNullCheckService
            * @kind function
            *
            * @description
            * Checking whether a string is empty.
            *
            *
            * @param {String} string for checking.
            * @returns {Boolean} true or false  - result of checking is string is empty.
        */
        isNullOrWhiteSpace: function ( inputObject ) { 
            return isNullOrWhiteSpace ( inputObject );
        }
         /**
            * @ngdoc function
            * @name isNotNullOrWhiteSpace
            * @methodOf halyk-ebanking-shared.service:isNullCheckService
            * @kind function
            *
            * @description
            * Checking whether a string is not empty.
            *
            *
            * @param {String} string for checking.
            * @returns {Boolean} true or false  - result of checking is string is not empty.
        */
        , isNotNullOrWhiteSpace: function ( inputObject ) { 
            return isNotNullOrWhiteSpace ( inputObject );
        }
         /**
            * @ngdoc function
            * @name isNotEmptyArray
            * @methodOf halyk-ebanking-shared.service:isNullCheckService
            * @kind function
            *
            * @description
            * Checking whether a object is array and not empty.
            *
            *
            * @param {Object} string for checking.
            * @returns {Boolean} true or false  - result of checking is object is array and not empty.
        */
        , isNotEmptyArray: function ( inputObject ) { 
            return isNotEmptyArray ( inputObject );
        }
    };
});