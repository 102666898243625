/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbRadioCheckbox
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Checkbox component that behaves like radio button.
 *
 * @param {boolean=} ebModel Target model where the checkbox value will be published.
 * @param {string} ebName Name of the checkbox input.
 * @param {string} ebValue Value of the checkbox - if check this value will be populated to the target model.
 */

angular.module( 'halyk-ebanking-shared').directive('cbRadioCheckbox', function(pathService,ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
        	ebModel: '=',
        	ebName: '@',
            ebValue: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbRadioCheckbox/cbRadioCheckbox.html",
        compile: function compile(element, attrs) {
        	// element.html(element.html().replace(/##ebName##/g, attrs.ebName));
            return {
                post: function postlink(scope,element,attrs) {
                var input = element.find('input');

                scope.selectinput = function () {
                    scope.ebModel = input[0].defaultValue;
                };

                scope.contextPath = pathService.generateTemplatePath('halyk-ebanking-shared')+"/styles/images/";
                }
            }
        }
    };
});
