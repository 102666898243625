/**
 * Created by Kvitko on 27.09.2017.
 */
angular.module( 'halyk-ebanking-shared').directive('cbAccordion', function(
      $compile
    , $rootScope
    , pathService
    , translate) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            title: "@",
            list: "=",
            callback: "&",
            accWidth: "="
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAccordion/cbAccordion.html',

        link: function postLink( scope, element, attrs ) {
              scope.isCollapsed = false;

              scope.collapseBody =function (){
                  scope.isCollapsed = !scope.isCollapsed;
              }
        }
    };

});