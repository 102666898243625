
/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSlideWidget
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Slide component dedicated for widget tile.
 *
 * @param {string} content URL of template of slide content.
 * @param {object=} items Data object for the slide content as received from resource service.
 * @param {promise} ebItemsPromise Promise object reassosiated with content data - used to controll state of slider view (e.g. presenting loading spinner until data is ready).
 * @param {number=} maxIndicators Maximum number of presented slide item anchors.
 * @param {expression} ebCallback Function performed for each received slide item data. The function takes one object argument with `items` property of an item value and should return a promise. Its usefull in case where additional data need to be collected for each item.
 * @param {expression} ebUpdate Function for getting lazy loaded chunks of items. The function takes one object argument with three properties: `ctx` contains current items object, `params` contains object of parameters for action to perform, `rel` contains name of action to perform.
 * @param {object} ebSelectedItem Model for the current item.
 * @param {expression} isSmall If value of this expression evaluates to truthy value slide widget is presented on small view of widget.
 * @param {expression} clickCallback Function performed when user clicks slide content. The function takes two arguments - event object and current item object.
 */
angular.module( 'halyk-ebanking-shared').directive('cbSlideWidget', function(pathService, commonService, userService, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            totalElements: '@',
        	content: '@',
        	items: '=',
            itemsPromise: '=ebItemsPromise',
        	maxIndicators: "=",
        	showAdditionalBlock: "=",
            ebCallback: '&?',
            ebUpdate: '&?',
            selectedItem: '=?ebSelectedItem',
            isSmall: '&',
            clickCallback: '&?',
            callback: '&?',
            selectCallback: '&?'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbSlideWidget/cbSlideWidget.html",
        link: function postLink(scope, element, attrs) {

            var mouseXStart;
            var mouseYStart;
            var mouseXStop;
            var mouseYStop;

            var MOVE_BUFFER_RADIUS = 40;
            if ( angular.isDefined( scope.totalElements ) && scope.totalElements == 0){
                return;
            }

            var elem = element.querySelectorAll("#slide-widget-content")[0];
            angular.element(elem).bind("mousedown touchstart", function(event) {
                mouseXStart = event.clientX;
                mouseYStart = event.clientY;

            });
            angular.element(elem).bind("mouseup touchend", function(event) {
                mouseXStop = event.clientX;
                mouseYStop = event.clientY;

                if(event.target.name !== 'revoke-btn'){
                    scope.slideEvent();
                }

            });

            angular.element(elem).bind("click", function(event) {
                var totalX = Math.abs(mouseXStart - mouseXStop);
                var totalY = Math.abs(mouseYStart - mouseYStop);

                if(totalX > MOVE_BUFFER_RADIUS || totalY > MOVE_BUFFER_RADIUS) {
                    event.stopPropagation();
                }
            });

            scope.preloaderControl = false;
			scope.newItemPromise = true;
			

        	scope.itemsContentContainer = {};
            var itemsContentContainerControll = {};
            var block = false;

        	scope.centerIndicator = parseInt(scope.maxIndicators/2);

            var clickDelay = 300;
            var disabled = false;
        	scope.goPrev = function() {
                if (!disabled) {
                    scope.carouselIndex -= 1;
					scope.items.pageNumber -= 1;
                    disabled = true;
                    $timeout(function () { disabled = false; }, clickDelay, false);
                }
        	}
        	scope.goNext = function() {
                if (!disabled) {
                    if(!block) {

						scope.carouselIndex += 1;
						scope.items.pageNumber += 1;
						/*
                        if(scope.carouselIndex  >= scope.items.pageNumber) {
                            scope.getNextPages();
                        }*/
                    }
                    disabled = true;
                    $timeout(function () { disabled = false; }, clickDelay, false);
                }
        	}

        	scope.goToSlideNumber = function(number) {
                if(!block) {
    				scope.carouselIndex = number;
    				if(scope.carouselIndex  >= scope.items.pageNumber) {
    					scope.getNextPages();
    				}
					scope.items.pageNumber = number;
                }
        	}

        	scope.getNextPages = function() {
    			if(angular.isDefined(scope.items._links)
				 && angular.isDefined(scope.items._links.next)) {
    				scope.itemsContentContainer.push(0);
                    block = true;
                    userService.getIdentityDetails().then(function(identity) {
                        scope.newItemPromise = scope.ebUpdate({ctx: scope.items, params: {customerId: identity.id}, rel:"next"}).then(function(data) {
                            block = false;
                            var iterator = scope.itemsContentContainer.length - 1;
                            scope.items = data;
                            scope.itemsContentContainer.pop();
                            angular.forEach(scope.items.content, function(item){
                                scope.ebCallback({items : item}).then(function(value) {
									if ( iterator < scope.itemsContentContainer -1 ) {
										scope.itemsContentContainer[iterator] = value;
										iterator++;
									};
                                })
                            });
                            
                        });
                    });
        		}
        	}

            scope.slideEvent = function() {
                if(scope.carouselIndex == scope.itemsContentContainer.length-2 && scope.itemsContentContainer.length <= scope.items.totalElements) {
                    scope.getNextPages();
                }
            }

        	scope.$watch('items', function(newItems) {
        	 	if( angular.isDefined( newItems ) && newItems.totalElements > 0) {
        	 		if(newItems.pageNumber > 1) {
						for(var i = 0; i < newItems.content.length; i++) {
							itemsContentContainerControll.push(newItems.content[i]);
                            scope.itemsContentContainer = angular.copy(itemsContentContainerControll);
                            scope.selectedItem = scope.itemsContentContainer[scope.carouselIndex];
						}
        	 		} else {
						scope.newItemPromise = false;
        	 			scope.itemsContentContainer = newItems.content;
                        itemsContentContainerControll = newItems.content;
						scope.carouselIndex = scope.carouselIndex > scope.itemsContentContainer.length-1 ? scope.itemsContentContainer.length -1 : scope.carouselIndex;
                        scope.selectedItem = scope.itemsContentContainer ? scope.itemsContentContainer[scope.carouselIndex] : null;
        	 		}
        	 	}
        	});

        	scope.getNumber = function(number) {
        		var array = [];
				for (var i = 0; i < number; i++) {
				    array.push(i);
				}
        		return array;
        	};

             scope.$watch('preloader', function(newPreloaderValue) {
                if(newPreloaderValue) {
                    $timeout(function() {
                        scope.preloaderControl = newPreloaderValue;
                    }, 50);
                }
             });

            scope.$watch('carouselIndex', function(newIndex, oldIndex) {
                if( newIndex !== undefined && newIndex != oldIndex
                   && newIndex >= 0 && angular.isDefined(scope.itemsContentContainer)) {
                    scope.selectedItem = scope.itemsContentContainer[newIndex];
                    if(scope.selectCallback){
                        scope.selectCallback({newIndex: newIndex, item: scope.selectedItem});
                    }
                }
            });

            scope.itemClicked = function( event) {
                if (scope.clickCallback) {
                    scope.clickCallback( {$event: event, item: scope.selectedItem});
                }
            };

        }
    };
});
