/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbUploadButton
 * @restrict EA
 * @scope
 *
 * @description
 *
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
 <div>
 <cb-accounts-currency-select ng-model='ngModel.currency' placeholder='Select'></cb-accounts-currency-select>
 </div>
 * ```
 */


angular.module('halyk-ebanking-shared').directive('cbUploadButton'
    , function ($compile
        , $rootScope
        , $q
        , pathService
        , cbUploadButtonService
        , isNullCheckService
        , cbNotificationService
        , translate) {
        return {
            restrict: 'E'
            ,
            replace: true
            ,
            require: 'ngModel'
            ,
            scope: {
                btnId: '@',
                model: '=ngModel'
                , contentList: '='
                , context: '='
                , ebDisabled: "=?"
                , onlySave: '@'
                , showSuccessMessage: '@'
                , uploadAfterSelect: "@"
                , callback: "&"
            }
            ,
            templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbUploadButton/cbUploadButton.html',
            link: function postLink(scope, element, attr) {

                scope.files = [];
                scope.fileReading = false;
                scope.uploadingPromise = false;


                scope.alreadyUploading = function () {

                    return scope.uploadingPromise != false
                        && scope.uploadingPromise.$$state.status === 0;
                };

                scope.uploadFiles = function (args) {
                    scope.fileReading = true;
                    if (isNullCheckService.isNotEmptyArray(scope.model)) {
                        if (angular.isDefined(scope.uploadAfterSelect)
                            && scope.uploadAfterSelect == 'true') {
                            args = angular.extend({
                                onlySave: scope.onlySave || true,
                                paymentId: scope.context.id
                            }, args);
                        }
                        scope.uploadingPromise = cbUploadButtonService.uploadFiles(args).then(function (result) {
                            showResultMessage(result.success, args, result.resultCode);
                            scope.callback({});
                        }).catch(function (data) {
                            console.error(data);
                            showResultMessage(false, args);
                        }).finally(function () {
                            scope.fileReading = false;
                            if (scope.uploadAfterSelect == 'true') {
                                scope.files = [];
                            }
                        });
                    }
                };

                function showResultMessage(isOk, args, isFileOk) {
                    if (isOk && scope.showSuccessMessage === "true") {
                        var fileNames = args.uploadFiles.reduce(function (str, file, index) {
                            var prefix = index == 0 ? "" : ", "
                            return str + prefix + file.fileName;
                        }, "");
                        cbNotificationService.showMessageDialog({
                                title: 'common.toast.success.title'
                                ,
                                translatedMessage: translate.property('common.file.is.uploaded', [" [ " + fileNames + " ] "])
                                ,
                                ok: 'OK'
                            }
                        );
                    }
                    if (!isOk && isFileOk === "ILLEGAL.FILE.TYPE") {
                        cbNotificationService.showWarningDialog({
                            title: "common.title.error",
                            message: "server.messages.send.error.illegal.file",
                            confirm: "common.button.close"
                        });
                    } else if (!isOk && isFileOk == null) {
                        cbNotificationService.showWarningDialog({
                            title: "common.title.error",
                            message: "server.messages.unknown.error",
                            confirm: "common.button.close"
                        });
                    }
                }

                scope.getArgs = function () {
                    if (angular.isDefined(attr.context)) {
                        return cbUploadButtonService.getArgs(scope.files, scope.context);
                    }
                    return {
                        'uploadFiles': files
                        , 'emailSubject': subject
                        , 'emailBody': body
                    };
                };

                element.bind('change', function () {
                        scope.fileReading = true;
                        loadData();
                        scope.removeChoice = function (index) {
                            scope.files.splice(index, 1);
                            var input = document.getElementById('file-upload-' + scope.btnId);
                            input.value = '';
                        };
                    }
                );

                var loadData = function () {
                    var inputFiles = document.getElementById('file-upload-' + scope.btnId).files;
                    var filesCount = inputFiles.length;
                    var filesLoaded = 0;
                    for (var i = 0; i < filesCount; i++) {
                        var item = inputFiles[i];
                        var mimeType = item.type;
                        var bytes = 0;
                        var reader = new FileReader();
                        reader.fileName = item.name;
                        reader.onload = function (readerEvt) {
                            var binaryString = readerEvt.target.result;
                            bytes = btoa(binaryString);
                            scope.files.push({
                                    'fileName': readerEvt.target.fileName
                                    , 'content': bytes
                                    , 'mimeType': mimeType
                                    , 'size': readerEvt.total
                                }
                            );
                            scope.model = scope.files;
                            scope.fileReading = false;
                            var alreadyUploading = scope.alreadyUploading();
                            filesLoaded++;
                            scope.$apply();
                            console.log('LOADED:', filesLoaded);
                            if (filesLoaded == filesCount &&
                                angular.isDefined(scope.uploadAfterSelect)
                                && scope.uploadAfterSelect == 'true') {
                                scope.fileReading = false;
                                scope.uploadFiles(scope.getArgs());
                            }
                        };
                        scope.fileReading = true;
                        reader.readAsBinaryString(item);

                    }
                }
            }


        };
    }
);
