/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTermsAgreementDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module('halyk-ebanking-shared').directive('cbTermsAgreementDialog', function (pathService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            title: '@ebTitle',
            message: '@ebMessage',
            terms: '@terms',
            close: '&ebOnClose',
            choices: '=ebChoices',
            callback: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbTermsAgreementDialog/cbTermsAgreementDialog.html"
        , link: function postLink(scope, element, attrs) {
            scope.data = {
                agreement: false
            };

            scope.$watch('data.agreement', function (newVal) {
                if (newVal) {
                    scope.callback({agreement: scope.data.agreement});
                }
            })
        }
    };
});
