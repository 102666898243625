/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDeclarationNoInput
 *
 * @description
 * cbDeclarationNoInput
 */

angular.module( 'halyk-ebanking-shared').directive('cbDeclarationNoInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            insurancePaymentType: '=',
            maxLength: '@',
            placeholder: '@',
            cbName: '@',
            cbForm: '='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDeclarationNoInput/cbDeclarationNoInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input.value = ngModelController.$viewValue || '';
                });
            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            scope.maxLength = scope.maxLength || 2;

            scope.$watch('insurancePaymentType', function(newVal) {
                if (angular.isDefined(newVal)) {
                    ngModelController.$validate();
                }
            });

        }
    }

});

angular.module( 'halyk-ebanking-shared').directive('cbDeclarationNoValidator', function() {

    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            ctrl.$validators.declarationNoValidForMS = function (value) {
                var insurancePaymentType = scope.$eval(attr.insurancePaymentType);

                if (angular.isDefined(insurancePaymentType) &&
                   (insurancePaymentType == "TYPE_S" || insurancePaymentType == "TYPE_M" || insurancePaymentType == null)) {
                    if (value != null) {
                        if (value.length == 2) {
                            return (value > 0 && value < 100);
                        }
                        return false;
                    }
                }
                return true;
            };

            ctrl.$validators.declarationNoValidOtherThanMS = function (value) {
                var insurancePaymentType = scope.$eval(attr.insurancePaymentType);

                if (angular.isDefined(insurancePaymentType) &&
                   (insurancePaymentType == "TYPE_S" || insurancePaymentType == "TYPE_M")) {
                    return true;
                }

                if (value != null) {
                    if (value.length == 2) {
                        if (!angular.isDefined(insurancePaymentType)) {
                            return (value > 0 && value < 100);
                        } else {
                            return (value == 1 || value == 40 || value == 51 || value == 70 || value == 80);
                        }
                    }

                    return false;
                }

                return true;
            };

        }
    }

});
