/* globals angular */

/**
 * @ngdoc filter
 * @name ebFilterModule.filter:dateParam
 *
 * @description
 * dateParam
 */

angular.module( 'halyk-ebanking-shared').filter('dateParam', function($filter){
    return function (number) {
        var date  = new Date(number);
        if(date.isValid()) {
            return $filter('date')(date, 'yyyy-MM-dd');
        }
        return number;
    };
});
