/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDeclarationInput
 *
 * @description
 * cbDeclarationInput
 */

angular.module( 'halyk-ebanking-shared').directive('cbDeclarationInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            maxLength: '@',
            placeholder: "@",
            cbName: '@',
            cbForm: '='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDeclarationInput/cbDeclarationInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input.value = ngModelController.$viewValue || '';
                    input[0].value = ngModelController.$viewValue || '';
                });
            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            scope.maxLength = scope.maxLength || 6;

        }
    }

});
/*
angular.module( 'halyk-ebanking-shared').directive('declarationValidator', function() {

    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            ctrl.$validators.declarationValid = function (value) {
            	if (value != null) {
            		if (value.length == 6 && /^\d+$/.test(value)) {
	            		var month = value.substring(0, 2);
                        var year = value.substring(2, 6);

	            		if (month > 0 && month < 13 && year > 1998) {
		            		return true;
		            	}
		            }

		            return false;
            	}

                return true;
            };

        }
    }

});
*/
