'use strict';

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:ebPagination
 *
 * @description
 * Component providing pagination functionalility to a list.
 *
 * @param {object=} ebData Data object of the list data returned by resource service.
 * @param {number=} ebPaginationCount Number of visible page buttons.
 * @param {expression} callback Action to execute for navigation.
 * @param {boolean=} ebLoading Model for flag indicating that some action is in progress.
 * @param {object=} ebParams Object of parameters for navigation actions.
 * @param {href} scrollTo Href of page element at which page should scrolled to after each navigation action.
 */

angular.module('halyk-ebanking-shared').directive('cbPagination', function(pathService, $location, $anchorScroll, $rootScope, cbSmartphoneDetectionService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebData: '=',
            ebPaginationCount: '=',
            callback: '&?',
            ebLoading: "=",
            ebParams: "=",
            scrollTo: "@?"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbPagination/cbPagination.html",
        link: function postLink(scope, element, attrs) {
            if (scope.ebPaginationCount == undefined) {
                scope.ebPaginationCount = 5;
            }

            scope.isSmartphone = cbSmartphoneDetectionService.isSmartphone();

            scope.showPagination = false;
            scope.hasFirst = false;
            scope.hasPrev = false;
            scope.hasNext = false;
            scope.hasLast = false;

            scope.pages = [];

            scope.setArrows = function() {
                if(angular.isDefined(scope.ebData._links)) {
                    scope.hasFirst = angular.isDefined(scope.ebData._links.first);
                    scope.hasPrev = angular.isDefined(scope.ebData._links.prev);
                    scope.hasNext = angular.isDefined(scope.ebData._links.next);
                    scope.hasLast = angular.isDefined(scope.ebData._links.last);
                }
                scope.totalPages = scope.ebData.totalPages;
                scope.currentPage = scope.ebData.pageNumber;
                scope.pageSize = scope.ebData.pageSize;

            }

            scope.setPages = function(totalPages, currentPage, pageRange) {
                // var width = angular.element($window).prop('innerWidth');

                // pageRange = width >= 768 ? pageRange : 3;

                scope.pages = [];
                if (totalPages <= pageRange) {
                    for (var i = 1; i <= totalPages; i++) {
                        scope.pages.push({
                            number: i,
                            select: i == currentPage
                        });
                    }
                } else {
                    var leftSide = currentPage - Math.floor(pageRange / 2);
                    var rightSide = currentPage + Math.floor(pageRange / 2);
                    if (leftSide <= 0) {
                        rightSide += Math.abs(leftSide) + 1;
                        leftSide = 1;
                    } else if (rightSide > totalPages) {
                        leftSide -= (rightSide - totalPages);
                        rightSide = totalPages;
                    }
                    for (var i = leftSide; i <= rightSide; i++) {
                        scope.pages.push({
                            number: i,
                            select: i == currentPage
                        });
                    }
                }
            }

            scope.$watch('ebData', function(newData) {
                if (newData != undefined && Object.getOwnPropertyNames(newData).length > 0) {
                    scope.setArrows();
                    scope.setPages(scope.totalPages, scope.currentPage, scope.ebPaginationCount);
                    scope.showPagination = scope.totalPages > 1;
                } else {
                    scope.showPagination = false;
                }
            });

            var update = function(rel, params) {
                scope.ebLoading = true;
                scope.callback({ctx: scope.ebData, params: params, rel:rel});
                $rootScope.$emit("pageChangeSuccess");
            }

            scope.goFirstPage = function() {
                var params = angular.copy( scope.ebParams);
                delete params.pageNumber;
                update("first", params);
                scope.scroll();
            }

            scope.goPreviousPage = function() {
                scope.ebParams.pageNumber--;
                update("prev", scope.ebParams);
                scope.scroll();
            }

            scope.goNextPage = function() {
                scope.ebParams.pageNumber++;
                update("next", scope.ebParams);
                scope.scroll();
            }

            scope.goLastPage = function() {
                var params = angular.copy( scope.ebParams);
                delete params.pageNumber;
                update("last", params);
                scope.scroll();
            }

            scope.goToPage = function(number) {
                if(scope.ebData.pageNumber != number) {
                    var params = angular.copy(scope.ebParams);
                    params.pageNumber = number;
                    update("selected", params);
                    scope.scroll();
                }
                
            }

            scope.scroll = function() {
                if(scope.scrollTo) {
                    $location.hash(scope.scrollTo);
                    $anchorScroll();
                }
            };

            element.bind("touchend", function(event) {
                element.addClass("indicator-touchet-end");   
            });

            element.bind("touchstart", function(event) {
                element.removeClass("indicator-touchet-end");
            });
        }
    };
});