/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDateRangeSelect
 * @restrict EA
 * @scope
 *
 * @description
 * Component allows to enter a time interval. User can choose between period of time and range of dates by selecting one
 * of two checkboxes. First checkbox selects a period of time - it may be period of n last days. When second
 * checkbox is selected user should enter two date of the range in the fields presented next to it. Each field validates entered dates
 * which must match the pattern "dd.MM.yyyy". User can input the date from keyboard or select it using date picker which is triggered
 * by clicking icon displayed in each field. If the period is selected then input fields are automaticlically filled in with proper dates.
 * If user starts typing in a date field or opens a date picker then period checkbox is turned off.
 *
 * @param {object=} ngModel Model of period type selection.
 * @param {string} periodType Type of period - may have three values: "last" (default) - means that upper bound of interval is current date and lower bound is a date in the past, "next" - mean that lower bound is current date and upper is a date in the future, "range" - any interval entered by user.
 * @param {string} periodTypeLabel Label indicating units for period.
 * @param {Date=} dateFrom Model for lower bound of the date range.
 * @param {Date=} dateTo Model for upper bound of the date range.
 * @param {object=} ebForm Model of the parent form.
 * @param {boolean=} hideLast Hides period selection part.
 * @param {boolean=} hideRange Hides range edit part.
 */

angular.module( 'halyk-ebanking-shared').directive('cbDateRangeSelect', function($compile, $rootScope, $timeout, $log, $http,$filter, $templateCache, pathService, accountsService, $location, $state, translate, viewConfig, cbUtilsService) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ngModel: "=",
            periodType: "@", //default: 'last', might be: 'next'
            periodTypeLabel: "@",
            dateFrom: "=",
            dateTo: "=",
            ebForm: "=",
            hideLast: "=",
            hideRange: "=",
            onChange: "&"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDateRangeSelect/cbDateRangeSelect.html',
        link: function postLink(scope, element) {
            console.log(scope.dateFrom + "scope.dateFrom;");
            var PERIOT_TYPE_LAST = 'last';
            var PERIOT_TYPE_NEXT = 'next';
            var PERIOT_TYPE_RANGE = 'range';
            var PERIOT_TYPE_DEFAULT = PERIOT_TYPE_LAST;

            scope.periodDefinitionTypeRange = PERIOT_TYPE_RANGE;


            scope.clearValidation = { dateFrom: false, dateTo: false };

            function generateBeginDate(period) {
                var startDate = new Date();
                startDate.setHours(0, 0, 0, 0);
                if (period) {
                    startDate.setDate(startDate.getDate() + Number(period));
                }
                return convertUTCDateToLocalDate(startDate);
            }

            function convertUTCDateToLocalDate(date) {
                var newDate = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
                return newDate;
            }

            function generateEndDate(startDate, period) {
                var endDate = new Date();
                if (startDate) {
                    endDate = new Date(startDate.getTime());
                }
                endDate.setHours(23,59,59,999);
                if (period) {
                    endDate.setDate(endDate.getDate() + Number(period));
                }
                return endDate;
            }

            function init() {
                var periodCount = scope.ngModel.periodCount;
                if (!periodCount) {
                    periodCount = viewConfig.PMT_FILTER_PERIOD_COUNT_DEFAULT;
                }
                //default lastRange
                if (!scope.periodType || scope.periodType === PERIOT_TYPE_LAST) {
                    scope.lastPeriod = {
                        dateFrom: generateBeginDate(-1 * periodCount),
                        dateTo: generateEndDate()
                    };
                } else if (scope.periodType && scope.periodType === PERIOT_TYPE_NEXT) {
                    scope.lastPeriod = {
                        dateFrom: generateBeginDate(),
                        dateTo: generateEndDate(undefined, periodCount)
                    };
                }

                scope.rangePeriod = {
                    dateFrom: scope.dateFrom != null ? formatDate(scope.dateFrom) : generateBeginDate(),
                    dateTo: scope.dateTo != null ? formatDate(scope.dateTo) : generateEndDate(undefined, periodCount)
                };

                scope.dateFrom = scope.lastPeriod.dateFrom;
                scope.dateTo = scope.lastPeriod.dateTo;
            }

            init();

            function castToDate(date, oldValue) {
                if (date == null) {
                    return new Date();
                }
                try {
                    var result = date instanceof Date ? date : new Date(Date.parse(date));
                    return  isNaN(result.getTime()) ? castToDate(oldValue, null) : result;
                } catch (ex) {
                    return new Date();
                }
            }

            function formatDate(date) {
                return $filter('dateParam')(date);
            }

            scope.dateFormat = 'dd.MM.yyyy';


            scope.$watch('ngModel.periodCount', function(newVal, oldVal) {
                if (newVal != oldVal && newVal) {
                    if (!scope.ngModel.isClearing) {

                        if (scope.ngModel.periodDefinitionType === PERIOT_TYPE_RANGE) {
                            scope.ngModel.periodDefinitionType = scope.periodType;
                        }

                        if (scope.ngModel.periodDefinitionType === PERIOT_TYPE_NEXT) {
                            scope.lastPeriod = {
                                dateFrom: generateBeginDate(),
                                dateTo: generateEndDate(undefined, scope.ngModel.periodCount)
                            };
                        }
                        if (scope.ngModel.periodDefinitionType === PERIOT_TYPE_LAST) {
                            scope.lastPeriod = {
                                dateFrom: generateBeginDate(-1 * scope.ngModel.periodCount),
                                dateTo: generateEndDate()
                            };
                        }

                        scope.dateFrom = scope.lastPeriod.dateFrom;
                        scope.dateTo = scope.lastPeriod.dateTo;

                        if ( scope.onChange ) {
                            $timeout(function () {
                                scope.onChange();
                            })
                        }

                    }
                }
            });

            scope.$watch('ngModel.periodDefinitionType', function(newVal, oldVal) {
                if (newVal != oldVal) {
                    if (!scope.ngModel.isClearing) {
                        if(newVal == PERIOT_TYPE_LAST || newVal == PERIOT_TYPE_NEXT) {
                            scope.dateFrom = scope.lastPeriod.dateFrom;
                            scope.dateTo = scope.lastPeriod.dateTo;

                            // scope.clearValidation.dateFrom = true;
                            // scope.clearValidation.dateTo = true;
                        } else if(newVal === PERIOT_TYPE_RANGE) {
                            if (!scope.dateFrom) {
                                scope.dateFrom = scope.rangePeriod.dateFrom;
                            }
                            if (!scope.dateTo) {
                                scope.dateTo = scope.rangePeriod.dateTo;
                            }
                            scope.dateFrom = scope.dateFrom;
                            scope.dateTo = scope.dateTo;

                            cbUtilsService.cleanValidationErrors(scope.ebForm, 'count');
                        }


                    }
                }
            });

            scope.$watch('dateFrom', function(newVal, oldVal) {
                var newDate=new Date(newVal).getTime();
                var oldDate=new Date(oldVal).getTime();
                if (newDate != oldDate) {
                    if (newVal) {
                        scope.dateFrom = castToDate(newVal, oldVal);
                        scope.dateFromChanged = true;
                        newDate = null;
                        oldVal = null;
                        // if ( scope.onChange ) {
                        //     scope.onChange();
                        // }
                    }
                }
            });

            scope.$watch('dateTo', function(newVal, oldVal) {
                var newDate=new Date(newVal).getTime();
                var oldDate=new Date(oldVal).getTime();

                if (newDate != oldDate || scope.dateFromChanged) {

                    if (newVal) {
                        scope.dateTo = castToDate(newVal, oldVal);
                        scope.dateFromChanged = false;
                        newDate = null;
                        oldVal = null;
                        if ( scope.onChange ) {
                            $timeout(function () {
                                scope.onChange();
                            })
                        }
                    }
                }
            });

            scope.$watch('ngModel.isClearing', function(newVal, oldVal) {
                if (newVal) {
                    init();
                    scope.ngModel.isClearing = false;
                }
            });

            scope.selectLastPeriodDefinitionType = function() {
                angular.element(document.querySelector('#count'))[0].focus();
                scope.ngModel.periodDefinitionType = scope.periodType;
            };

            scope.selectRangePeriodDefinitionType = function() {
                scope.ngModel.periodDefinitionType = PERIOT_TYPE_RANGE;
            };

            scope.callback = function () {
                if ( scope.onChange ) {
                    scope.onChange();
                }
            }

        }
    };
});
