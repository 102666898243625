/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbShowPensionDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module('halyk-ebanking-shared').directive('cbShowPensionDialog', function (pathService, translate, staticTextService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            showPension: '=',
            close: '&ebOnClose',
            callback: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbShowPensionDialog/cbShowPensionDialog.html"
        , link: function postLink(scope, element, attrs) {
            scope.pension = {
                agreement: false
            };

            const params = {
                productType: "pension.fund.agreement.text"
            }


            if (scope.showPension) {
                scope.title = translate.property('pension.show.title');
                scope.termsPromise = staticTextService.getStaticText(params).then(function (result) {
                    scope.terms = result;
                });
            } else {
                scope.title = translate.property('pension.hide.title');
                scope.agreementText = translate.property('agreementText.hide')
            }

            scope.wrapCallback = function () {
                return scope.callback({checked: scope.showPension});
            }
        }
    };
});
