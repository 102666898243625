/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDownloadButton
 * @restrict EA
 *
 * @description
 * cbDownloadButton
 */

angular.module( 'halyk-ebanking-shared').directive('cbPrintButton', function(pathService){

	return {
		restrict: 'EA',
		transclude: true,
		replace: true,
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbPrintButton/cbPrintButton.html'
	};
});
