/**
 * Created by Kvitko on 27.09.2017.
 */
'use strict';

/**
 * @ngdoc service
 * @name ebankingSharedModule.service:legacyEntityService

 * @description
 * legacyEntityService.
 */
angular.module( 'halyk-ebanking-shared').service( 'legacyEntityService',
    function (
        $rootScope
        , gate
        , resourceServiceFactory
        , $q
    ) {

        var legacyEntityService = resourceServiceFactory.create ( "le_all" );

        return {
            getSharingUsers: function ( params ) {
                return gate.query ( "get_users_by_account_no" , params ).then (
                    function ( result ) {
                        return result.content;
                    }, function ( ) {
                        //$log.error ( "Error loading branch dictionary" );
                        return $q.reject ( "Błąd utworzenie transferu." );
                    }
                );
            }
        };
    } );
