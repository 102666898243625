/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbFilterResponsiveRow
 * @restrict EA
 * @scope
 *
 * @description
 * Responsive row for filter partials. First column of the row contains label. If filter parameters are invalid icon indicating
 * error will be placed before it that. Remainig part of the row is filled with transluded filter content.
 *
 * @param {string} label label of filter part
 * @param {string} cbName name of a model inside filter form model to check validity of filter params
 * @param {object=} cbForm filter form model
 */

angular.module( 'halyk-ebanking-shared').directive('cbFilterResponsiveRow', function(pathService) {

    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            labelWidth:'@',
            inputWidth:'@',
            label:'@',
            cbName: '@',
            cbForm: '='
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + "/directives/cbFilterResponsiveRow/cbFilterResponsiveRow.html",
        link: function postLink(scope, element, attrs) {
             var label = angular.element(element.querySelectorAll('#row-label')[0])[0];
             label.style.width = attrs.labelWidth;
             var input = angular.element(element.querySelectorAll('#row-input')[0])[0];
             input.style.width = attrs.inputWidth;
        }
    };
});
