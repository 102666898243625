/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:specialCharsFilter
 *
 * @description
 * specialCharsFilter
 */
 angular.module('halyk-ebanking-shared').filter('specialCharsFilter', function() {

    var format = function(text) {
        text = replaceAll(text, '&nbsp;',' ');
        text = replaceAll(text, '&quot;','"');
        text = replaceAll(text, '&gt;','>');
        text = replaceAll(text, '&lt;','<');
        text = replaceAll(text, '&amp;','&');
        return text;
    };

    function replaceAll(text, find, replace) {
      return text.replace(new RegExp(find, 'g'), replace);
    }

    return function(text) {
        if (text != null) {
            return format(text);
        }
        return text;
    };

});
