/**
 * Created by Kvitko on 13.02.2017.
 */
angular.module( 'halyk-ebanking-shared').directive('cbRadiobox', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebModel: '=',
            ebName: '@',
            ebRequired: '=',
            ebDisabled: '=',
            ebItems:'='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbRadiobox/cbRadiobox.html",

        link: function(scope, element, attrs){
            ebTheming.applyTheme(element);

            scope.changeRadio = function ( newVal ) {
               scope.ebModel = newVal;
            };

            scope.itemClick = function ( newVal ) {
                scope.ebModel = newVal;
            };

            angular.forEach( scope.ebItems, function(item){
                if ( item.default ){
                    scope.changeRadio( item.value );
                }
            } );
        }

    }
});

