/**
 * Created by Kvitko on 08.02.2017.
 */
/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbComboBox
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down selection for any collection.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
 <div>
    <cb-combo-box ng-model="ngModel.currency" placeholder="Select"></cb-accounts-currency-select>
 </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbCombobox', function(
    $compile
    , $rootScope
    , pathService
    , translate) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            ebShowCardIcon: "=",
            ebForm: "=",
            ebName: "@",
            requiredMsg: "@requiredMsg",
            ebShowInfo: "=",
            ebRequired: '=',
            ngModel: "=",
            placeholder: "@",
            list: "=",
            callback: "&",
            initValue: "=",
            emptyMsg: "=",
            showExtended: "=?"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbCombobox/cbCombobox.html',

        link: function postLink(scope, element, attrs, ngModel) {
                if (angular.isDefined(scope.emptyMsg) && scope.emptyMsg != ('' && null)) {
                    scope.validationMsg = translate.property(scope.emptyMsg)
                }
                else
                    scope.validationMsg = translate.property('cb.combobox.validation');

            scope.$watch('list', function ( newValue , oldValue ) {
                if ( newValue == null || newValue.length  == 0 ) {
                    return;
                }
                if ( angular.isDefined( scope.initValue ) && scope.initValue != null
                        && newValue && newValue.length > 0 ){
                    scope.callback({newValue: scope.initValue});
                }

            });

            scope.select = function(item, model) {
            };
        }
    };
});

