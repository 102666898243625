/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbActionFooter
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Section with submit button to be placed at the bottom of a container.
 *
 * @param {string} submitLabel Label of the submit button.
 * @param {expression} submitAction Action to be executed when the submit button is clicked.
 *
 * @example
 * ```js
    <div>
        <cb-action-footer submit-label="cb.calendar.form.accept.delete.submit" submit-action="accept()"></cb-action-footer>
    </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbActionFooter', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            submitLabel: '@',
            submitAction: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbActionFooter/cbActionFooter.html"
    };
});
