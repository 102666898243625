angular.module('halyk-ebanking-shared').service('contractService', function (gate, cbNotificationService, language, logger, $q, $filter, $http, userService) {

    function downloadFile(blob, fileName) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    function downloadPdfFile(byte, fileName) {
        let blob = new Blob([byte], {type: "application/pdf"})
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        window.open(link.href);
    }

    function downloadWordDocument(byte, fileName) {
        let blob = new Blob([byte], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"})
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        window.open(link.href);
    }

    function base64ToArrayBuffer(base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }


    function canBeOpenedInBrowser(contentType) {
        return "text/html" === contentType
            || 'application/pdf' === contentType
            || ('' + contentType).startsWith('image');
    }

    return {
        downloadContractFile: function (contractId) {
            return this.openContractFile(contractId, true);
        },
        openContractFile: function (contractId, download) {
            return userService.getUserDetails().then(function (detail) {

                var downloadContractFileUrl = '/frontend-web/api/contract/downloads/download_contract.json'
                    + '?customerId=' + detail.userIdentityId.id + '&contractId=' + contractId;

                //old download
                //window.location = downloadContractFileUrl;
                //window.target = '_blank';

                var xhr = new XMLHttpRequest();
                xhr.open('GET', downloadContractFileUrl);
                xhr.responseType = 'blob';
                xhr.send();
                xhr.onload = function () {
                    if (xhr.status !== 200) {
                        cbNotificationService.showWarningDialog({
                            title: "common.title.error",
                            message: "contract.can.not.be.downloaded",
                            confirm: "common.button.close"
                        });
                        return
                    }

                    /**
                     * it will open the downloaded content in a new browser window.
                     */
                    if (!download && canBeOpenedInBrowser(xhr.response.type)) {
                        window.open(URL.createObjectURL(xhr.response));
                    } else {
                        var fileName = xhr.getResponseHeader('Content-Disposition')
                            .split("filename=")[1]
                            .replace(/"/g, '');
                        downloadFile(xhr.response, fileName);
                    }
                };
            });
        },
        getDepositTerms: function (contractId) {
            return userService.getUserDetails().then(function (detail) {
                const genDocReq = {
                    method: 'GET',
                    url: '/frontend-web/api/deposit/terms.json',
                    params: {
                        customerId: detail.userIdentityId.id,
                        contractId: contractId,
                        language: language.get().toUpperCase()
                    },
                    headers: {'Content-Type': 'application/json'}
                }

                return $http(genDocReq).then(function (response) {
                    if (response.status !== 200) {
                        cbNotificationService.showWarningDialog({
                            title: "common.title.error",
                            message: "contract.can.not.be.downloaded",
                            confirm: "common.button.close"
                        });
                        return
                    } else {
                        let fileName = response.data.fileName;
                        let file = base64ToArrayBuffer(response.data.data);
                        downloadPdfFile(file, fileName);
                    }
                }, function (errorResponse) {
                    cbNotificationService.showWarningDialog({
                        title: "common.title.error",
                        message: "contract.can.not.be.downloaded",
                        confirm: "common.button.close"
                    });
                    console.log(errorResponse);
                    return errorResponse;
                })
            });
        },

        getLoanTerms: function (contractId) {
            return userService.getUserDetails().then(function (detail) {
                const genDocReq = {
                    method: 'POST',
                    url: '/frontend-web/api/credit/terms',
                    data: {
                        customerId: detail.userIdentityId.id,
                        contractId: contractId
                    },
                    headers: {'Content-Type': 'application/json'}
                }

                return $http(genDocReq).then(function (response) {
                    if (response.status !== 200) {
                        cbNotificationService.showWarningDialog({
                            title: "common.title.error",
                            message: "contract.can.not.be.downloaded",
                            confirm: "common.button.close"
                        });
                        return
                    } else {
                        let fileName = response.data.fileName;
                        let file = base64ToArrayBuffer(response.data.data);
                        downloadWordDocument(file, fileName);
                    }
                }, function (errorResponse) {
                    cbNotificationService.showWarningDialog({
                        title: "common.title.error",
                        message: "contract.can.not.be.downloaded",
                        confirm: "common.button.close"
                    });
                    console.log(errorResponse);
                    return errorResponse;
                })
            });
        }
    }
});
