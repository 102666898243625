/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbMatchMediaService
 *
 * @description
 * cbMatchMediaService
 */

angular.module( 'halyk-ebanking-shared').service('cbMatchMediaService', function() {

    /*
        Powielenie definicji core.less
    */
    var _screenSmallMax = "527px";
    var _screenMediumMax = "767px";
    /* MUSI pasować do reguły w widgetContent.less dla klas .cb-layout-dashboard__widget-tile--big .widget-content-container-big (linia 101) */
    var _SMARTPHONE_1 = "(max-width: " + _screenSmallMax + ")";
    var _SMARTPHONE_2 = "(max-width: " + _screenSmallMax + ") and (orientation: portrait), (max-width: " + _screenMediumMax + ") and (orientation: landscape)";
    var _SMARTPHONE = _SMARTPHONE_1;

    var smartphoneMediaQuery;

    this.createSmartphoneMediaQuery = function() {
        if (!smartphoneMediaQuery) {
            smartphoneMediaQuery = window.matchMedia(_SMARTPHONE);
        }
        return smartphoneMediaQuery;
    }

    this.createMediaQuery = function (condition) {
        if (condition) {
            return window.matchMedia(condition);
        } else {
            return window.matchMedia(_SMARTPHONE);
        }
    };

    this.mediaQueryResponse = function (mql) {
        if (mql.matches){
            console.log("The condition " + mql.media + " met");
        }
        else{
            console.log("The condition " + mql.media + " not met");
        }
    };

    this.addListenerToMediaQuery = function(mql, callback) {
        mql.addListener(callback);
    }

    /*
        $scope.$on('$destroy', function() {...});
    */
    this.removeListenerFromMediaQuery = function(mql, callback) {
        mql.removeListener(callback);
    }

});
