/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbListActionRow
 * @restrict: 'E'
 *
 * @description
 * Row of list component intended to contain action buttons.
 */

angular.module( 'halyk-ebanking-shared').directive('cbListActionRow', function(pathService){

	return {
		restrict: 'E',
		transclude: true,
		replace: true,
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbListActionRow/cbListActionRow.html',
	};
});
