/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbCheckbox
 * @restrict EA
 * @element
 * @scope
 *
 * @description
 * Checkbox input field.
 *
 * @param {boolean=} ebModel Model of the checkbox.
 * @param {string} ebName Name of the checkbox as a form element.
 * @param {boolean=} ebRequired Required flag.
 * @param {boolean=} ebDisabled Disabled flag.
 * @param {boolean=} ebChecked Checked flag.
 *
 * @example
     * ```js
        <form>
          <eb-checkbox eb-model="variale" eb-name="name"></eb-checkbox>
        </form>
    * ```
    <doc:example module="angular.module( 'halyk-ebanking-shared')">

        <doc:source>
            <style>
                .eb-checkbox {
                  width: 26px;
                  margin: 0px;
                  position: relative;
                }

                .eb-checkbox label {
                  cursor: pointer;
                  position: absolute;
                  width: 26px;
                  height: 26px;
                  top: 0;
                  left: 0;
                  background: #fff;
                  border: 1px solid #BDB9B9;
                  margin: 0px;
                }

                .eb-checkbox label:after {
                  opacity: 0.2;
                  content: '';
                  position: absolute;
                  width: 17px;
                  height: 10px;
                  background: transparent;
                  top: 5px;
                  left: 4px;
                  border: 4px solid blue;
                  border-top: none;
                  border-right: none;
                  -webkit-transform: rotate(-45deg);
                  -moz-transform: rotate(-45deg);
                  -o-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
                .eb-checkbox label:hover::after {
                  opacity: 0.5;
                }

                .eb-checkbox input[type=checkbox]:checked + label:after {
                  opacity: 1;
                }

                #example_source {
                  display: none;
                }

                .tabbable {
                    display: none;
                }
            </style>
            <form>
               <eb-checkbox eb-model="variale" eb-name="name"></eb-checkbox>
            </form>
        </doc:source>
    </doc:example>
 */
angular.module( 'halyk-ebanking-shared').directive('cbCheckbox', function(pathService, ebTheming, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebModel: '=',
            ebName: '@',
            ebLabel: '@',
            ebRequired: '=',
            ebDisabled: '=',
            ebChecked: '=',
            ebOpenDialog: '='
        },
          templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbCheckbox/cbCheckbox.html",

          link: function(scope, element, attrs){
            ebTheming.applyTheme(element);

            scope.selectinput = function () {
                $timeout(function (){
                    if ( isEnabled() ){
                        scope.ebModel = !scope.ebModel;
                    }
                })
            };

            function isEnabled() {
              return !scope.ebDisabled && !scope.ebOpenDialog;
            }
          }

        }
});
