/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTooltip
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Tooltip component that can be attatched to an element.
 *
 * @param {string} viewUrl URL of tooltip content template.
 * @param {obejct=} extraData Data for the tooltip template.
 * @param {string} header Tooltip header.
 * @param {string} side Side of element at which the tooltip shoukd be displayed - proper values are: `left`, `right`, `top` and `bottom`.
 * @param {string} handler If this attribute value equals to `click` then the tooltip will be presented when user clicks the target element, otherwise the tooltip will be presented when mouse pointer hovers the element.
 */

angular.module( 'halyk-ebanking-shared').directive('cbTooltip', function(tooltips, translate) {
	return {
        restrict: 'A',
        scope: {
            extraData: "=",
            viewUrl: "@",
            header: "@",
            side: "@",
            handler: "@"
        },
        link: function(scope, element, attrs) {

            var side = scope.side || "right";

            scope.textGetter = function(key) {
                var text = translate.property( key );
                return key ? text != key ? text : key : null;
            };

        	scope.mouseoverHandler = function() {
                tooltips.showTooltip(scope.textGetter(scope.header),
                                     scope.textGetter(attrs.cbTooltip),
                                     side,
                                     scope.viewUrl,
                                     scope.extraData,
                                     scope.handler,
                                     element[0]
                );
        	};

        	scope.mouseoutHandler = function() {
        		tooltips.hideTooltip();
        	};

            scope.mousedownHandler = function() {
                tooltips.showTooltip(scope.textGetter(scope.header),
                                     scope.textGetter(attrs.cbTooltip),
                                     side,
                                     scope.viewUrl,
                                     scope.extraData,
                                     scope.handler,
                                     element[0]
                );
            };

            if (scope.handler == "click") {
                element.bind("mousedown", scope.mousedownHandler);
            } else {
                element.bind("mouseover", scope.mouseoverHandler);
                element.bind("mouseout", scope.mouseoutHandler);
            }

        	scope.$on("$destroy", function() {
                if (scope.handler == "click") {
                    element.unbind("mousedown");
                } else {
                    element.unbind("mouseover");
                    element.unbind("mouseout");
                }
        	});

        }
    };
});
