/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAmount
 *
 * @restrict E
 * @element
 * @scope
 *
 * @description
 * Input for amount.
 *
 * @param {number=} amount Model for the field.
 * @param {number=} amountbig Model for the field to be displayed as important value.
 * @param {string} currency The currency of the amount.
 * @param {string} currencybig The currency to be displayed as important value.
 * @param {number} fractionSize Number of decimal digits.
 * @example
    * ```js
        <div>
            <eb-amount amount="100" currency="GEL"></eb-amount>
        </div>
    * ```
    <doc:example module="angular.module( 'halyk-ebanking-shared')">

        <doc:source>
            <style>
                .eb-amount-sm {display:none}
                .eb-amount-lg {display:inline-block}@media only screen and (max-width:768px){
                    .eb-amount-sm{display:block}
                    .eb-amount-lg{display:none}
                }prepaid
            </style>
            <div>
               <eb-amount amount="100" currency="GEL" header="Kwota dostępna"></eb-amount>
            </div>
        </doc:source>
    </doc:example>
 */
angular.module( 'halyk-ebanking-shared').directive('cbAmount', function($locale, $filter, pathService, ebTheming) {
    var formats = $locale.NUMBER_FORMATS;

    return {
        restrict: 'E',
        replace: true,
        scope: {
            label: "@",
            placeholder: "@",
            amount: "=",
            rate: "=",
            amountbig: "=", // TODO nadmiarowe - do usunięcia prawdopodobnie - powinien parametr, który zmienia stylowanie
            currency: "@",
            icon: "@",
            currencybig: "@", // TODO j.w.
            fractionSize: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAmount/cbAmount.html',
        link: function ($scope, element, attrs) {
            ebTheming.applyTheme(element);
            $scope.highlightNegative = angular.isDefined(attrs.highlightNegative);
            if(angular.isDefined(attrs.absoluteValue)){
                if($scope.amount < 0){
                    $scope.amount = $scope.amount * -1;
                }
            };
            $scope.fractionSize = angular.isDefined(attrs.fractionSize);
        }
    };
});
