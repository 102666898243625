/**
 * Created by Kvitko on 30.05.2017.
 */
/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbComboBox
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down selection with incremintal search for any collection.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
 <div>
    <cb-combo-search ng-model="ngModel.currency" placeholder="Select"></cb-combo-search>
 </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbComboSearch', function(
    $compile
    , $rootScope
    , pathService
    , translate) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            ebForm: "=",
            ebName: "@",
            requiredMsg: "@requiredMsg",
            ebRequired: '=',
            ngModel: "=",
            placeholder: "@",
            list: "=",
            callback: "&",
            initValue: "="
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbComboSearch/cbComboSearch.html',

        link: function postLink(scope, element, attrs, ngModel, $select) {
            scope.validationMsg = translate.property('cb.combobox.validation');
            scope.entity = {};

            scope.$watch('ngModel', function ( newValue , oldValue ) {
                scope.entity.selected = newValue;
            });
            
            scope.$watch('list', function ( newValue , oldValue ) {
                if ( newValue == null || newValue.length  == 0 ) {
                    return;
                }
                if ( angular.isDefined( scope.initValue ) && scope.initValue != null
                        && newValue && newValue.length > 0 ){
                    scope.callback({newValue: scope.initValue});
                }

            });

            scope.select = function(item, model) {
                scope.ngModel = scope.entity.selected;
            };
        }
    };
});

