/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbListDetailsItem
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * Container for presenting details of list item.
 *
 * @param {string} key I18n key of label for the container.
 * @param {string} padded If this attribute is not present or is empty the label is wrapped in addition div element.
 * @param {string} width Width of the label section of the container.
 */

angular.module( 'halyk-ebanking-shared').directive('cbListDetailsItem', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
        	key: '@',
            padded: '@',
            width: '@'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbListDetailsItem/cbListDetailsItem.html",
        link: function postLink(scope, element, attrs) {
        	ebTheming.applyTheme(element);
        }
    };
});
