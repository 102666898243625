/* globals angular */

/**
 * @ngdoc directive
 * @name ebValidationModule.filter:ebTextareaFilter
 * @element input
 * @function
 *
 * @description
 * filter form input 'textarea' 4x35.
 *
 * @example
    elem.val($filter('ebTextareaFilter')(text));
 */

angular.module( 'halyk-ebanking-shared').filter('cbTextareaFilter', function() {
    return function(text,ebRows) {

        var isSpace = function(char) {
            return char == ' ';
        };
        var isNewLine = function(char) {
            return char.match(/\r\n|\r|\n/g);
        };
        var isEndOfLine = function(lineNumber) {
            return lineNumber == 35;
        };

        var newLinesBeforeIndex = function(text, index) {
            var lines = text.slice(0, index).split(/\r\n|\r|\n/g);
            return lines.length - 1;
        };

        var setCharAt = function(text, index, char, mode) {
            var offset = 1; // replace
            if (mode == 'insert') {
                offset = 0;
            }

            return text.slice(0, index).concat(char, text.slice(index + offset));
        };
        var toString = function(array) {
            return array.join('\n');
        };

        var textArray = text;
        if (!angular.isArray(textArray)) {
            textArray = text.split('\n');
            var correctLength = true;
            for (var i = textArray.length - 1; i >= 0; i--) {
              if(textArray[i].length > 35) {
                correctLength = false;
                break;
              }
            };
            if (textArray.length > ebRows || !correctLength) {
                var tmp = text.match(/.{1,35}/g);
                if (tmp != null) {
                    textArray = tmp.slice(0, ebRows);
                }
            }
        } //TODO refactor
        var lastSpacePosition = 0;
        var previousLastSpacePosition = 0;
        var lineLengthCounter = 0;
        for (var i = 0; i < textArray.length; i++, lineLengthCounter++) {

            if (isSpace(textArray[i]) || isNewLine(textArray[i])) {
                lastSpacePosition = i;
                if (isNewLine(textArray[i])) {
                    lineLengthCounter = -1;
                    previousLastSpacePosition = i;
                }
            }

            if (isEndOfLine(lineLengthCounter)) {
                var mode = 'replace';
                if (lastSpacePosition == previousLastSpacePosition) {
                    var newLinesBeforeCurrentItem = newLinesBeforeIndex(toString(textArray), i);
                    lastSpacePosition = lastSpacePosition + 35 + ((newLinesBeforeCurrentItem > 0) ? 1 : 0);
                    mode = 'insert';
                }

                textArray = setCharAt(textArray, lastSpacePosition, '\n', mode);
                lineLengthCounter = 35 - (lastSpacePosition - previousLastSpacePosition) - 1;
                previousLastSpacePosition = lastSpacePosition;
            }
        }
        return toString(textArray);
    };



});
