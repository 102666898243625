/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbList
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * List component. Its items are populated by data received from promise.
 *
 * @param {object=} ebPromise The promise for list items data.
 * @param {boolean=} ebLazyLoading Flag indicating that loading of items data is triggered on demand.
 */

angular.module( 'halyk-ebanking-shared').directive('cbList', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') +  '/directives/cbList/cbList.html',
        scope: {
            promise: "=ebPromise",
            lazyLoading: "=ebLazyLoading"
        },
        link: function ($scope, element, attrs) {
            ebTheming.applyTheme(element);
        }
    };
});
