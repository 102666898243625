/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInputPhoneNo
 * @restrict: 'E'
 * @scope
 *
 * @description
 * Input field for phone number. It allows to enter digit string of maximum length equal to 9 when the first digit cannot be 0 or 4.
 *
 * @param {string=} ebModel Model for the number.
 * @param {string} ebName Name of the field.
 * @param {expression=} ebDisabled Sets the disabled attribute on the element if the expression evaluates to truthy.
 * @param {expression=} ebRequired If `true` the account number will be required.
 * @param {expression=} equalTo If provided the value of the field will be reuqired to equal to the value of expression.
 * @param {siring} ebPlaceholder A text to be presented inside the field when no user data is entered.
 */

angular.module( 'halyk-ebanking-shared').directive('cbInputPhoneNo', function (pathService, $timeout) {
    var PHONE_NO_LENGTH = 9;
    var PHONE_NO_REGEX = /^[^04][0-9]*$/;   /* Numery nie moga zaczynac sie na 0 lub 4 */

    return {
        restrict: 'E',
        replace: true,
        scope: {
            ngModel: "=ebModel",
            ebName: "@",
            ebDisabled: "=",
            ebRequired: '=',
            equalTo: "=?", // czy to jeszcze używane, to chyba zostało przeniesione przypadkowo z pola do haseł
            ebPlaceholder: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInputPhoneNo/cbInputPhoneNo.html',
        compile: function(element, attrs) {
            var $input = element.find('input');

            if (!attrs.equalTo) {
                $input.removeAttr('eb-equal-to');
            }

            $input.attr('maxlength', PHONE_NO_LENGTH);

            return {
                post: function(scope, element, attrs) {
                    var $input = element.find('input');
                    var inputModelCtrl = $input.controller('ngModel');

                    var allowedKeyCodes = {
                        normal: [
                            8,      /* backspace */
                            9,      /* tab */
                            13,     /* enter */
                            27,     /* escape */
                            35,     /* end */
                            36,     /* home */
                            37,     /* left arrow */
                            39,     /* right arrow */
                            46,     /* delete */
                            229     /* FIX: android chromium always return 229 keycode */
                        ],
                        ctrl: [
                            65,     /* A */
                            67,     /* C */
                            86,     /* V */
                            88      /* X */
                        ]
                    };

                    $input.on('keydown', function(e) {
                        // Sprawdzenie czy nacisnieto klawisz dozwolony
                        if ((allowedKeyCodes.normal.indexOf(e.keyCode)) !== -1 ||
                            (e.ctrlKey === true && (allowedKeyCodes.ctrl.indexOf(e.keyCode) !== -1))) {
                            return;
                        }

                        // Sprawdzenie czy wprowadzono cyfre
                        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                            e.preventDefault();
                        }
                    });

                    $input.on('keyup', function(e) {
                        // FIX: android chromium maxlength
                        this.value = (this.value || '').replace(/[^0-9]/g, '').substr(0, PHONE_NO_LENGTH);
                        inputModelCtrl.$setViewValue(this.value);
                    });

                    $input.on('paste', function(e) {
                        e.preventDefault();

                        if ((e.originalEvent || e).clipboardData) {
                            var content = (e.originalEvent || e).clipboardData.getData('text/plain');
                            if (content != null) {
                                document.execCommand('insertText', false, content.replace(/[^0-9]/g, ''));
                            }
                        } else if(window.clipboardData) {
                            var content = window.clipboardData.getData('Text');
                            if (window.getSelection && content != null)
                                window.getSelection().getRangeAt(0).insertNode(document.createTextNode(content.replace(/[^0-9]/g, '')));
                        }
                    });

                    scope.$on("$destroy", function() {
                        $input.off();
                    });

                    // Dodanie walidatorow
                    inputModelCtrl.$validators.phoneNoLength = function(modelValue, viewValue) {
                        return (viewValue == null || viewValue.length == PHONE_NO_LENGTH);
                    };

                    inputModelCtrl.$validators.phoneRegex = function(modelValue, viewValue) {
                        return (viewValue == null || PHONE_NO_REGEX.test(viewValue));
                    };
                }
            };
        }
    };
});
