/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSelectType
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Select box for transaction filter.
 *
 * @param {object=} ngModel Target model for selected value.
 * @param {object=} list Available items data as received from resource service.
 * @param {boolean=} dsbld If `true` then the select box will be disabled.
 * @param {object=} inputForm Model of parent form - used for presenting validity.
 * @param {string} placeholderKey I18n key for placeholder text.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSelectType', function(translate, pathService) {

    return {
        restrict: 'EA',
        replace: false,
        scope: {
            ngModel: "=",
            list: "=",
            dsbld: "=",
            rel: "@", // nie używane
            inputForm: "=", // czy to jest potrzebne??? stan można wyjąć z ngModelu
            mandatory: "=",
            placeholderKey: "@"
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbSelectType/cbSelectType.html',
        link: function(scope, element, attrs) {

            scope.selectType = function(item, model) {
                scope.ngModel = item.value;
            }

            scope.$on('$destroy', function() {
                element.off();
            });
        }
    };
});
