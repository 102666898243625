/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbUtilsService
 *
 * @description
 * A utility service for managing validity of form fields.
 *
 * @requires $rootScope
 */

angular.module( 'halyk-ebanking-shared').service('cbUtilsService', function($rootScope) {

    return {
        /**
         * ngdoc method
         * @name clearServiceValidationErrors
         * @methodOf halyk-ebanking-shared.service:cbUtilsService
         *
         * @description
         * This method will clear validation errors that where returned from the backend
         * thus allowing to resend data for new validation.
         *
         * @param {object} element The model of the field to have validation error cleared.
         */
        clearServiceValidationErrors: function (element) { //pozbywamy się błędów, które przychodzą z serwera
            if (element) {
                var reset = false;
                for (var att in  element.$error) {
                    if (element.$error.hasOwnProperty(att)) {
                        element.$setValidity(att, true);
                        reset = true;
                    }
                }
                if (reset) {
                    element.$setPristine();
                    element.$setUntouched();
                    element.$validate();
                }
                if (element.$modelValue != element.$viewValue) {
                    element.$modelValue = element.$viewValue;
                }
            }
        },

        /**
         * ngdoc method
         * @name cleanValidationErrors
         * @methodOf halyk-ebanking-shared.service:cbUtilsService
         *
         * @description
         * This method will clear validation errors of form field.
         *
         * @param {object} form The model of the form.
         * @param {string} fieldName The name of the field.
         */
        cleanValidationErrors: function(form, fieldName) {
            if (form && fieldName) {
                if (!form[fieldName].$valid) {
                    for ( var key in form[fieldName].$error ) {
                        console.log('Clear validation error: ' + key);
                        form[fieldName].$setValidity(key, true);
                    }
                }
            }
        }

    }
});
