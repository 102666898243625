/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbCollapseLabel
 * @restrict EA
 * @scope
 *
 * @description
 * Component displays collapse container label. When user clicks the label it toggles associated collapse container.
 *
 * @param {boolean=} ngModel Model of collapse state.
 * @param {string} collapseLabel Label displayed when when assosiated container is collapsed.
 * @param {string} label Label displayed when when assosiated container is rised.
 */
angular.module( 'halyk-ebanking-shared').directive('cbCollapseLabel', function(pathService) {
    return {
        restrict: 'EA',
        replace: false,
        scope: {
            ngModel: "=",
            collapseLabel: "@",
            label: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbCollapseLabel/cbCollapseLabel.html',

        link: function(scope, element, attrs) {
        }
    };
});
