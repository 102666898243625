/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTemplateLink
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Component for selecting payment template.
 *
 * @param {object=} ebForm Model of the parent form.
 * @param {object=} ebFormData Data asossiated with the parent form.
 * @param {object=} ebText Object containing label of the link - the component uses field named `link': `ebText.link` and labels and messages used by template select dialog.
 * @param {object} ebDefaultTemplate Default template to be used.
 * @param {string} paymentType The type of the payment.
 */

angular.module( 'halyk-ebanking-shared').directive('cbTemplateLink', function(ebTheming, pathService, $uibModal, templateModelService) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbTemplateLink/cbTemplateLink.html',
        scope: {
            ebForm: '@',
            ebFormData: '=',
            ebText: '=',
            ebDefaultTemplate: '=?',
            ebControlChangeEvent: '=?',
            paymentType: "@"
        },
        link: function postLink(scope, element, attrs) {

            ebTheming.applyTheme(element);

            scope.goToTemplateModal = function() {
                scope.modalIsActive = true;

                var selectDialog = $uibModal.open({
                    template: '<cb-template-modal eb-form="{{form}}" payment-type="{{paymentType}}" eb-form-data="formData" eb-text="text" eb-on-close="close()" eb-confirm="confirm()" eb-accept="accept()"></eb-insurance-template-modal>',
                    windowClass: 'eb-modal-dialog',
                    backdropClass: 'always-on',
                    controller: function ($scope) {
                        angular.extend($scope, {
                            form: scope.ebForm,
                            formData: scope.ebFormData,
                            text: scope.ebText,
                            paymentType: scope.paymentType ? scope.paymentType : "INSURANCE",
                            close: function() {
                                selectDialog.dismiss();
                            },
                            confirm: function() {
                                selectDialog.dismiss();
                            },
                            accept: function() {
                                scope.ebControlChangeEvent = true;
                                selectDialog.dismiss();
                            }
                        });
                    }
                });

                selectDialog.result.then(function() {}, function() {
                    scope.modalIsActive = false;
                });
            };

            if (scope.ebDefaultTemplate != null) {
                scope.ebFormData.formModel = templateModelService.getModelByTemplateType(scope.paymentType ? scope.paymentType : "INSURANCE", scope.ebDefaultTemplate, scope.ebFormData.formModel);
                scope.ebFormData.recipientAddress = scope.ebFormData.formModel.recipientAddress;
                scope.ebFormData.recipientName = scope.ebFormData.formModel.recipientName;
                scope.ebControlChangeEvent = true;
            }

        }
    }
});
