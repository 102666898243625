/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInsuranceAccountSelect
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * Select box for selecting insurance account.
 *
 * @param {string=} ngModel Model for account value.
 * @param {string=} monitorValue Helper model for ragistering values to discriminate from set by payment template.
 * @param {string} insuranceAccountsKey Prefix of i18n label keys for insurance accounts.
 * @param {siring} placeholder A text to be presented inside the field when no user data is entered.
 */

angular.module( 'halyk-ebanking-shared').directive('cbInsuranceAccountSelect', function (pathService, insuranceAccountsDictionary) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ngModel: "=",
            monitorValue: "=?", // to nie powinno tak być! problemy z tym związane należy rozwiązać w kontrolerze lub serwisie
            insuranceAccountsKey: "@",
            placeholder: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInsuranceAccountSelect/cbInsuranceAccountSelect.html',

        link: function postLink(scope, element, attrs) {
            scope.selectedValue = {
                selected: undefined
            };

            scope.insuranceAccounts = insuranceAccountsDictionary;

            scope.$watch("ngModel", function (newValue, oldValue) {
                var reevaluating = false;
                if (newValue != undefined && (newValue != oldValue || scope.selectedValue.selected == undefined)) {
                    for (var i = 0; i < scope.insuranceAccounts.length; i++) {
                        if (scope.insuranceAccounts[i].accountNo == newValue ||
                            scope.insuranceAccounts[i].key == newValue) {
                            scope.selectedValue.selected = scope.insuranceAccounts[i];
                            scope.ngModel = scope.insuranceAccounts[i].model
                            reevaluating = true;
                            break;
                        }
                        if (scope.insuranceAccounts[i].model == newValue) {
                            scope.selectedAccountNo = scope.insuranceAccounts[i].accountNo;
                        }
                    }
                    if(!reevaluating && scope.monitorValue != undefined) {
                        scope.monitorValue = newValue;
                    }
                    scope.selectedValue.selected = newValue;
                }
            }, true);

            scope.selectInsuranceAccount = function (item, model) {
                scope.ngModel = item.model;
            };

        }
    }
});
