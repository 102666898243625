/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTemplateModal
 *
 * @description
 * cbTemplateModal
 */

angular.module( 'halyk-ebanking-shared').directive('cbTemplateModal', function(pathService, ebTheming) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            form: '@ebForm',
            formData: '=ebFormData',
            text: '=ebText',
            close: '&ebOnClose',
            accept: '&ebAccept',
            confirm: '&ebConfirm',
            paymentType: '@'
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbTemplateModal/cbTemplateModal.html',
        link: function postLink(scope, element, attrs) {

            ebTheming.applyTheme(element);

        }
    };
});
