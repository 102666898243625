
/**
 * @ngdoc filter
 * @name ebFilterModule.filter:timeRemaining
 *
 * @description
 * timeRemaining
 */
angular.module( 'halyk-ebanking-shared').filter('timeRemaining', function ($filter, translate) {
    return function (item) {

        var then = new Date(item);
        var now = new Date();

        var message = "";

        var days = 0;
        var diffindays = 0;
        var dfb = 0;

        var now_date = now.getDate();
        var now_month = now.getMonth();
        var now_year = now.getFullYear();
        var then_date = then.getDate();
        var then_month = then.getMonth();
        var then_year = then.getFullYear();

        var message_days = "";

        var message_ago = translate.property("timeRemainingFilter.ago");
        var message_in = translate.property("timeRemainingFilter.in");

        var date = $filter('date')(then, 'dd.MM.yyyy HH:mm');

        examine_day = function (day) {
            if(day == 1)
                return translate.property("timeRemainingFilter.day_01");
            else
                return translate.property("timeRemainingFilter.day_02");
        };

        days_in_month = function (month, year) {
            if(month == 1)
            {
                if(year % 4 ==  0)
                    return 29;
                else
                    return 28;
            }
            else if(month == 3 || month == 5 || month == 8 || month == 10)
                return 30;
            else
                return 31;
        };

        days_in_year = function (year) {
            if (year % 4 == 0)
                return 366;
            else
                return 365;
        }

        if(then_year == now_year)
        {
            if(then_month == now_month)
            {
                if(then_date == now_date)
                {
                    message = translate.property("timeRemainingFilter.today");

                }
                else
                {
                    days = then_date - now_date;
                    message_days = examine_day(days);
                    if(days < 0)
                        if(days == -1)
                            message = translate.property("timeRemainingFilter.yesterday");
                        else
                            message =  Math.abs(days) + message_days + " " + message_ago;
                    else //if(days > 0)
                        if(days == 1)
                            message = translate.property("timeRemainingFilter.tomorrow");
                        else
                            message = message_in + days + " " + message_days;
                }
            }
            else if(then_month > now_month)
            {
                for(i = now_month; i < then_month; i++)
                    dfb += days_in_month(i, now_year);

                if(then_date == now_date)
                {
                    message_days = examine_day(dfb);
                    message = message_in + dfb + " " + message_days;
                }
                else
                {
                    days = then_date - now_date;

                    if(days > 0)
                    {
                        days += dfb;
                        message_days = examine_day(days);
                        message = message_in + days + " " + message_days ;

                    }
                    else //if(days < 0)
                    {
                        diffindays = now_date - then_date;

                        dfb -= diffindays;
                        message_days = examine_day(dfb);

                        message = message_in + dfb + " " + message_days ;
                    }
                }
            }
            else //if(then_month < now_month)
            {
                for(i = now_month; i > then_month; i--)
                    dfb += days_in_month(i - 1, now_year);

                if(then_date == now_date)
                {
                    message_days = examine_day(dfb);
                    message =  dfb + " " + message_days + message_ago;
                }
                else
                {
                    days = then_date - now_date;

                    if(days > 0)
                    {
                        dfb -= days;
                        message_days = examine_day(dfb);
                        message =  dfb + " " + message_days + message_ago;
                    }
                    else //if(days < 0)
                    {
                        dfb -= days;
                        message_days = examine_day(dfb);

                        message =  dfb + " " + message_days + message_ago;
                    }
                }
            }
        }

        if(then_year > now_year)
        {
            dfb = days_in_month(now_month, now_year) - now_date;
            for(i = now_month + 1; i <= 11; i++)
                dfb += days_in_month(i, now_year);
            for(i = now_year + 1; i < then_year; i++)
                dfb += days_in_year(i);
            for(i = 0; i < then_month; i++)
                dfb += days_in_month(i, then_year);
            dfb += then_date;

            message_days = examine_day(dfb);
            message = message_in + dfb + " " + message_days;
        }

        if(then_year < now_year)
        {
            dfb = days_in_month(then_month, then_year) - then_date;
            for(i = then_month + 1; i <= 11; i++)
                dfb += days_in_month(i, then_year);
            for(i = then_year + 1; i < now_year; i++)
                dfb += days_in_year(i);
            for(i = 0; i < now_month; i++)
                dfb += days_in_month(i, now_year);
            dfb += now_date;

            message_days = examine_day(dfb);
            message = dfb + " " + message_days + message_ago;
        }

        return message;
    };
});
