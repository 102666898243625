/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTemplateSelect
 *
 * @description
 * cbTemplateSelect
 */

angular.module( 'halyk-ebanking-shared').directive('cbTemplateSelect', function(pathService, ebTheming, recipientsService, templateModelService, searchWrapperService, insuranceAccountsDictionary, translate) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        transclude: true,
        scope: {
            model: '=',
            ebAccept: '&',
            placeholder: '@',
            paymentType: '@',
            insurancePaymentAccountKey: "@",
            insurancePaymentTypesKey: "@",
            selectNoTemplatesKey: "@",
            selectNoChooseTemplatesKey: "@"
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbTemplateSelect/cbTemplateSelect.html',
        link: function postLink(scope, element, attrs) {

            ebTheming.applyTheme(element);

            element.querySelectorAll('input.insurance-search-input')[0].focus();

            scope.searchText = "";

            scope.insuranceAccounts = insuranceAccountsDictionary;

            var wrapTemplatesText = function(){
                scope.templatesList = scope.templatesList.map(function(template){
                    var obj = searchWrapperService.wrapTemplateFieldsForSelect(template);
                    if (template.recipientAddress) {
                        obj.wrappedText += template.recipientAddress[0];
                    }
                    if (template.beneficiaryAccountNoName) {
                        obj.wrappedText += 'ZUS - ' + translate.property(scope.insurancePaymentAccountKey+template.beneficiaryAccountNoName.toLowerCase())+' ';
                    }
                    if (template.paymentDetails && template.paymentDetails.paymentType) {
                        obj.wrappedText += template.paymentDetails.paymentType+' - '+translate.property(scope.insurancePaymentTypesKey+template.paymentDetails.paymentType);
                    }
                    return angular.extend(template,obj);
                });
            }

            scope.templatesPromise = recipientsService.search({
                filerTemplateType: scope.paymentType,
                pageSize: 10000
            }).then(function(data) {
                var recipientList = data.content;
                var templatesList = [];

                for (var i = 0; i < recipientList.length; i++){
                    for (var j = recipientList[i].paymentTemplates.length - 1; j >= 0; j--) {
                        recipientList[i].paymentTemplates[j].recipientName = recipientList[i].recipientName;
                        recipientList[i].paymentTemplates[j].recipientAddress = recipientList[i].recipientAddress;
                        templatesList.push(recipientList[i].paymentTemplates[j]);
                    };
                };

                if (scope.paymentType == 'INSURANCE') {
                    prepareInsuranceList(templatesList);
                }
                scope.templatesList = templatesList;
                wrapTemplatesText();
            });

            function prepareInsuranceList(list){
                for (var i = 0; i < list.length; i++) {
                    prepareInsuranceItem(list[i]);
                }
            }

            function getInsuranceDestinationTypeByAccountNo(value){
                var re = /\s/g;
                for(var i = 0; i < scope.insuranceAccounts.length; i++){
                    if (scope.insuranceAccounts[i].model == value) {
                        return value;
                    } else if(value.replace(re,"") == scope.insuranceAccounts[i].accountNo.replace(re,"")){
                        return scope.insuranceAccounts[i].model;
                    }
                }
            }

            function prepareInsuranceItem(item){
                item.beneficiaryAccountNoName = getInsuranceDestinationTypeByAccountNo(item.beneficiaryAccountNo);
            }

            scope.selectTemplate = function(template, event) {
                scope.model.formModel = templateModelService.getModelByTemplateType(scope.paymentType, template, scope.model.formModel);
                scope.model.recipientAddress = scope.model.formModel.recipientAddress;
                scope.model.recipientName = scope.model.formModel.recipientName;
                scope.ebAccept();
            };



        }
    };
});
