/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbUploadButtonMultipart
 * @restrict EA
 * @scope
 *
 * @description
 *
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
 <div>
 <cb-accounts-currency-select ng-model='ngModel.currency' placeholder='Select'></cb-accounts-currency-select>
 </div>
 * ```
 */


angular.module('halyk-ebanking-shared').directive('cbUploadButtonMultipart'
    , function ($compile
        , $rootScope
        , $q
        , pathService
        , cbUploadButtonService
        , isNullCheckService
        , cbNotificationService
        , translate) {
        return {
            restrict: 'EA',
            replace: false,
            require: 'ngModel',
            scope: {
                paymentId: '=',
                btnId: '@',
                model: '=ngModel'
                , contentList: '='
                , context: '='
                , ebDisabled: "=?"
                , onlySave: '@'
                , showSuccessMessage: '@'
                , uploadAfterSelect: "@"
                , callback: "&"
            }
            ,
            templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbUploadButtonMultipart/cbUploadButtonMultipart.html',
            link: function postLink(scope, element, attr) {
                scope.files = [];
                scope.fileReading = false;
                scope.uploadingPromise = false;

                scope.getArgs = function () {
                    if (angular.isDefined(attr.context)) {
                        return cbUploadButtonService.getArgs(scope.files, scope.context);
                    }
                    return {
                        'uploadFiles': files
                        , 'emailSubject': subject
                        , 'emailBody': body
                    };
                };

                element.bind('change', function () {
                        scope.fileReading = true;
                        loadData();
                    }
                );

                scope.removeChoice = function (index) {
                    var file = scope.files[index];
                    cbUploadButtonService.deleteFile(file.fileId);
                    scope.files.splice(index, 1);
                };

                function showError(error) {
                    cbNotificationService.showWarningDialog({
                        title: "common.title.error",
                        preparedMessage: error.fileName + ': <p>' + (error && error.resultCode ? translate.property(error.resultCode) : translate.property("server.messages.unknown.error")),
                        confirm: "common.button.close"
                    });
                }

                function executeCallback() {
                    if (scope.callback) {
                        scope.callback({});
                    }
                }

                function sendFileToEmail(filePromises, item) {
                    let args = scope.getArgs();
                    filePromises.push(cbUploadButtonService.sendFileToEmail(
                            item,
                            args.emailSubject,
                            args.emailBody,
                            scope.paymentId
                        ).then(function (result) {
                            scope.files.push({
                                    'fileId': result.fileId,
                                    'fileName': result.fileName
                                }
                            );
                            if(scope.model) {
                                scope.model.push({
                                    'id': result.fileId,
                                    'fileName': result.fileName
                                });
                            }
                            executeCallback();
                        }, function (error) {
                            showError(error);
                        })
                    );
                }

                function uploadFileMultipart(filePromises, item) {
                    filePromises.push(cbUploadButtonService.uploadFileMultipart(item, scope.context.id)
                        .then(function (result) {
                            scope.files.push({
                                    'fileId': result.fileId,
                                    'fileName': result.fileName
                                }
                            );
                            if(scope.model) {
                                scope.model.push({
                                    'id': result.fileId,
                                    'fileName': result.fileName
                                });
                            }
                        }, function (error) {
                            showError(error);
                        })
                    );
                }

                var loadData = function () {
                    var inputFiles = document.getElementById('file-upload-' + scope.btnId).files;
                    var filesCount = inputFiles.length;
                    scope.fileReading = true;
                    var filePromises = [];

                    for (var i = 0; i < filesCount; i++) {
                        var item = inputFiles[i];
                        var onlySave = scope.onlySave || 'true';
                        if (onlySave === 'true') {
                            uploadFileMultipart(filePromises, item);
                        } else {
                            sendFileToEmail(filePromises, item);
                        }
                        scope.fileReading = true;
                    }
                    $q.all(filePromises).finally(function (result) {
                        scope.fileReading = false;
                        var input = document.getElementById('file-upload-' + scope.btnId);
                        input.value = '';
                        executeCallback();
                    });
                }
            }

        };
    }
);
