'use strict';

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:otpService

 * @description
 * paymentsService.
 */
angular.module( 'halyk-ebanking-shared').service('otpService', function (
    $rootScope
    , userService
    , gate
    , $q
    , $log) {

    return {
         getSMSAuth: function (data) {
            return userService.getUserDetails().then(
                function (detail) {
                    var params = {
                        userIdentity: detail.userIdentityId
                    };
                    return gate.command("get_sms_auth", params, data).then(
                        function (response) {
                            if(response && response.success === false){
                                return $q.reject(response);
                            }
                            return response.data;
                        }, function () {
                            $log.error("Error sending statement");
                            return $q.reject("Błąd utworzenie transferu.");
                        });
                }
            );
        },
        revenueServiceCheck: function () {
            return userService.getUserDetails().then(
                function (detail) {
                    var params = {masterId: detail.userIdentityId.id,
                    };
                    return gate.command("revenue_service_check", params).then(
                        function (response) {
                            return response;
                        }, function (error) {
                            console.error(error.message);
                            return $q.reject("Błąd utworzenie transferu.");
                        });
                }
            );
        },
    };
});
