/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAppHeader
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Content view header to be used on full screen widget. It contain navigation elements - close and back, view title/information
 * (icon, title and extra label/value), and action panel where view control elements (like "new", "edit", 'history", etc.) are transcluded.
 *
 * @param {string} headerText Text to displayed as header title/information.
 * @param {string} headerExtraLabel Optional label of extra data to be displayed in the header.
 * @param {string} headerExtraValue Optional extra data to be displayed in the header.
 * @param {string} backState Name of target state when user clicks back navigation.
 * @param {string} dashboardState Name of target state when user clicks close navigation.
 * @param {object=} params Optional parameters for backState navigation.
 * @param {string} appIcon Name of icon.
 * @param {boolean=} toRight Action panel aligment - if `true` panel will be aligned to the right, else to the left side of the header.
 * @param {boolean=} actionPanel Flag cotrolling appearance of action panel.
 * @param {boolean=} actionPanelHideXs Flag cotrolling appearance of action panel on very small devices.
 * @param {expression} onBack Action to be executed when user clicks back navigation.
 * @param {expression} onClose Action to be executed when user clicks close navigation.
 */

angular.module( 'halyk-ebanking-shared').directive('cbAppHeader', function(pathService, ebTheming, $timeout, $state, $q, viewStackService) {

    return {
        restrict: 'EA',
        transclude: true,
        scope: {
            headerText: "@",
            headerExtraLabel: "@",
            headerExtraValue: "@",
            backState: "@",
            dashboardState: "@",
            params: '=',
            appIcon: "@",
            toRight: "=",
            actionPanel: '=',
            actionPanelHideXs: '@',
            onBack: '&?',
            onClose: '&?'
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbAppHeader/cbAppHeader.html',
        link: function preLink(scope, element, attrs) {
            ebTheming.applyTheme(element);

            scope.enableOptions = attrs.options != null ? true : false;
            console.log('EXTRA VALUE', scope.headerExtraValue);

            var go = function(state, params, callback, action) {
                $timeout(function() {
                    $q.when(callback != null ? callback() : null).then(function(value) {
                        if (value === false || !state) {
                            return;
                        }

                        if (action && !viewStackService.isStackEmpty()) {
                            action();
                            return;
                        }

                        if (params) {
                            $state.go(state, params);
                        } else {
                            $state.go(state, {});
                        }
                    });
                });
            };

            scope.isBack = (scope.backState || attrs.onBack);
            scope.isClose = (scope.dashboardState || attrs.onClose);

            scope.back = function() {
                go(scope.backState, scope.params, scope.onBack, viewStackService.goToPreviousState);
            };

            scope.close = function() {
                go(scope.dashboardState, undefined, scope.onClose, viewStackService.goToDefaultState);
            };
        }
    }
});
