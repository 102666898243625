/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbCurrencySelect
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down for currency selection. Available currencies are loaded from `currenciesService` and contain all available currencies for transactions.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
    <div>
        <cb-currency-select ng-model="ngModel.currency" placeholder="Select"></cb-currency-select>
    </div>
 * ```
*/

angular.module( 'halyk-ebanking-shared').directive('cbCurrencySelect', function( pathService, currenciesService, $timeout) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            placeholder: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbCurrencySelect/cbCurrencySelect.html',

        link: function postLink(scope, element, attrs, ngModel) {

            scope.params = {
                fields: 'currency',
                pageSize: 10000
            };

            scope.currenciesList = [scope.placeholder];

            scope.currenciesPromise = currenciesService.search().then( function( data) {
                angular.forEach( data.content, function( value) {
                    if(scope.currenciesList.indexOf( value.content) == -1) {
                        scope.currenciesList.push( value.content);
                    }
                })
            });

            scope.selectCurrency = function( item, model) {
                if (model === scope.placeholder) {
                    scope.ngModel = null;
                }
                else {
                    scope.ngModel = model;
                }
            };

        }
    };
});