/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbGetSMS
 * @restrict EA
 * @scope
 *
 * @description
 *
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
 <div>
 <cb-accounts-currency-select ng-model="ngModel.currency" placeholder="Select"></cb-accounts-currency-select>
 </div>
 * ```
 */


angular.module('halyk-ebanking-shared').directive('cbGetSms', function (
    $state
    , $compile
    , $rootScope
    , pathService
    , $timeout
    , $interval
    , otpService
    , translate
    , isNullCheckService
    , cbNotificationService
    , localStore) {
    return {
        restrict: 'EA'
        , scope: {
            callback: "&"
            , buttonText: "@"
            , validateForm: "&"
            , backState: "@"
            , customService: "=?"
        }
        , templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbGetSms/cbGetSms.html',
        link: function postLink(scope, element, attr, ngModel) {

            scope.smsSended = false;
            scope.getSmsAgain = false;
            scope.sessionExpired = false;
            scope.notPerfomSmsFunction = null;
            scope.smsMaskTimeout = null;
            scope.counterTimerInterval = null;
            scope.confirmTransfer = {};
            scope.smsCodeMasked = '';
            scope.errorCode = null;
            scope.waiting = false;

            if (!angular.isDefined(attr.validateForm)) {
                scope.validateForm = undefined;
            }

            scope.counter = '60';

            scope.smsAuth = {
                smsCode: null,
                sessionId: null
            };

            function getResultMessage(input) {

                var translatedResultCode = translate.property(input.resultCode);

                var result = translatedResultCode;

                if (translatedResultCode != null
                    && isNullCheckService.isNotNullOrWhiteSpace(input.message)
                    && input.message.length > 0) {

                    result = translatedResultCode.concat(": ").concat(input.message);
                }

                return result;
            };

            scope.performTransfer = function () {

                if (typeof scope.confirmTransfer.smsCode === 'string') {
                    scope.smsCode = scope.confirmTransfer.smsCode;
                } else {
                    scope.smsCode = angular.copy(scope.confirmTransfer.smsCode.join());
                }

                var message = "";
                scope.waiting = true;

                scope.callback({sms: scope.smsCode}).then(
                    function (result) {
                        scope.waiting = false;
                        if (result != null && result !== undefined) {

                            if (result.success == false || result.result == false) {

                                scope.errorCode = translate.property(result.resultCode);
                                scope.confirmTransfer.smsCode = [];

                                if (result && result.failCallback != null) {
                                    result.failCallback();
                                }

                                message = getResultMessage(result);
                                message = message != null ? message : translate.property('payment.transfer.complete.error');
                                showError(message);
                            } else {

                                scope.errorCode = null;

                                if (result && result.successCallback != null)
                                    result.successCallback();

                                message = getResultMessage(result);
                                //    showOk ( message );
                                //    showOk ( result.resultCode+"1" );


                                if (result.resultCode == "server.messages.operation.successful" &&

                                    $state.current.name == "cu.payments.confirmInterBankGelTransfer"

                                    && scope.$parent.$parent.confirmTransfer.sum <= 1500) {
                                    showOk(result.resultCode);
                                } else if (result.resultCode == "server.messages.operation.successful") {
                                    showOk(result.resultCode + "1");
                                } else {
                                    showOk(message);
                                }
                            }
                        }

                    }
                ).catch(
                    function (error) {
                        scope.waiting = false;
                        scope.errorCode = translate.property(error && error.resultCode ? error.resultCode : "server.response.handling.error");
                        scope.confirmTransfer.smsCode = [];
                        showError(scope.errorCode);
                    }
                );
            };

            var showOk = function (data) {
                return cbNotificationService.showPaymentCancelledDialog({
                    text: {
                        title: 'payment.otp.message.title',
                        confirmationText: data,
                        back: 'payments.pay_a_bill.message.back'
                    },
                    state: {
                        dashboard: scope.backState// || 'dashboard'
                    }
                });
            };

            var showError = function (data) {
                return cbNotificationService.showWarningDialog({
                    title: 'payment.otp.message.title',
                    message: data,
                    confirm: 'payments.pay_a_bill.message.close'
                });
            };

            scope.getSMS = function (form = null) {
                if (angular.isDefined(scope.validateForm) && !scope.validateForm()) {
                    return;
                }

                var textArea = element.find('textarea');

                var params = {
                    _links: {
                        "get_sms_auth": {
                            "href": "/api/rstyle_internal/actions/get_sms_auth.json"
                        }
                    }
                };
                if(scope.customService){
                    otpService.revenueServiceCheck().then();
                } else {
                    otpService.getSMSAuth(params, params).then(function (response) {
                        scope.smsAuth = response;
                    }).catch(function(result){
                        var message = getResultMessage(result);
                        message = message != null ? message : translate.property('payment.transfer.complete.error');
                        showError(message);
                    });
                }
                scope.confirmTransfer.smsCode = [];
                scope.smsCodeMasked = '';
                scope.errorCode = null;
                scope.getSmsAgain = false;
                scope.smsSended = true;
                scope.sessionExpired = false;
                scope.counter = '60';
                if (scope.notPerfomSmsFunction != null) {
                    $timeout.cancel(scope.notPerfomSmsFunction);
                }
                if (scope.counterTimerInterval != null) {
                    $interval.cancel(scope.counterTimerInterval);
                }

                if (angular.isDefined(textArea[0])) {
                    textArea[0].focus();
                }

                scope.notPerfomSmsFunction = $timeout(notPerfomSmsFunction, scope.counter * 1000);
                scope.counterTimerInterval = $interval(updateCounter, 1000);
            };

            scope.getSMS();

            function updateCounter() {
                if (!scope.smsSended) {
                    return;
                }
                ;
                scope.counter--;
            }

            function notPerfomSmsFunction() {
                scope.smsSended = false;
                scope.getSmsAgain = false;
                scope.sessionExpired = true;
                scope.errorCode = null;
            };

            scope.$on('$destroy', function () {
                $timeout.cancel(scope.notPerfomSmsFunction);
                $interval.cancel(scope.counterTimerInterval);
            });
        }
    };
});
