/* globals angular */

/**
 * @ngdoc overview
 * @name halyk-ebanking-shared
 *
 * @description
 * banking shared module
 */

angular.module('halyk-ebanking-shared', [

    'platform',
    'ui.mask'

]).config(function (translationsLoaderProvider, pathServiceProvider) {

    translationsLoaderProvider.addTranslationsPath(pathServiceProvider.generateRootPath('halyk-ebanking-shared') + "/i18n/messages_{{language}}.json");

});
