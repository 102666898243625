/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSelectDark
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Select box with dark background.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSelectDark', function($compile, $rootScope, $timeout, $log, $http, $templateCache, pathService, accountsService, $location, $state, paymentType, gate) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ngModel: "=?",
            skipTypes: "@",
            placeholder: "@", //db.payments.header.own
            messageKeyPrefix: "@", //db.payments.header
            cbDark: "=",
            cbTargetClassName: "@",
            cbMenuPosition: "@",
            cbAppendToBody: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSelectDark/cbSelectDark.html',

        link: function postLink(scope, element, attrs) {

            scope.peymentListConfig = {
                selectedPayment: "OWN"
            }

            scope.paymentListPromise = paymentType.getPaymentTypes().then(function(data){
                var availableTypes = [];
                if (angular.isDefined(scope.blockedTypes) || angular.isDefined(scope.skipTypes)) {
                    for (var i = 0 ; i < data.length; i++){
                        if ((scope.blockedTypes == null || scope.blockedTypes.indexOf(data[i].value) == -1)
                            && (scope.skipTypes == null || scope.skipTypes.indexOf(data[i].value) == -1)) {
                            availableTypes.push(data[i]);
                        }
                    }
                } else {
                    availableTypes = data;
                }

                scope.paymentTypeList = availableTypes;
                scope.peymentListConfig = {
                    selectedPayment: scope.paymentTypeList[0]
                }
            });

            if(scope.ngModel == null) {
                scope.ngModel = paymentType.getDefaultPaymentType();
            }

            scope.selectPaymentType = function(item) {
                var formType = item.value;
                var formData = {
                    formModel: {},
                    formType: formType
                }
                var paymentFormInitialState = {}
                var stateName = 'cu.payments.form.' + angular.lowercase(formType);

                $state.go(stateName, { formType: formType });
            }

        }
    }
});
