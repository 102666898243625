/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbModalSectionHeader
 *
 * @restrict EA
 * @scope
 *
 * @description
 * cbModalSectionHeader
 */

angular.module( 'halyk-ebanking-shared').directive('cbModalSectionHeader', function (pathService, $state, ebTheming, $timeout) {

	return {
		restrict: 'EA',
		replace: true,
		transclude: true,
		scope: {
			header: '@cbHeader',
			headerExtraLabel: '@cbHeaderExtraLabel',
			headerExtraValue: '@cbHeaderExtraValue',
			backState: '@',
			params: '=',
			closeFn: '&',
			back: '@cbHideBack',
			titlewidth: '@titleWidth',
			cbNowrap: '@'
		},
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbModalSectionHeader/cbModalSectionHeader.html',
        link: function preLink(scope, element, attrs) {
            ebTheming.applyTheme(element);

            scope.enableOptions = attrs.options != null ? true : false;

            scope.goBack = function() {
		        $timeout(function() {
			        if(scope.backState != null) {
			            if (angular.isUndefined(scope.params) || scope.params === null) {
			                $state.go(scope.backState, {});
			            } else {
			                $state.go(scope.backState, scope.params);
			            }
			        }
		        });
            }

            scope.closeme2 = function() {
            	scope.closeFn();
            }
        }
	}
});
