/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbChoiceDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module( 'halyk-ebanking-shared').directive('cbChoiceDialog', function (pathService, translate, localStore, $interval, $timeout, $http, otpService, viewStateService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            title: '@ebTitle',
            message: '@ebMessage',
            input: '=ebInput',
            close: '&ebOnClose',
            card: '@ebCard',
            choices: '=ebChoices',
            smsVerification: '=?ebSmsVerification'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbChoiceDialog/cbChoiceDialog.html"
		,link: function postLink ( scope , element , attrs ) {
			scope.model = {}
			scope.showForm=false;
			scope.smsCode='';
			scope.isSmsVerification = scope.smsVerification;
            scope.counterTimerInterval = null;

            if(angular.isDefined(scope.card)){
                scope.cardData = JSON.parse(scope.card);
            }

			if(scope.choices && scope.choices.length > 1){
                scope.icon = "question-icon";
            }

			scope.$watch("model.input", 
				function () {
					viewStateService.addFormData('cb.choice.dialog', 'inputValue', scope.model.input);
				}
			);

			scope.getSMS = function (){
               var params = {
                    _links: {
                        "get_sms_auth" : {
                            "href" : "/api/rstyle_internal/actions/get_sms_auth.json"
                        }
                    }
                };

                otpService.getSMSAuth(params,params).then(function( response ) {
                    scope.smsAuth = response;
                });

                scope.errorCode = null;
                scope.getSmsAgain = false;
                scope.smsSended = true;
                scope.sessionExpired =  false;

                scope.counter = localStore.get( "sms_session_interval" );

                if ( scope.counterTimerInterval != null ){
                       $interval.cancel( scope.counterTimerInterval );
                   }
                   scope.notPerfomSmsFunction = $timeout(notPerfomSmsFunction, scope.counter*1000);
                   scope.counterTimerInterval = $interval(updateCounter, 1000);
            };

            function notPerfomSmsFunction() {
                scope.smsSended = false;
                scope.getSmsAgain = false;
                scope.sessionExpired = true;
                scope.errorCode = null;
            };

            function updateCounter() {
                if( !scope.smsSended ){
                    return;
                };
                scope.counter --;
            }

			scope.checkSmsCode = function (smsCode){
                var req = {
                    method: 'POST',
                    url: '/frontend-web/api/rstyle_internal/actions/digital_card_register.json',
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        cardId: scope.cardData.cardId,
                        code: smsCode
                    }
                }
                scope.errorCode = null;

                $http(req).then(function(resp){
                    if(resp.data.resultCode == 'server.messages.otp.validation.failed'){
                        scope.errorCode = translate.property ( resp.data.resultCode );
                        return;
                    }

                    if(resp.data.data.cardId && resp.data.data.requestId){
                        document.returnform.cardId.value=resp.data.data.cardId;
                        document.returnform.requestId.value=resp.data.data.requestId;
                        document.returnform.submit();
                        scope.close();
                    } else{
                        scope.errorCode=translate.property ( 'server.response.handling.error' );
                    }
                }, function(resp){
                    console.log(resp)
                });
            };

            scope.$on('$destroy', function() {
                $timeout.cancel(  scope.notPerfomSmsFunction );
                $interval.cancel( scope.counterTimerInterval );
            });

		}
    };
});
