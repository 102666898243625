/* globals angular */

/**
 * @ngdoc filter
 * @name ebFilterModule.filter:newLineFilter
 *
 * @description
 * newLineFilter
 */

angular.module( 'halyk-ebanking-shared').filter('newLineFilter', function() {
	return function(text) {
		if(text != null) {
			return text.split(/\n/g);
		}
	};
})
