/**
 * Created by Kvitko on 10.02.2017.
 */
angular.module('halyk-ebanking-shared').directive('cbLicenseContainer', function ($window, pathService) {

    function getStyleInt(elem, prop) {
        try {
            return parseInt(window.getComputedStyle(elem, null).getPropertyValue(prop), 10);
        } catch (e) {
            return parseInt(elem.currentStyle[prop], 10);
        }
    }

    function getElementDimension(elem, prop) {
     return getStyleInt(elem, 'height') +
            getStyleInt(elem, 'padding-top') +
            getStyleInt(elem, 'padding-bottom');
    }

    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbLicenseContainer/cbLicenseContainer.html",
        scope: {
            contract: '@',
            callback: '=scrollToBottom'
        },
        link: function (scope, elem, attr) {
            if ( !angular.isDefined( scope.contract ) ){
                scope.contract = false;
            }
            var callback = scope.callback || function () {
                };
            var boundElement =  elem;
            var oldScrollY = 0;
            var handleScroll = function () {
                var contentHeight;
                var viewportHeight;
                var scrollY;

                var domElement = boundElement[0];
                contentHeight = domElement.scrollHeight;
                viewportHeight = getElementDimension(domElement, 'height')-15;
                scrollY = domElement.scrollTop;

                var scrollWasInYDirection = oldScrollY !== scrollY;

                oldScrollY = scrollY;

                if (scrollWasInYDirection && scrollY >= contentHeight - viewportHeight) {
                    callback();
                }
            };
            boundElement.bind('scroll', handleScroll);

            scope.$on('$destroy', function () {
                boundElement.unbind('scroll', handleScroll);
            });
        }
    };
});
