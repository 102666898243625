
/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSimpleAdditionalInput
 *
 * @description
 * cbSimpleAdditionalInput
 */
angular.module( 'halyk-ebanking-shared').directive('cbSimpleAdditionalInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            dependentValue: '=?',
            maxLength: '@',
            ebForm: '=?',
            ebName: '@',
            placeholder: '@',
            ngModel: '=',
            ebDisabled: '=?',
            ebRequired: '=?'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSimpleAdditionalInput/cbSimpleAdditionalInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);
            scope.ebRequired = angular.isDefined(scope.ebRequired) ? scope.ebRequired : true;
            scope.ebDisabled = angular.isDefined(scope.ebDisabled) ? scope.ebDisabled : false;

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input.value = ngModelController.$viewValue || '';
                });
            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            scope.maxLength = scope.maxLength || 14;

            scope.$watch('dependentValue', function(newVal) {
                if (angular.isDefined(newVal)) {
                    ngModelController.$validate();
                }
            });

        }
    }

});