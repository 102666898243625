/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDialog
 * @restrict EA
 * @scope
 *
 * @description
 * Dialog presenting a message. Directive content is transcluded to action area of the dialog.
 *
 * @param {string} ebTitle Dialog title.
 * @param {string} ebMessage Message displayed on dialog main area.
 * @param {string} ebIcon Name of icon - it will be presented before the message.
 * @param {expression} ebOnClose Action to execute when user closes the dialog.
 */

angular.module( 'halyk-ebanking-shared').directive('cbDialog', function(pathService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            title: '@ebTitle',
            message: '@ebMessage',
            icon: '@ebIcon',
            close: '&ebOnClose'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbDialog/cbDialog.html",
        link: function postLink ( scope , element , attrs ) {
            if(!scope.icon){
                scope.icon = "question-icon";
            }
        }
    };
});
