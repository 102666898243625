/**
 * @ngdoc filter
 * @name ebFilterModule.filter:cardDateFilter
 *
 * @description
 * cardDateFilter
 */
angular.module( 'halyk-ebanking-shared').filter('cardDateFilter', function ($filter) {
    return function (number) {
        if(number != null) {
            var date  = new Date(number);
            if(date.isValid()) {
                return $filter('date')(date, 'MM / yyyy');
            }
        }
        return number;
    };
});
