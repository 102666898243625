'use strict';

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbEditableName
 *
 * @description
 * cbEditableName
 */
angular.module('halyk-ebanking-shared').directive('cbEditableNameDeposit', function (pathService, userService, translate, customerProductService, CUSTOM_NAME_REGEX, domService, cbSmartphoneDetectionService, localStore) {


    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            customerProductData: "=",
            onClickSave: "=",
            placeholderText: "@",
            emptyPlaceholderText: "@",
            ebEditableModel: "=",
            productType: "@",
            updatedName: "=",
            maxLength: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbEditableNameDeposit/cbEditableName.html',
        link: function postLink(scope, element, attrs) {

            if (!scope.maxLength) {
                scope.maxLength = 13;
            }

            var relation = {
                "change_product_name": {
                    "href": "/api/deposit/actions/change_deposit_name.json"
                }
            };


            scope.params = {
                showEdit: false,
                isSmartphone: cbSmartphoneDetectionService.isSmartphone()
            };
            scope.$watch('placeholderText', function (newVal) {
                if (angular.isDefined(newVal)) {
                    scope.placeholderText = newVal.substring(0, 50);
                    if (!angular.isDefined(scope.customerProductData) ||
                        scope.customerProductData == null) {
                        scope.customerProductData = {
                            customName: scope.placeholderText
                        };
                    }
                }
            });

            scope.$watch('customerProductData.customName', function (newVal) {
                if (angular.isDefined(newVal) && newVal != null && newVal.customName != null) {
                    scope.customerProductData.customName = newVal.customName.substring(0, 50);
                }
            });

            scope.labelClicked = function () {
                scope.params.showEdit = !scope.params.showEdit;
            }

            var validationRegex = new RegExp(CUSTOM_NAME_REGEX);


            scope.defaultPlaceholder = translate.property("cb.editable_name.placeholder");

            scope.normalizeName = function (name) {
                if (name == null) {
                    return name;
                }
                return name.split('\n').join('');
            };

            scope.updateCustomerProductName = function (name) {
                if (!angular.isDefined(name)) {
                    return;
                }
                if (angular.isDefined(scope.onClickSave)) {
                    scope.onClickSave(name, scope.ebEditableModel);
                    scope.customerProductData.customName = name;
                    scope.placeholderText = name;
                    scope.updatedName = name;
                    return;
                }
                scope.customerProductData = {
                    customName: '',
                    _links: relation,
                    type: '',
                    productId: ''
                };

                scope.ebEditableModel._links = {
                    "self": {
                        "href": "/api/rstyle_internal/actions/change_product_name.json"
                    }
                };

                var modelHref = scope.ebEditableModel;


                scope.customerProductData.customName = name;
                scope.customerProductData.type = "deposit";
                scope.customerProductData.productId = scope.ebEditableModel.agreementNo;

                console.log(modelHref);

                name = scope.normalizeName(name);
                if (name != null && name.length > 0 && name.length <= scope.maxLength) {
                    if (validationRegex.test(name)) {
                        var res = null;
                        customerProductService.updateCustomerProductName(scope.customerProductData, name).then(function (data) {
                            scope.customerProductData.customName = name;
                            scope.placeholderText = name;
                            scope.updatedName = name;
                            return res;
                        });
                    } else {
                        return translate.property('cb.editable_name.validate.invalid_characters');
                    }
                } else if (name == null || name.length == 0) {
                    var res = customerProductService.removeCustomerProductName(scope.customerProductData);
                    scope.customerProductData.customName = "";
                    scope.placeholderText = "";
                    scope.updatedName = "";
                    return res;
                }
            };

            var input = null;
            scope.registerListener = function () {
                input = element.find('textarea');
                if (input[0].value == '') {
                    input[0].value = scope.placeholderText;
                }
                input.on('keypress', function (e) {
                    e = e || event;
                    if (this.value.length >= scope.maxLength && !domService.isSelected()) {
                        e.preventDefault(); //$setViewValue(""); /
                    }
                })
            };

            scope.updateDefault = function () {
                if (!scope.customerProductData) {
                    scope.customerProductData = {};
                }
                if ((scope.customerProductData.customName == null || scope.customerProductData.customName === "")
                    && scope.placeholderText.length <= scope.maxLength) {
                    scope.customerProductData.customName = scope.placeholderText;
                }
            }

        }
    };
});