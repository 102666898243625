/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.controller:cbCalendarModalController
 *
 * @description
 * Controller for date picker modal dialog used by {@link halyk-ebanking-shared.directive:cbCalendarInput cbCalendarInput}.
 */
angular.module( 'halyk-ebanking-shared').controller('cbCalendarModalController', function ($scope, translate) {

    function isWeekdayValid(date) {
        return !$scope.ebDisableWeekend || (date.getDay() !== 0 && date.getDay() !== 6);
    }

    function isPreviousDateValid(date) {
        return !$scope.ebDisablePastDates || $scope.ebMinDate.getTime() - 86400000 < date.getTime();
    }

    $scope.disabled = function (date, mode) {
        if(mode == 'day') {
            return !(isWeekdayValid(date) && isPreviousDateValid(date));
        }
        return false;
    };

    $scope.ebDisableWeekend = $scope.ebDisableWeekend == undefined ? false : $scope.ebDisableWeekend;
    $scope.ebDisablePastDates = $scope.ebDisablePastDates == undefined ? false : $scope.ebDisablePastDates;

    if ($scope.ebDisablePastDates) {
        if(!angular.isDefined($scope.ebMinDate)) {
            $scope.pastDateString = 'cb-date-input-validation-past';
            $scope.ebMinDate = new Date();
        } else {
            $scope.pastDateString = '';
        }
    }

    if($scope.ngModel) {
        $scope.ebInitDate = new Date($scope.ngModel);
    } else if(!$scope.ebInitDate) {
        $scope.ebInitDate = angular.copy($scope.$parent.mdl) || new Date();
        $scope.localDate = $scope.ebInitDate;
    }

    $scope.ebMaxDate = $scope.ebMaxDate == undefined ? null : $scope.ebMaxDate;

    $scope.ebDatePickerOptions = $scope.ebDatePickerOptions == undefined ? {} : $scope.ebDatePickerOptions;
    $scope.ebStartingDay = $scope.ebStartingDay == undefined ? 1 : $scope.ebStartingDay;

    $scope.$watch('localDate',function (newVal, oldVal){
        $scope.accept();
    })
    $scope.accept = function () {
        $scope.$parent.mdl = $scope.localDate;
        // $scope.$dismiss();
    }

});
