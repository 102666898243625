/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSearchInput
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Input field for search bar.
 *
 * @param {boolean=} ebSetFocus Forces focus of the input.
 * @param {string=} ebSearchText Model for the input value.
 * @param {string} ebPlaceholder Placeholder for search phrase.
 * @param {string} ebName Name of the input field.
 * @param {expression} ebOnBlur Action to execute when input field lost focus.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSearchInput', function ($compile, pathService) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            ebSetFocus: "=",
            ebSearchText: "=",
            ebPlaceholder: '@',
            ebName: '@',
            ebOnBlur: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbSearchInput/cbSearchInput.html",
        link: function postLink(scope, element, attrs) {
            element.find('input').attr('name', scope.ebName);
        }
    };
});
