/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDatepickerAdapter
 *
 * @description
 * Helper directive used by {@link halyk-ebanking-shared.directive:cbCalendarInput cbCalendarInput}.
 */

angular.module( 'halyk-ebanking-shared').directive('cbDatepickerAdapter', function($timeout, $locale) {
    return {
        restrict: 'A',
        scope: false,
        link: function postLink(scope, element, attrs) {
            function expandFirstDay(dayElement, monthOffset) {
                var monthName = element.querySelectorAll('thead button[role="heading"]')[0].textContent.split(' ')[0];
                var monthFormat = $locale['DATETIME_FORMATS'].MONTH;
                var shortMonthFormat = $locale['DATETIME_FORMATS'].SHORTMONTH;
                var monthCounter = -1 + (monthOffset || 0);
                Array.prototype.every.call(monthFormat, function(val) {
                    monthCounter++;
                    return val.toLowerCase() !== monthName.toLowerCase();
                });
                var monthNo = monthCounter % 12;
                //dayElement.textContent = shortMonthFormat[monthNo][0].toUpperCase() + shortMonthFormat[monthNo].toLowerCase().slice(1)  + ' ' + 1;
            }

            function expandDays() {
                var thisMonthFirstDay = element.querySelectorAll('tbody tr:nth-child(-n+2) span:not(.text-muted)')[0];
                expandFirstDay(thisMonthFirstDay);
                var nextMonthFirstDay = element.querySelectorAll('tbody tr:nth-child(n+5) span.text-muted')[0];
                expandFirstDay(nextMonthFirstDay, 1);
            }

            function bindCalendarEvent(selector) {
                var button = element.querySelectorAll(selector)[0];
                angular.element(button).bind("click", expandDays);
            }

            function unbindCalendarEvent(selector) {
                var button = element.querySelectorAll(selector)[0];
                angular.element(button).unbind("click", expandDays);
            }

            function bindCalendarEvents() {
                bindCalendarEvent('thead tr:first-child th:first-child button');
                bindCalendarEvent('thead tr:first-child th:last-child button');
            }

            function unbindCalendarEvents() {
                unbindCalendarEvent('thead tr:first-child th:first-child button');
                unbindCalendarEvent('thead tr:first-child th:last-child button');
            }

            scope.$on('$destroy', unbindCalendarEvents);

            $timeout(function() {
                bindCalendarEvents();
                expandDays();
            }); //t todo consider doing it without $timeout

        }

    };
});
