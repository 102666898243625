/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbMultiLineTextField
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Text area component.
 *
 * @param {string=} ngModel The model for entered text.
 * @param {object=} ebForm Parent form model.
 * @param {expression=} ebDisabled Sets the disabled attribute on the element if the expression evaluates to truthy.
 * @param {siring} placeholder A text to be presented inside the field when no user data is entered.
 * @param {string} ebName Name of the input field.
 * @param {number=} ebRows Number lines of the text area.
 * @param {number} ebMaxlength Maximum langth of entered text.
 * @param {expression=} ebRequired If `true` the account number will be required.
 * @param {boolean=} ebFocus Forces focus request when component is displayed.
 * @param {boolean} outFocusEnabled If defined focus may leave the component.
 * @param {expression} ebFocusMove Expression to guard focus out.
 * @param {number} ebFocusMoveKey Key code causing focus out.
 * @param {string} cbTextEmptyValid If this attribute is not empty the text must not be empty.
 */
angular.module( 'halyk-ebanking-shared').directive('cbMultiLineTextField', function($filter, pathService, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
            ngModel: '=?',
            cbVerifyValue: '=?',
            ebForm: '=',
            ebDisabled: "=?",
            placeholder: "@",
            ebName: "@",
            form: '=?formData', // not used
            ebRows: "=?",
            ebMaxlength: "@?",
            ebRequired: "=?",
            ebResizable: "=?",
            asOneLine: "@", // to jest specyficzny hak do wyjaśnienia
            ebFocus: "=?",
            outFocusEnabled: "@",
            ebFocusMove: "&",
            ebFocusMoveKey: '=?',
            cbTextEmptyValid: "@", // czy to jest potrzebne? wyglada, że nie powinno działać
            ebAddKey: "=" // TODO chyba nie jest używana, bo nigdzie nie ma odczytu wartości; własciwie jest wykorzystywane do swego rodzaju inicjalizacji - będzie uruchomione tylko raz
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbMultiLineTextField/cbMultiLineTextField.html',
        link: function postLink(scope, element, attrs) {

            scope.ebRequired = angular.isDefined(scope.ebRequired) ? scope.ebRequired : true;
            scope.outFocusEnabled = angular.isDefined(scope.outFocusEnabled) ? scope.outFocusEnabled : false;
            scope.ebFocusMoveKey = "";
            var ownRedirect = false;

            scope.linesNumber = 4;
            if(scope.ebRows) {
                scope.linesNumber = scope.ebRows;
            } else {
                scope.ebRows = scope.linesNumber
            }
            if(scope.ebResizable) {
                scope.linesNumber = 1;
            }

            if (scope.ebName === undefined) {
                scope.ebName = "textarea";
            }
            if(scope.ebMaxlength == undefined) {
                scope.ebMaxlength = 146;
            }

            var textArea = element.find('textarea');
            var ctrlDown = false;

            scope.watchMe = function(newVal) {
                scope.cbVerifyValue = textArea.val();
            }

            scope.$watch('ngModel', function(newVal, oldVal) {
                if (newVal != null) {
                    if (angular.isArray(scope.ngModel)) {
                        textArea.val(scope.ngModel.join('\n'));
                        var text = textArea.val();
                        var height = text.split('\n').length;
                        if (scope.ebResizable) {
                            scope.linesNumber = height > scope.ebRows ? scope.ebRows : height;
                        }
                    } else {
                       
                    }
                }
            });

            function getSign(keyCode) {
                var sign = null;
                if (keyCode != null && keyCode.substring(0, 1) === '-') {
                    if (!isSignPrintable(keyCode.substring(1))) {
                        return '';
                    }
                    sign = getCorrectSign(keyCode.substring(1));
                    return sign != null ? sign : String.fromCharCode(keyCode.substring(1));
                } else {
                    if (!isSignPrintable(keyCode)) {
                        return '';
                    }
                    sign = getCorrectSign(keyCode);
                    return sign != null ? sign : String.fromCharCode(keyCode).toLowerCase();
                }
            }

            function isSignPrintable(keyCode) {
                var keyCodesArray = [0, 37, 38, 39, 40, 16, 17, 20, 144, 9, 36, 35, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123]; // arrows, ctrl, shift, capslock, numlock, tab, home, end, f keys
                if (keyCode == '' || keyCode == '0' ||
                    keyCode < 0 ||
                    keyCodesArray.indexOf(keyCode) != -1) {
                    return false;
                } else {
                    return true;
                }
            }

            function getCorrectSign(code) {
                switch (code) {
                    case '187' : return '+';
                    case '188' : return ',';
                    case '189' : return '-';
                    case '190' : return '.';
                    case '219' : return '[';
                    case '221' : return ']';
                    case '186' : return ';';
                    case '222' : return '"';
                    case '191' : return '?';
                    case '220' : return '\\';
                    default: return null;
                }
            }

            scope.$watch('ebFocus', function(newVal, oldVal) {
                if (newVal != null && newVal != oldVal && newVal == true) {
                    if (ownRedirect) {
                        textArea[0].selectionStart = 1;
                        textArea[0].selectionEnd = 1;
                    } else {
                        textArea[0].selectionStart = 0;
                        textArea[0].selectionEnd = 0;
                    }
                    ownRedirect = false;
                    textArea[0].focus();
                    scope.ebFocus = false;
                }
            });

            textArea.bind('change blur paste', function(event) {
                $timeout(function() {
                    var text = textArea.val();
                    // var parsedText = $filter('cbTextareaFilter')(text, scope.ebRows);
                    // textArea.val(parsedText);
                    scope.ngModel = text.split('\n');
                })
            });

            function giveUpFocus() {
                if (scope.outFocusEnabled && scope.ebFocusMove != undefined) {
                    textArea[0].blur();
                    scope.ebFocusMove();
                }
            }

            textArea.bind('keydown', function(event) {
                addKeyDown(event.keyCode, event);
            });

            scope.moveOut = false;
            textArea.bind('keyup', function(event) {
                if (event.keyCode == 17) {
                    ctrlDown = false;
                }
                //if (input.val().length > 34 && input[0].selectionStart == input[0].selectionEnd) {
                if (scope.moveOut) {
                    scope.ebFocusMoveKey = ((event != null && event.shiftKey) ? "-" : "") + event.keyCode;
                    giveUpFocus(event.keyCode);
                }
            });

            function addKeyDown(keyCode, event) {
                var keyCodesArray = [37, 38, 39, 40, 16, 17, 20, 144, 9, 36, 35, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123]; // arrows, ctrl, shift, capslock, numlock, tab, home, end, f keys
                scope.moveOut = false;
                //scope.ebFocusMove = false;
                if(keyCode == 17)
                    ctrlDown = true;
                if (scope.asOneLine == true) {
                    keyCodesArray.push(13);
                }
                if (keyCodesArray.indexOf(keyCode) == -1) {
                    if (keyCode == 13) {
                        var text = textArea.val();
                        var parsedText = $filter('cbTextareaFilter')(text, scope.ebRows);
                        var height = text.split('\n').length;
                        if (height > scope.ebRows-1) {
                            scope.ebFocusMoveKey = null;
                            giveUpFocus();
                            if (event != null) {
                                event.preventDefault();
                            }
                        } else {
                            textArea.val(parsedText);
                            height++;
                        }
                    }
                    // else {
                    //     var text = textArea.val();
                    //     var lines = text.split('\n');
                    //     var height = lines.length;
                    //     var selStart = textArea[0].selectionStart;
                    //     var selEnd = textArea[0].selectionEnd;
                    //     var currentLineNo = text.substr(0, selStart).split('\n').length - 1;
                    //     var selEndLineNo = text.substr(0, selEnd).split('\n').length - 1;
                    //     var currentLineStart = 0;
                    //     for (var i = 0; i < currentLineNo; i++){
                    //         currentLineStart += lines[i].length + 1;
                    //     }
                    //     var currentLineEnd = currentLineStart + lines[currentLineNo].length;
                    //
                    //     var breakstop = false;
                    //     if (lines[currentLineNo].length > 34 && selStart == selEnd && keyCode != 8 && keyCode != 46 ) {
                    //         if(height == scope.ebRows) {
                    //             for(var i = scope.ebRows-1; i >= currentLineNo; i--) {
                    //                 if(i == currentLineNo)
                    //                     breakstop = true;
                    //                 if(lines[i].length < 35)
                    //                     break;
                    //             }
                    //             if((lines[scope.ebRows-1].length > 34 && currentLineNo == scope.ebRows-1) || breakstop) {
                    //                 //scope.ebFocusMoveKey = ((event != null && event.shiftKey) ? "-" : "") + keyCode;
                    //                 //giveUpFocus();
                    //                 textArea.val(text.substr(0, text.length));
                    //                 if (event != null) {
                    //                     event.preventDefault();
                    //                 }
                    //             }
                    //         }
                    //
                    //         if(!(selStart == currentLineEnd && currentLineNo == height-1) && (!ctrlDown && keyCode != 86)) {
                    //             var subText = '';
                    //             var loopend = height;
                    //             if(height == scope.ebRows) {
                    //                 for(var i = height-1; i >=0; i--) {
                    //                     if(lines[i].length > 34)
                    //                         loopend = i;
                    //                     else
                    //                         break;
                    //                 }
                    //             }
                    //             var dlugosc = lines[0].length;
                    //             for(var i = currentLineNo; i < loopend; i++)
                    //             {
                    //                 if(lines[i].length > 34 && !(selStart == currentLineEnd && i == currentLineNo))
                    //                     lines[i] = lines[i].substring(0,34) + '\n' + lines[i].substring(34,lines[i].length);
                    //             }
                    //             for(var i = 0; i < height; i++) {
                    //                 if(i < currentLineNo || (i >= loopend-1 && i < height-1) || (lines[i].length < 35 && i < height-1) || (selStart == currentLineEnd && i == currentLineNo))
                    //                     subText += lines[i] + '\n';
                    //                 else
                    //                     subText += lines[i];
                    //             }
                    //             if(text.substring(currentLineEnd,currentLineEnd+1)!='\n')
                    //                 height++;
                    //             else {
                    //                 var subLines = subText.split('\n');
                    //                 height = subLines.length;
                    //             }
                    //
                    //             textArea.val(subText);
                    //             if(selStart == currentLineEnd)
                    //                 textArea[0].setSelectionRange(selStart+1, selEnd+1);
                    //             else
                    //                 textArea[0].setSelectionRange(selStart, selEnd);
                    //         }
                    //         else if(text.substring(currentLineEnd,currentLineEnd+1)=='\n') {
                    //             textArea[0].setSelectionRange(selStart+1, selEnd+1);
                    //
                    //         }
                    //         else if(height < scope.ebRows){
                    //             var subText = text.substr(0, selStart) + '\n' + text.substr(selStart, text.length);
                    //             textArea.val(subText);
                    //             height++;
                    //             if (selStart == 0 || text.substr(selStart - 1, 1) == '\n') {
                    //                 textArea[0].setSelectionRange(selStart, selEnd);
                    //             } else {
                    //                 textArea[0].setSelectionRange(selStart + 1, selEnd + 1);
                    //             }
                    //         }
                    //         else {
                    //             //scope.ebFocusMoveKey = keyCode;
                    //             //giveUpFocus();
                    //             textArea.val(text.substr(0, text.length));
                    //             if (event != null) {
                    //                 event.preventDefault();
                    //             }
                    //         }
                    //
                    //     }
                    //     if((keyCode != 8 && keyCode != 46 && currentLineNo == scope.ebRows-1
                    //         && lines[scope.ebRows-1] != undefined && lines[scope.ebRows-1].length >= 34 && selStart == selEnd)
                    //         || breakstop) {
                    //         scope.moveOut = true;
                    //     }
                    //
                    //     if (keyCode == 8 || keyCode == 46) {
                    //
                    //         //Obsluga usuniecia jedynego znaku z danego wiersza
                    //         if (lines[currentLineNo].length == 1 && selStart == selEnd && ((keyCode == 8 && selStart > currentLineStart) || (keyCode == 46 && selStart == currentLineStart))) {
                    //             if (height>1)
                    //                height--;
                    //             for (var i=0; i < lines.length-1; i++){
                    //                 if (currentLineNo == 0 && i != currentLineNo)                                             //dla usuniecia jedynego znaku w pierwszym wierszu
                    //                     lines[i] += '\n';
                    //                 else if (currentLineNo == lines.length-1 && i != lines.length-2)                          //dla usunięcia jedynego znaku w ostatnim wierszu
                    //                     lines[i] += '\n';
                    //                 else if (currentLineNo != 0 && i != currentLineNo && currentLineNo != lines.length-1)     //dla usuniecia jedynego znaku w srodkowym wierszu
                    //                     lines[i] += '\n';
                    //             }
                    //             var subText = "";
                    //             for (var i=0; i < lines.length-1; i++){
                    //                 subText = subText + lines[i];
                    //             }
                    //             textArea.val(subText);
                    //             if(currentLineNo == lines.length-1 && keyCode == 46) {
                    //                 textArea[0].selectionStart = selStart - 1;
                    //                 textArea[0].selectionEnd = selEnd - 1;
                    //             }
                    //             else {
                    //                 textArea[0].selectionStart = selStart;
                    //                 textArea[0].selectionEnd = selEnd;
                    //             }
                    //         }
                    //         else if (keyCode == 8 && selStart == currentLineStart && selStart == selEnd && lines[currentLineNo].length > 0) {                //usuniecie znaku nowej lini poprzez backspace
                    //             if (((lines[currentLineNo - 1] == undefined) ? 0 : lines[currentLineNo - 1].length) + lines[currentLineNo].length > 35) {
                    //             //if (lines[currentLineNo - 1].length + lines[currentLineNo].length > 35) {
                    //                 if (event != null) {
                    //                     event.preventDefault();
                    //                 }
                    //             } else {
                    //                 height--;
                    //             }
                    //         }
                    //         else if (selStart == currentLineStart && selStart == selEnd && lines[currentLineNo].length == 0) {            //obsluga usuniecia pustej lini poprzez backspace
                    //             height--;
                    //         }
                    //         else if (keyCode == 46 && selStart == currentLineEnd && selStart == selEnd && lines[currentLineNo].length > 0 && currentLineNo != height-1) {                                        //usuniecie znaku nowej lini poprzez delete
                    //             if (lines[currentLineNo + 1].length + lines[currentLineNo].length > 35) {
                    //                 if (event != null) {
                    //                     event.preventDefault();
                    //                 }
                    //             } else {
                    //                 height--;
                    //             }
                    //         }
                    //         else if (selStart != selEnd) {                                                                        //obsluga usuniecia zaznaczonego tekstu
                    //             var newLineSign = false;
                    //             for (var i = selStart; i <= selEnd; i++)
                    //                 if (text.charAt(i)=='\n')
                    //                     newLineSign = true;
                    //
                    //             if (selStart != currentLineStart && newLineSign) {
                    //                 var selLineEnd = 0;
                    //                 for (var i = 0; i <= selEndLineNo; i++) {
                    //                     selLineEnd += lines[i].length;
                    //                 }
                    //                 if ((selStart - currentLineStart) + (selLineEnd - selEnd) > 34) {
                    //                     text = text.slice(0,selEnd) + '\n' + text.slice(selEnd);
                    //                     textArea.val(text);
                    //                     textArea[0].selectionStart = selStart;
                    //                     textArea[0].selectionEnd = selEnd;
                    //                 }
                    //             }
                    //             if (selStart == currentLineStart && newLineSign) {
                    //                 if(text.charAt(selEnd)=='\n' && currentLineNo == 0) {
                    //                     textArea[0].selectionStart = selStart;
                    //                     textArea[0].selectionEnd = selEnd + 1;
                    //                 }
                    //                 else {
                    //                     textArea[0].selectionStart = selStart - 1;
                    //                     textArea[0].selectionEnd = selEnd;
                    //                 }
                    //                 if (text.charAt(selEnd)=='\n' && currentLineNo == 0)
                    //                     text = text.replace(text.substring(selStart,selEnd+1),'');
                    //                 else
                    //                    text = text.replace(text.substring(selStart-1,selEnd),'');
                    //                 lines = text.split('\n');
                    //                 height = lines.length;
                    //             }
                    //             else {
                    //                 text = text.replace(text.substring(selStart,selEnd),'');
                    //                 lines = text.split('\n');
                    //                 height = lines.length;
                    //             }
                    //         }
                    //     }
                    // }
                    //
                    // if(scope.ebResizable) {
                    //     scope.linesNumber = height > scope.ebRows ? scope.ebRows : height;
                    // }
                }
            }
        }
    };
});

angular.module( 'halyk-ebanking-shared').directive('cbMultiLineTextFieldValidator', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {
            ctrl.$validators.textEmpty = function(value) {
                if (!scope.cbTextEmptyValid){
                    return true;
                }

                if (angular.isDefined(value.trim)) {
                    if (value.trim().length==0) {
                        return false;
                    }
                } else {
                    for (var i = 0; i < value.length; i++){
                        if (value[i].trim().length>0) {
                            return true;
                        }
                    }
                    return false;
                }
                return true;
            }
        }
    };
});
