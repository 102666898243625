'use strict';

/**
 * @ngdoc service
 * @name ebankingSharedModule.service:cbUploadButtonService

 * @description
 * cbUploadButtonService.
 */
angular.module('halyk-ebanking-shared').service('cbUploadButtonService', function ($rootScope
    , commonService
    , resourceServiceFactory
    , userService, translate
    , $filter, $http, $q, gate) {

    var rel = {}
        , userName = {};

    var cbUploadButtonService = new resourceServiceFactory.create("file_upload");

    userService.getUserDetails().then(
        function (data) {
            userName = data.firstName.concat(' ', data.lastName);
        }
    );

    function isHalykAccount(account) {
        return isGeorianAccount(account)
            && account.substring(4, 6).toUpperCase() == "HB";
    };

    function isGeorianAccount(account) {
        return account.substring(0, 2).toUpperCase() == "GE";
    };

    return {

        sendFileToEmail: function (file, emailSubject, emailBody, paymentConfirmationId){
            var formData = new FormData();
            formData.append('emailBody', emailBody);
            formData.append('emailSubject', emailSubject);
            formData.append('file', file);
            formData.append('paymentId', paymentConfirmationId);
            return $http({
                url: '/frontend-web/services/colvir-service/api/file_upload/actions/send_file_to_email.json',
                headers: {"Content-Type": undefined},
                data: formData,
                method: "POST"
            }).then(function (httpResult) {
                let result = httpResult && httpResult.data ? httpResult.data : { success : false};
                let preparedResult = {
                    success: result.success,
                    resultCode: result.resultCode,
                    fileName: file.name,
                    fileId: httpResult && httpResult.data && httpResult.data.data ? httpResult.data.data.fileId : undefined
                };
                if(result.success === false) {
                    return $q.reject(preparedResult);
                }
                return preparedResult;//to return response body
            }, function (error) {
                return $q.reject({
                    resultCode: error && error.data ? error.data.resultCode : undefined,
                    fileName: file.name
                });//to return file name
            });
        },
        uploadFileMultipart: function (file, paymentId) {
            var formData = new FormData();
            formData.append('file', file);
            return $http({
                url: '/frontend-web/services/colvir-service/api/file_upload/actions/file_upload_multipart.json?'
                    + 'paymentId=' + ((paymentId) ? paymentId : ''),
                headers: {"Content-Type": undefined},
                data: formData,
                method: "POST"
            }).then(function (result) {
                return {
                    fileId: result && result.data && result.data.data ? result.data.data.fileId : undefined,
                    fileName: file.name
                };//to return response body
            }, function (error) {
                return $q.reject({
                    resultCode: error && error.data ? error.data.resultCode : undefined,
                    fileName: file.name
                });//to return file name
            });
        },

        deleteFile: function (attachmentId) {
            return gate.command("delete_file", {"attachementId": attachmentId}, null);
        },


        uploadFile: function (params, rel, context) {
            return cbUploadButtonService.action(params, "file_upload", context).then(
                function (result) {
                    return result;
                }
            )
        }
        , uploadFiles: function (params, rel, context) {
            return cbUploadButtonService.action(params, "file_upload", context).then(
                function (result) {
                    return result;
                }
            )
        }
        , getArgs: function (files, context) {
            var subject = '', body = '';
            var accountNumber = context.accountNo || context.beneficiaryAccountNumber;
            if (isHalykAccount(accountNumber)) {
                subject = translate.property('payments.transfer.halyk.foreign.email.subject');
            } else {
                subject = translate.property('payments.transfer.another.foreign.email.subject');
            }

            var amount = context.amount ? context.amount : context.sum
                , currency = context.currency
                , personalNumber = context.beneficiaryPersonalCode
                , accountNo = context.accountNo ? context.accountNo : context.beneficiaryAccountNumber;


            /*  
                https://assecocbp.atlassian.net/browse/HC-1117
                а в тексте: валютный перевод, клиент: имя, фамилия клиента, личный номер, 
                номер счета ( с которого осуществился перевод), сумма и валюта. 
            */

            body = [
                subject
                , ';', $filter('translate')('payment.pay_a_bill.list.customer_name'), ': ', userName
                , ',; ', $filter('translate')('payments.transfer.label.personal.number'), ': ', personalNumber
                , ',; ', $filter('translate')('payments.transfer.label.account.number'), ': ', accountNo
                , ',; ', $filter('translate')('payments.pay_a_bill.payment.amount'), ': ', amount
                , ',; ', $filter('translate')('payments.details.currency'), ': ', currency, '.'
            ].join(' ');

            /*
             https://assecocbp.atlassian.net/browse/HC-1117
             в заглавии письма пусть будет имя, фамилия клиента , сумма и валюта,
             */
            subject += [
                ' - ', userName
                , ' ', $filter('translate')('payments.pay_a_bill.payment.amount'), ': ', amount
                , ', ', $filter('translate')('payments.details.currency'), ': ', currency
            ].join(' ');

            return {
                'uploadFiles': files
                , 'emailSubject': subject
                , 'emailBody': body
            };
        }
    };
});