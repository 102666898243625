angular.module( 'halyk-ebanking-shared').value('cbComplementaryIdTypesDictionary', [{
    key: "R",
    model: "REGON"
}, {
    key: "P",
    model: "PESEL"
}, {
    key: "1",
    model: "ID_CARD"
}, {
    key: "2",
    model: "PASSPORT"
}, {
    key: "X",
    model: "NONE"
}]);
