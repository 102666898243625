/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbFullScreenWidget
 * @restrict EA
 * @scope
 *
 * @description
 * A container for widget view content. The container is layed out on top of dashboard and fills all its space. At the top of the container
 * a header is placed. The header presents title of the view and navigation controls (close - triggers navigation to dashboard and optionally
 * back - triggers navigation to previous view if availbale). Beneath the header directive contents are transcluded.
 *
 * @param {string} ebTitle The title of the view; if not provided as attribute a default title will be resolved by the key: miniappName + '.title'.
 * @param {string} ebSubtitle The subtitle presented below the title with font of size equal to 80% of the size of font of the title.
 * @param {boolean=} hideClose If `true` the dashboard navigation button is hidden.
 * @param {string} hideHeader If this attribute is not empty the container header is hidden.
 * @param {string} miniappName Miniapplication name.
 */
angular.module('halyk-ebanking-shared').directive('cbFullScreenWidget', function( pathService, cbStaticBannerService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebTitle: '@',
            ebSubtitle: '@',
            iconClass: '@ebIconClass', // not used
            hideClose: '=',
            showEditMode: '=', // not used
            showViewMode: '=',
            hideHeader: "@",
            miniappName: "@"
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbFullScreenWidget/cbFullScreenWidget.html',
        link: function( scope, element, attrs) {
            scope.isStaticBannerVisible = function() {
                return cbStaticBannerService.isStaticBannerVisible();
            };
            if (scope.isStaticBannerVisible()) {
                scope.staticBannerSrc = cbStaticBannerService.getStaticBannerHref();
            }
        }
    };
});
