/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbScrollTrigger
 *
 * @description
 * Directive allows to controll emitting scrolling events of a container. The scrolling events are used to dynamic data loading.
 */

angular.module( 'halyk-ebanking-shared').directive('cbScrollTrigger', function ($window) {
    return {
        restrict: 'A',
        scope: true,
        controller:  function( $scope) {
            $scope.enableScrollEventTracking = function( $event) {
                $scope.$emit( 'cb.dashboard.enable.scroll.events');
            };

            $scope.disableScrollEventTracking = function( $event) {
                $scope.$emit( 'cb.dashboard.disable.scroll.events');
            };

            $scope.$on( '$destroy', function() {
                $scope.disableScrollEventTracking();
            });
        },
        link: function (scope, element, attrs) {
            scope.enableScrollEventTracking();
        }
    };
});
