/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAccountsCurrencySelect
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down for currency selection. Available currencies are loaded from `accoutsService`.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
    <div>
        <cb-accounts-currency-select ng-model="ngModel.currency" placeholder="Select"></cb-accounts-currency-select>
    </div>
 * ```
*/

angular.module( 'halyk-ebanking-shared').directive('cbAccountsCurrencySelect', function(
                                                                                    $compile
                                                                                , $rootScope
                                                                                , $timeout
                                                                                , $log
                                                                                , $http
                                                                                , $templateCache
                                                                                , pathService
                                                                                , accountsService
                                                                                ) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            placeholder: "@",
            currenciesList: "="
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAccountsCurrencySelect/cbAccountsCurrencySelect.html',

        link: function postLink(scope, element, attrs, ngModel) {

            scope.params = {
                fields: 'currency',
                pageSize: 10000
            };

            //scope.currenciesList = []

            /*scope.currenciesPromise = accountsService.search(scope.params).then(function(accountList) {
                angular.forEach(accountList.content, function(value) {
                    if(scope.currenciesList.indexOf ( value.currency ) == -1) {
                        scope.currenciesList.push ( value.currency );
                        //scope.selectCurrency ( value.currency, ngModel );
                    }
                })

            });*/


            scope.selectCurrency = function(item, model) {

            };
            
        }
    };
});
