
angular.module( 'halyk-ebanking-shared').directive('cbToolbar', function(pathService, $window, $document, ebTheming, $rootScope) {

    return {
        restrict: 'EA',
        transclude: true,
        replace: true,
        scope: {
            searchText: '=',
            searchHide: '=?',
            addUrl: '@', // chyba nie używane
            addTitle: '@', // chyba nie używane
            addIconName: '@', // chyba nie używane
            ebDisableAdd: "=?", // chyba nie używane
            ebDisableSearch: "=?",
            ebSearchPlaceholder: '@?'
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbToolBar/cbToolbar.html',
        link: function preLink(scope, element, attrs) {
            ebTheming.applyTheme(element);

            scope.searchShown = true;

            if(!angular.isDefined(scope.ebSearchPlaceholder)) {
                scope.ebSearchPlaceholder = 'Wpisz szukany tekst';
            }

            $document.on('click', function(evt) {
                if(evt.target.name !== 'toolbarSearchInput' && scope.searchShown) {
                    scope.$apply(scope.hideSearch);
                }
            });

            /*scope.hideSearch = function() {
                if(scope.searchText === undefined || scope.searchText.length == 0) {
                    scope.toggleSearchBox();
                }
            };*/

            scope.toggleSearchBox = function() {
                scope.searchShown = !scope.searchShown;
            };

            scope.showSearchBox = function(evt) {
                /*scope.toggleSearchBox();*/
                evt.stopPropagation();
            }
            $rootScope.$on('$stateChangeStart', function(){
                scope.searchText = "";
                /*if(scope.searchShown)
                    scope.toggleSearchBox();*/
            });
            $rootScope.$on('pageChangeSuccess', function(){
                scope.searchText = "";
                /*if(scope.searchShown)
                    scope.toggleSearchBox();*/
            });
        }
    }
});
