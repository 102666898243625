/* globals angular */

angular.module('halyk-ebanking-shared').service("downloadService", function (
    $http, cbNotificationService
) {

    function downloadFile(blob, fileName) {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    function canBeOpenedInBrowser(contentType) {
        return "text/html" === contentType
            || 'application/pdf' === contentType
            || ('' + contentType).startsWith('image');
    }

    return {
        downloadFile: function (url) {
            return this.openFile(url, true);
        },
        openFile: function (url, download) {
            $http({
                url: url,
                method: "GET",
                responseType: "blob"
            }).then(function (response) {
                /**
                 * it will open the downloaded content in a new browser window.
                 */
                if (!download && canBeOpenedInBrowser(response.data.type)) {
                    window.open(URL.createObjectURL(response.data));
                } else {
                    let fileName = response.headers('Content-Disposition')
                        .split("filename=")[1]
                        .replace(/"/g, '');
                    downloadFile(response.data, decodeURIComponent(fileName));
                }
            }, function (errorResponse){
                cbNotificationService.showWarningDialog({
                    title: "common.title.error",
                    message: "file.can.not.be.downloaded",
                    confirm: "common.button.close"
                });
            });
        }
    }
});
