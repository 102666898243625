'use strict';
/**
 * @ngdoc directive
 * @name ebComponentModule.directive:ebSharedPreloader
 *
 * @description
 * ebPreloader
 */

ebComponentModule.directive('ebSharedPreloader', function(pathService) {
    return {
        restrict: 'EA',
        replace: true,
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/ebSharedPreloader/ebSharedPreloader.html',
    }
});
