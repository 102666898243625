/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTextSpoiler
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module( 'halyk-ebanking-shared').directive( 'cbTextSpoiler' , function ( pathService, $sce, viewStateService ) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            message: '@ebMessage',
            close: '&ebOnClose',
            callback: '&',

        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbTextSpoiler/cbTextSpoiler.html"
		,link: function postLink ( scope , element , attrs ) {
            scope.sce = $sce;
            element[0].parentNode.classList.add("eb-text-spoiler-dialog-content");
            element[0].parentNode.parentNode.classList.add("eb-text-spoiler-dialog-content");
			scope.accept = function () {
                scope.callback();
			}
		} 
    };
});
