/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbResponsiveRow
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Container for row of form.
 *
 * @param {string} label Label to be displayed on the button.
 * @param {string} labelbig Big label to be displayed on the button.
 * @param {string} oneline If this attribute is not empty all elements of the row will be presented in one line.
 * @param {string} cbName Name of form element assosiated with the row - used for visualization of validity state of the row.
 * @param {object=} cbForm Parent form model.
 * @param {string} cbHidevalid If this attribute is not empty icons indicating valid state will not be presented.
 */
angular.module( 'halyk-ebanking-shared').directive('cbResponsiveRowDeposit', function(pathService,translate) {

    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            label:'@',
            labelbig: '@', // TODO do przerobienia
            oneline: '@',
            cbName: '@',
            addColon: '@',
            cbForm: '=',
            cbHidevalid: '@'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbResponsiveRowDeposit/cbResponsiveRowDeposit.html",
        link: function postLink(scope, element, attrs) {
            scope.center = angular.isDefined(attrs.center);
            scope.top = angular.isDefined(attrs.top);
            scope.labelValue = scope.addColon ? translate.property( scope.label )+":" : translate.property( scope.label );
            scope.labelFor = attrs.labelFor != null ? attrs.labelFor : "row-value";
        }
    };
});
