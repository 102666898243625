/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInputNumber
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * Input field for integer number. It allows user to enter only valid values of number. 
 * The field has no mask. Entered number is validated by matching against regular expression pattern: `[0-9]{0,12}?`.
 *
 * The pattern may be overriden if not appropriate.
 *
 * @param {object=} ebForm Parent form model.
 * @param {number=} ebMaxAmount Upper bound of allowed number values.
 * @param {number=} ebMinAmount Lower bound of allowed number values.
 * @param {expression=} ebDisabled Sets the disabled attribute on the element if the expression evaluates to truthy.
 * @param {object=} ngModel Model for the amount.
 * @param {object=} ngCurrencyModel Model form currency selection.
 * @param {expression=} ebRequired If `true` the number value will be required.
 * @param {string} ebName Name of the input field.
 * @param {siring} ebPlaceholder A text to be presented inside the field when no user data is entered.
 * @param {string} minAmountMsg A message presented when entered number is to small.
 * @param {string} maxAmountMsg A message presented when entered number is to big.
 * @param {string} missingAmountMsg A message presented when no number is entered.
 * @param {string} faultAmountMsg A message presented when entered number is fault.
 * @param {string} overrideNumberPattern A pattern to check entered number syntax - it overrides default pattern.
 */

angular.module('halyk-ebanking-shared').directive('cbInputNumber', function (pathService, $filter, $timeout, currenciesService, translate) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebForm: "=",
            maxAmount: "=?ebMaxAmount",
            minAmount: "=?ebMinAmount",
            ebDisabled: "=",
            ngModel: "=",
            ebRequired: '=',
            ebName: "@",
            ebInputStyle: "@",
            ebPlaceholder: "@",
            minAmountMsg: '@ebMinAmountMsg',
            maxAmountMsg: '@ebMaxAmountMsg',
            missingAmountMsg: '@ebMissingAmountMsg',
            faultAmountMsg: '@ebFaultAmountMsg',
            overrideNumberPattern: "@",
            maxLenght: "=?ebMaxLenght"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInputNumber/cbInputNumber.html',
        link: function postLink(scope, element, attrs) {

            scope.mdl = {};

            scope.cleanValidationErrors = function(form, fieldName) {
                if (!form[fieldName].$valid) {
                    for ( var key in form[fieldName].$error ) {
                        console.log('Clear validation error: ' + key);
                        form[fieldName].$setValidity(key, true);
                    }
                }
            }

            if(!angular.isDefined(attrs.ebMaxLenght)){
                scope.maxLenght=9;
            }

            if(!angular.isDefined(attrs.ebMinAmount)) {
                scope.minAmount = 0;
            }

            if(!angular.isDefined(attrs.ebMaxAmount)) {
                scope.maxAmount = 999999999999;
            }

            if (scope.ebPlaceholder == undefined || scope.ebPlaceholder.length <= 0) {
                scope.ebPlaceholder = "0";
            }
            // Ponieważ w stylach dla placeholdera jest ustawione direction: rtl; co jest jedynym sposobem,
            // żeby na starszych wersjach chrome i safari, w szczególności na tabletach, teskt wyświetlał
            // się z prawej strony muszę obrócić napis, żeby się poprawnie wyświetlał. Jak ktoś ma lepszy
            // pomysł to niech poprawi. I tak text-align nie działa.
            scope.ebPlaceholderR = scope.ebPlaceholder.split(' ').reverse().join(' ');

            /*if (!angular.isDefined(scope.maxDigitsMsg)) {
                scope.faultAmountMsg = translate.property("platform.component.amount.max_digits");
            }*/

            var viewInput = angular.element(element.querySelectorAll('.view-input')[0]);
            var editInput = angular.element(element.querySelectorAll('.edit-input')[0]);
        }

    };
});