/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.factory:cbTabService
 *
 * @description
 * Service for managing tabs data. It allows to gather tabs data, sort it and track changes of their order.
 *
 * @requires $state
 */

angular.module( 'halyk-ebanking-shared').factory('cbTabService', ['$state', function($state) {

    /**
     * ngdoc object
     * @name halyk-ebanking-shared.type:TabBuilder
     *
     * @desciption
     * Tab collection object - gathers all tab data and provides methods to access and manipulate it.
     */
    var TabBuilder = function() {
        this._currentTab = null;
        this._idx = 0;
        this._tabs = {};
        this._list = [];
    };

    /**
     * ngdoc function
     * @name halyk-ebanking-shared.type:TabBuilder#addTab
     *
     * @desciption
     * Adds a tab data.
     *
     * @param {string} name Name of the tab.
     * @param {string} state State associated with the tab.
     * @param {object} criteria Properties of the tab.
     */
    TabBuilder.prototype.addTab = function(name, state, criteria) {
        this._tabs[name] = angular.extend({}, {
            name: name,
            idx: this._idx++,
            state: state,
            criteria: criteria
        });

        return this;
    };


    /**
     * ngdoc function
     * @name halyk-ebanking-shared.type:TabBuilder#build
     *
     * @desciption
     * This method sorts tab data and returns object containing all tab data and methods for accessing and manipulating it.
     *
     * @returns {object} An object containing all tab data and methods for accessing and manipulating it.
     */
    TabBuilder.prototype.build = function() {
        var _builder = this;

        for (var prop in _builder._tabs) {
            if (_builder._tabs.hasOwnProperty(prop)) {
                _builder._list.push(_builder._tabs[prop]);
            }
        }
        _builder._list.sort(function(a, b) {
            return (a.idx < b.idx ? -1 : 1);
        });


        return {
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#getTab
             *
             * @desciption
             * This method returns data of a tab having the given value, index or state.
             *
             * @param {object, number or string} value Value, index or state.
             * @returns {object}  An object containing the tab data.
             */
            getTab: function(value) {
                for (var prop in _builder._tabs) {
                    if (angular.isObject(_builder._tabs[prop]) && (_builder._tabs[prop] === value || _builder._tabs[prop].idx == value || _builder._tabs[prop].state == value)) {
                        return _builder._tabs[prop];
                    }
                }
            },
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#getTabs
             *
             * @desciption
             * This method returns data of all tabs.
             *
             * @returns {object}  An object containing all tabs data.
             */
            getTabs: function() {
                return _builder._tabs;
            },
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#getTabList
             *
             * @desciption
             * This method returns data of all tabs as array.
             *
             * @returns {object}  An array containing all tabs data.
             */
            getTabList: function() {
                return _builder._list;
            },
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#getCurrentTab
             *
             * @desciption
             * This method returns data of currenty selected tab.
             *
             * @returns {object}  An object containing currenty selected tab data.
             */
            getCurrentTab: function() {
                return _builder._currentTab;
            },
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#setCurrentTab
             *
             * @desciption
             * This method sets data of currenty selected tab.
             *
             * @param {object} Data of new current tab.
             */
            setCurrentTab: function(value) {
                _builder._currentTab = value;
            },
            /**
             * ngdoc function
             * @name halyk-ebanking-shared.type:TabBuilderResult#changeTab
             *
             * @desciption
             * This method returns data of a tab having the given value, index or state.
             *
             * @param {string} tab `'next'` or `'prev'`.
             * @param {function} onChange Callback - it will be called with the `tab` argument and previously current tab data.
             */
            changeTab: function(tab, onChange) {
                switch(tab) {
                    case 'next':
                        this.changeTab(this.getTab(this.getCurrentTab().idx + 1), onChange);
                        break;

                    case 'prev':
                        this.changeTab(this.getTab(this.getCurrentTab().idx - 1), onChange);
                        break;

                    default:
                        if (tab != null && tab.state != null) {
                            var prevTab = this.getCurrentTab();
                            this.setCurrentTab(tab);

                            (onChange || angular.noop)(tab, prevTab);
                            $state.go(tab.state);
                        }
                        break;
                }
            }
        };
    };

    return {
        getTabBuilder: function() {
            return new TabBuilder();
        }
    };
}]);
