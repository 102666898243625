/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAccountNoPattern
 * @restrict: 'A'
 *
 * @description
 * Pattern for account number fields.
 */

angular.module( 'halyk-ebanking-shared').directive( 'cbAccountNoPattern', function() {

    return {
        restrict: 'A',
        require: '?ngModel',
        link: function( scope, iElement, iAttrs, ngModelCtrl) {

            var lastProperValue = undefined;
            var lastParsedValue = undefined;
            var pattern =  iAttrs.cbAccountNoPattern;
            var overridePattern = iAttrs.cbOverrideNumberPattern;
            var regExp = undefined;

            function getModelCtrl() {
                if (scope.ebForm && scope.ebName) {
                    return scope.ebForm[scope.ebName];
                }
                else {
                    return ngModelCtrl;
                }
            }

            function getPattern() {
                if (overridePattern) {
                    return overridePattern;
                }
                return pattern;
            }

            function initRegExp() {
                var regexp, pat = getPattern();
                pattern = pat;

                if (angular.isString(pat) && pat.length > 0) {
                  regexp = new RegExp( '^' + pat + '$');
                }

                if (regexp && !regexp.test) {
                  throw minErr('ebNumberPattern')('noregexp',
                    'Expected {0} to be a RegExp but was {1}. Element: {2}', pattern,
                    regexp, startingTag(elm));
                }

                regExp = regexp;
            }

            function patternChanged( val) {
                pattern = val;
                initRegExp();
            }

            function overridePatternChanged( val) {
                overridePattern = val;
                initRegExp();
            }

            iAttrs.$observe( 'cbAccountNoPattern', initRegExp);
            iAttrs.$observe( 'cbOverrideNumberPattern', initRegExp);

            initRegExp();

            function storeVal( val) {
                lastParsedValue = val;
                if (val !== "") {
                    lastProperValue = val.replace(/\s+/g, '');
                    if (isNaN(lastProperValue)) {
                        lastProperValue = val;
                    }
                }
                else {
                    lastProperValue = null;
                }
            }

            function checkValue( viewValue) {
                var newVal = viewValue.toString().replace(/\s+/g, '');
                for (var i = 2; i <= 27; i += 5) {
                        if(newVal.length > i && newVal.charAt(i) != " ")
                            newVal = newVal.slice(0,i) + " " + newVal.slice(i);
                    }
                if (regExp.test(newVal)) {
                    storeVal(newVal);
                    return lastProperValue;
                }
                else {
                    var modelVal = lastProperValue;
                    var ctrl = getModelCtrl();
                    ctrl.$setViewValue( lastParsedValue);
                    ctrl.$render();
                    return modelVal;
                }
            }

            function twoDecimalDigits( value) {
                return Math.round( value * 100) / 100;
            }

            function formatValue( value) {
                var val;
                if (value !== undefined && value !== null) {
                    storeVal( value);
                    return value;
                } else if(value == null) {
                    val = ""
                    storeVal( val);
                    return val;
                }
                return value;
            }

            var ctrl = getModelCtrl();
            ctrl.$parsers.unshift( checkValue);
            ctrl.$formatters.push( formatValue);

       }
    };
});
