/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbStaticBannerService
 *
 * @description
 * This service provides methods `isStaticBannerVisible` to check if static banner should be presented and `getStaticBannerHref`
 * which returns href to the banner content.
 */

angular.module( 'halyk-ebanking-shared').factory( 'cbStaticBannerService', function( $window, pathService, httpService, viewConfig) {

    return {
        isStaticBannerVisible: function() {
            return $window.innerWidth >= viewConfig.STATIC_BANNER_BREAKPOINT || 
            		$window.innerHeight >= viewConfig.STATIC_BANNER_BREAKPOINT;
        },
        getStaticBannerHref: function() {
            return httpService.appContext + pathService.generateRootPath( 'halyk-config') + viewConfig.STATIC_BANNER_HREF;
        }
    };

});
