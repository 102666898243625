/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSimpleSelect
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Simple select box.
 *
 * @param {object=} ngModel Target model for the selected value.
 * @param {choiceList=} Collection of select items.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSimpleSelect', function(pathService) {

	return{
		restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
        	ngModel: "=",
        	choiceList: "=?"
        },
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSimpleSelect/cbSimpleSelect.html',
		link: function(scope, element, attrs){

			scope.selectChoice = function(item,model){
				scope.ngModel = item;
			}

			if(scope.ngModel == null){
				scope.ngModel = scope.choiceList[0];
			}

		}

	}

});
