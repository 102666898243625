'use strict';

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbEditableName
 *
 * @description
 * cbEditableName
 */
angular.module('halyk-ebanking-shared').directive('cbEditableName', function(pathService, userService, translate, customerProductService, CUSTOM_NAME_REGEX, domService, cbSmartphoneDetectionService, localStore) {



    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            customerProductData: "=",
            onClickSave: "=",
            placeholderText: "@",
            emptyPlaceholderText: "@",
            ebEditableModel: "=",
            productType:"@",
            updatedName: "=",
            maxLength: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbEditableName/cbEditableName.html',
        link: function postLink(scope, element, attrs) {

            if(!scope.maxLength){
                scope.maxLength = 20;
            }
            var relation = {"change_product_name" :{
                "href" : "/api/rstyle_internal/actions/change_product_name.json"
            }};
            if (angular.isDefined( scope.productType ) && scope.productType == 'BULK_PAYMENT'){
                relation = {"update_bulk_name" :{
                    "href" : "/api/rstyle_internal/actions/update_bulk_name.json"
                }}
            }

            scope.params = {
                showEdit: false,
                isSmartphone: cbSmartphoneDetectionService.isSmartphone()
            };
            scope.$watch('placeholderText', function(newVal) {
                if (angular.isDefined(newVal)) {
                    scope.placeholderText = newVal.substring(0,50);
                    if ( !angular.isDefined( scope.customerProductData )  ||
                        scope.customerProductData == null){
                        scope.customerProductData = {
                            customName: scope.placeholderText
                        };
                    }
                }
            });

            scope.$watch('customerProductData.customName', function(newVal) {
                if (angular.isDefined(newVal) && newVal!= null && newVal.customName != null) {
                    scope.customerProductData.customName = newVal.customName.substring(0,50);
                }
            });

            scope.labelClicked = function() {
                scope.params.showEdit = !scope.params.showEdit;
            }

            var validationRegex = new RegExp(CUSTOM_NAME_REGEX);

            if (angular.isDefined(scope.placeholderText)) {
                scope.placeholderText = scope.placeholderText.substring(0,50);     
            }
            if (scope.customerProductData != null &&
                scope.customerProductData.customName != null) {
                scope.customerProductData.customName = scope.customerProductData.customName.substring(0,50);     
            }

            if (scope.customerProductData != undefined 
                && scope.customerProductData.customName == null 
                && scope.placeholderText != null 
                && scope.placeholderText != ""
                && scope.placeholderText.length <= scope.maxLength) {
                scope.customerProductData.customName = scope.placeholderText;
            }
            scope.defaultPlaceholder = translate.property("cb.editable_name.placeholder");

            scope.normalizeName = function(name) {
                if (name == null) {
                    return name;
                }
                return name.split('\n').join('');
            };

            scope.updateCustomerProductName = function(name) {
                    if ( !angular.isDefined(name) ){
                        return;
                    }
                if ( angular.isDefined(scope.onClickSave) ){
                    scope.onClickSave(name, scope.ebEditableModel);
                    scope.customerProductData.customName = name;
                    scope.placeholderText = name;
                    scope.updatedName = name;
                    return;
                }
                scope.customerProductData = {
                        customName: '',
                            _links: relation,
                             type : '',
                         productId: ''
                    };
                    var modelHref = null;
                    console.log("editmode");
                    console.log(scope.ebEditableModel);
                    if ( angular.isDefined(scope.ebEditableModel.bulkType) ){
                        modelHref = 'bulk';
                    } else if ( angular.isDefined(scope.ebEditableModel.isTranche == true) ){
                        modelHref = 'tranche';
                    } else if ( angular.isDefined(scope.ebEditableModel._links.self ) ){
                       modelHref = scope.ebEditableModel._links.self.href;
                    }

                if ( modelHref.indexOf( "credit" ) > -1 ){
                    scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "credit";
                        scope.customerProductData.productId = scope.ebEditableModel.creditId;
                    } else if ( modelHref.indexOf( "tranche" ) > -1 ){
                    scope.customerProductData.customName  = name;
                    scope.customerProductData.type = "tranche";
                    scope.customerProductData.productId = scope.ebEditableModel.contractNumber;
                    } else if ( modelHref.indexOf( "account" ) > -1 ){
                    scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "account";
                        scope.customerProductData.productId = scope.ebEditableModel.accountId;
                    } else if ( modelHref.indexOf( "deposit" ) > -1 ){
                        scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "deposit";
                        scope.customerProductData.productId = scope.ebEditableModel.depositId;
                    } else if ( modelHref.indexOf( "cards" ) > -1 ){
                       scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "card";
                        scope.customerProductData.productId = scope.ebEditableModel.cardId;
                    } else if ( modelHref.indexOf( "cardProduct" ) > -1 ){
                        scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "card";
                        scope.customerProductData.productId = scope.ebEditableModel.contractNumber;
                    } else if ( modelHref.indexOf( "bulk" ) > -1 ){
                        scope.customerProductData.customName  = name;
                        scope.customerProductData.type = "bulk";
                        scope.customerProductData.productId = scope.ebEditableModel.bulkId;
                        scope.customerProductData.customerId = localStore.get('customer_id');
                        scope.customerProductData.bulkStatus = scope.ebEditableModel.bulkStatus;
                        scope.customerProductData.bulkType = scope.ebEditableModel.bulkType;
                    }
                name = scope.normalizeName(name);
                if (name != null && name.length > 0 && name.length <= scope.maxLength) {
                    if (validationRegex.test(name)) {
                        var res =null;
                        if ( modelHref.indexOf( "bulk" ) > -1 ){
                            customerProductService.updateBulk(scope.customerProductData).then( function (data) {
                                scope.customerProductData.customName = name;
                                scope.placeholderText = name;
                                scope.updatedName = name;
                                return res;
                            });
                        } else {
                            customerProductService.updateCustomerProductName(scope.customerProductData, name).then( function (data) {
                                scope.customerProductData.customName = name;
                                scope.placeholderText = name;
                                scope.updatedName = name;
                                return res;
                            });
                        }
                    } else {
                        return translate.property('cb.editable_name.validate.invalid_characters');
                    }
                } else if (name == null || name.length == 0) {
                    var res = customerProductService.removeCustomerProductName(scope.customerProductData);
                    scope.customerProductData.customName = "";
                    scope.placeholderText = "";
                    scope.updatedName = "";
                    return res;
                }
            };

            var input = null;
            scope.registerListener = function() {
                input = element.find('textarea');
                if ( input[0].value == '' ){
                    input[0].value = scope.placeholderText;
                }
                input.on('keypress', function(e) {
                    e = e || event;
                    if (this.value.length >= scope.maxLength && !domService.isSelected()) {
                        e.preventDefault(); //$setViewValue(""); /
                    }
                }) //custom maxlength for safari
            };

            scope.updateDefault = function() {
                if(!scope.customerProductData) {
                    scope.customerProductData = {};
                }
                if ((scope.customerProductData.customName == null || scope.customerProductData.customName === "")
                    && scope.placeholderText.length <= scope.maxLength) {
                    scope.customerProductData.customName = scope.placeholderText;
                }
            }

        }
    };
});
