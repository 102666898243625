/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbShowPensionNotifications
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module('halyk-ebanking-shared').directive('cbShowPensionNotifications', function (pathService, $http, localStore) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            notificationsList: '=ebNotificationsList',
            callback: '&',
            close: '&ebOnClose'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbShowPensionNotifications/cbShowPensionNotifications.html"
        , link: function postLink(scope, element, attrs) {
            scope.markAsRead = function (notificationId) {
                var req = {
                    method: 'POST',
                    url: '/frontend-web/api/dictionaries/actions/mark_pension_fund_ntf_read',
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        notificationId,
                        markAllRead: false
                    }
                }

                return $http(req).then(response => {
                    if (angular.isDefined(response.data.success)) {
                        countNotRead();
                    }
                });
            }

            function countNotRead() {
                let amount = 0;
                scope.notificationsList.forEach(notification => {
                    if (!notification.isRead) {
                        amount++;
                    }
                })
                scope.notReadCount = amount;
            }

            function setAllRead() {
                scope.notificationsList.forEach(notification => {
                    notification.isRead = true;
                })
            }

            scope.markAllAsRead = function () {
                var req = {
                    method: 'POST',
                    url: '/frontend-web/api/dictionaries/actions/mark_pension_fund_ntf_read',
                    headers: {'Content-Type': 'application/json'},
                    data: {
                        markAllRead: true,
                        customerId: localStore.get('customer_id'),
                    }
                }

                return $http(req).then(response => {
                    if (angular.isDefined(response.data.success)) {
                        setAllRead();
                        scope.callback({notReadCount: 0})
                    }
                })
            }
        }
    };
});
