/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbChoiceDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module( 'halyk-ebanking-shared').directive('cbRadioDialog', function (pathService, viewStateService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            title: '@ebTitle',
            message: '@ebMessage',
            radioButtons: '=radioButtons',
            icon: '@ebIcon',
            close: '&ebOnClose',
            choices: '=ebChoices'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbRadioDialog/cbRadioDialog.html"
		,link: function postLink ( scope , element , attrs ) {
			scope.model = {}
			scope.$watch("model.selectedButton", 
				function () {
					viewStateService.addFormData('cb.radio.dialog', 'radioValue', scope.model.selectedButton);
				}
			);
		}
    };
});
