/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInsurancePaymentTypeSelect
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * Select box for choosing insurance payment type.
 *
 * @param {string=} ngModel Model for account value.
 * @param {string} insurancePaymentTypesKey Prefix of i18n label keys for insurance accounts.
 * @param {siring} placeholder A text to be presented inside the field when no user data is entered.
 */

angular.module( 'halyk-ebanking-shared').directive('cbInsurancePaymentTypeSelect', function(pathService, cbInsurancePaymentTypesDictionary) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ngModel: "=",
            insurancePaymentTypesKey: "@",
            placeholder: "@",
            paymentType: "@" // to chyba nie jest potrzebne
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInsurancePaymentTypeSelect/cbInsurancePaymentTypeSelect.html',

        link: function postLink(scope, element, attrs) {

            scope.paymentTypes = cbInsurancePaymentTypesDictionary;

            scope.selectPaymentType = function(item, model) {
                scope.ngModel = model;
            };

        }
    }
});
