/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDropDownList
 * @restrict EA
 * @scope
 *
 * @description
 * cbDropDownList
 */

angular.module( 'halyk-ebanking-shared').directive('cbDropDownList', function(pathService){

	return {
		restrict: 'EA',
		transclude: true,
		replace: true,
		scope: {
			textcolor: '@',
			bordercolor: '@',
			label: '='
		},
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDropDownList/cbDropDownList.html',
		link: function(scope, element, attrs) {


       		scope.internalLabel = scope.label || {};
       		scope.internalLabel.setLabel = function(newLabel) {
       			scope.internalLabel.selectedLabel = newLabel;
       		}
        }
	}
});
