/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDropDownButton
 * @restrict EA
 * @scope
 *
 * @description
 * Button looking like drop down.
 *
 * @param {string} label Label to be displayed on the button.
 * @param {string} labelbig Big label to be displayed on the button.
 * @param {string} buttontall Flag idicating the button should be tall.
 * @example
 * ```js
    <div>
        <cb-drop-down-button labelbig="ABC" buttontall ></cb-drop-down-button>
    </div>
 * ```
*/

angular.module( 'halyk-ebanking-shared').directive('cbDropDownButton', function( pathService) {

	return {
		restrict: 'EA',
		transclude: true,
		scope: {
			label: '@',
			labelbig: '@', // TODO do przerobienia
			buttontall: '@'
		},
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDropDownButton/cbDropDownButton.html'
	};
});
