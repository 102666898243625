/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDepositsCurrencySelect
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down for currency selection. Available currencies are loaded from `depositsService`.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
    <div>
        <cb-deposits-currency-select ng-model="ngModel.currency" placeholder="Select"></cb-deposits-currency-select>
    </div>
 * ```
*/

angular.module( 'halyk-ebanking-shared' ).directive ( 'cbDepositsCurrencySelect' , function ( $compile
                                                                                            , $rootScope
                                                                                            , $timeout
                                                                                            , $log
                                                                                            , $http
                                                                                            , $templateCache
                                                                                            , pathService
                                                                                            , depositsService ) {

    return {
      restrict: 'EA',
      replace: true,
      scope: {
          placeholder: "@",
      },
      templateUrl: pathService.generateTemplatePath ( 'halyk-ebanking-shared' ) 
        .concat ( '/directives/cbDepositsCurrencySelect/cbDepositsCurrencySelect.html' ),

      link: function postLink ( scope, element, attrs, ngModel ) {

        scope.params = {
          fields: 'currency'
          , pageSize: 10000
        };

        scope.currenciesList = [];

        scope.currenciesPromise = depositsService.search ( scope.params ).then (
          function ( depositList ) {
            depositList.content.forEach (
              function ( item , index, array ) {
                if ( scope.currenciesList.indexOf ( item.currency ) == -1) {
                  scope.currenciesList.push ( item.currency );
                  // select all currencies
                  //scope.$$childTail.$select.select ( value.currency );
                }
              } 
            );
          }
        );


        scope.selectCurrency = function ( item, model ) {

        };
          
      }
    };
});
