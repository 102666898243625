/*

 If the message originates from the server use the following expression <command_name>.<field_name>.<error_name> e.g.
 transfer_order.amount.NotNull

 <div eb-message-key="transfer_order.amount.NotNull" class="validation-message animate-on"><span>Amount must be set.</span></div>

 */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbValidationMessages
 *
 * @description
 * cbValidationMessages
 */

angular.module( 'halyk-ebanking-shared').directive('cbValidationMessages', function (translate, pathService, $compile, serverValidator) {
    return {
        restrict: 'EA',
        require: '^form',
        transclude: true,
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbValidationMessages/cbValidationMessages.html',
        scope: {
            name: '@ebName',
            ebErrorCode: '@',
            cbHidevalid: '@',
            cbStopValid: '=',
            properties: '=?'
        },
        controller: function ($scope) {
            $scope.handleMessage = function (key) {
                var model = $scope.form[$scope.name];
                model.$setValidity(key, false);
                model.$setDirty();
                var unregister = $scope.$watch(function() {
                    return model.$modelValue;
                }, function(newValue, oldValue) {
                    if(newValue !== oldValue) {
                        model.$setValidity(key, true);
                        model.$setPristine();
                        unregister();
                    }
                });
            }
        },
        link: {
            pre: function ($scope, $element) {
                var $transcludeFn = arguments[4];
                var handlersToUnregister = [];
                $transcludeFn($scope, function (clonedTranscludedContent) {
                    var placeholder = $element.querySelectorAll('[ng-messages]')[0];
                    angular.forEach(clonedTranscludedContent, function (transcludedElement) {
                        transcludedElement = angular.element(transcludedElement);
                        var ebMessageKey = transcludedElement.attr('eb-message-key');
                        if (ebMessageKey) {
                            transcludedElement.attr('ng-message', ebMessageKey);
                            var unregisterMessageHandler = serverValidator.registerMessageHandler({
                                key: ebMessageKey,
                                handle: $scope.handleMessage
                            });
                            handlersToUnregister.push(unregisterMessageHandler);
                        }
                    });
                    angular.element(placeholder).append(clonedTranscludedContent);
                });
                $compile($element.contents())($scope);
                $scope.$on('$destroy', function () {
                    angular.forEach(handlersToUnregister, function (unregisterFn) {
                        unregisterFn();
                    });
                });
            },
            post: function($scope, $element, $attr, $form) {
                $scope.form = $form;
            }
        }
    };
});