/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInputAmount
 * @restrict: 'EA'
 * @scope
 *
 * @description
 * Input field for amounts. It allows user to enter only valid values of amount. The field has no mask. Entered amount is validated by
 * matching against regular expression pattern: `[0-9]{0,12}([,\.]|([,\.][0-9]{1,2}))?`.
 *
 * The pattern may be overriden if not appropriate.
 * The component may display a select box after the field allowing user to select the currency of the amount. If the select is not displayed
 * a label of currency is presented instead.
 *
 * @param {string} ebCurrencyModel Label of currency of the amount. Used when currency select box is hidden.
 * @param {boolean=} ebSelectCurrency Controls visibility of currency select box - if `true` the box will be visible.
 * @param {object=} ebForm Parent form model.
 * @param {number=} ebMaxAmount Upper bound of allowed amount values.
 * @param {number=} ebMinAmount Lower bound of allowed amount values.
 * @param {expression=} ebDisabled Sets the disabled attribute on the element if the expression evaluates to truthy.
 * @param {object=} ngModel Model for the amount.
 * @param {object=} ngCurrencyModel Model form currency selection.
 * @param {expression=} ebRequired If `true` the account number will be required.
 * @param {string} ebName Name of the input field.
 * @param {siring} ebPlaceholder A text to be presented inside the field when no user data is entered.
 * @param {string} minAmountMsg A message presented when entered amount is to small.
 * @param {string} maxAmountMsg A message presented when entered amount is to big.
 * @param {string} missingAmountMsg A message presented when no amount is entered.
 * @param {string} faultAmountMsg A message presented when entered amount is fault.
 * @param {string} accountId Id of account to validate the amount against availability of funds.
 * @param {string} overrideNumberPattern A pattern to check entered amount syntax - it overrides default pattern.
 * @param {string} includePln If this attribute is not empty the "GEL" currency label added to available currency list.
 */

angular.module( 'halyk-ebanking-shared').directive('cbInputAmount', function (pathService, $filter, $timeout, currenciesService, translate) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebAmountModel: "=", // prawdopodobnie nieużywane
            ebCurrencyModel: "@",
            ebSelectCurrency: "=",
            ebForm: "=",
            maxAmount: "=?ebMaxAmount",
            minAmount: "=?ebMinAmount",
            ebDisabled: "=",
            ngModel: "=",
            ngCurrencyModel: "=",
            ebRequired: '=',
            ebId: "=", // not used
            ebName: "@",
            ebInputStyle: "@",
            ebPlaceholder: "@",
            minAmountMsg: '@ebMinAmountMsg',
            maxAmountMsg: '@ebMaxAmountMsg',
            missingAmountMsg: '@ebMissingAmountMsg',
            faultAmountMsg: '@ebFaultAmountMsg',
            accountId: "@",
            accountCurrency: "@", // prawdopodobnie nieużywane
            overrideNumberPattern: "@",
            includePln: "@",
            fractionDigits: "@",
            restrictedCurrencies: "=?"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInputAmount/cbInputAmount.html',
        link: function postLink(scope, element, attrs) {
console.log('minAmount', scope.minAmount, 'maxAmount', scope.maxAmount);
            scope.mdl = {};
            scope.selectCurrency = false;
            scope.allCurrenciesList = [];
            if(!scope.fractionDigits) {
                scope.fractionDigits = "2";
                scope.step = 0.01;
                scope.numberPattern = "[0-9]{0,12}([,\\.]|([,\\.][0-9]{1,2}))?"
            } else {
                scope.step = 1/Math.pow(10, parseInt(scope.fractionDigits));
                scope.numberPattern = "[0-9]{0,12}([,\\.]|([,\\.][0-9]{1," + scope.fractionDigits + "}))?"
            }
            if (angular.isDefined(attrs.currency)) {
                scope.selectCurrency = true;
                scope.currenciesPromise = currenciesService.search().then(function (data) {
                    //scope.currenciesList = data.content;
                    scope.allCurrenciesList = data.content;
                    if (scope.allCurrenciesList != null && scope.includePln && !containsCurrency('GEL')) {
                        scope.allCurrenciesList.splice(0, 0, { content: 'GEL' });
                    }
                    restrictCurrencies();
                    scope.ngCurrencyModel = scope.ngCurrencyModel || 'EUR';
                });
                scope.$watch('ngCurrencyModel', function () {
                    scope.mdl.currency = scope.ngCurrencyModel;
                    scope.cleanValidationErrors(scope.ebForm, scope.ebName);
                });
                scope.selectCurrency = function (item, model) {
                    scope.ngCurrencyModel = item.content;
                }
            }

            scope.formatAmount = function(val) {
                if(val === true) {
                    if(scope.ngModel != undefined) {
                        let fractionDigits = parseInt(scope.fractionDigits);
                        var formatAmount = (Math.round(scope.ngModel * Math.pow(10, fractionDigits)) / (Math.pow(10, fractionDigits))).toFixed( fractionDigits);
                        scope.ngModel = formatAmount; 
                    }
                }
            };

            function restrictCurrencies() {
                if (scope.restrictedCurrencies == null) {
                    scope.currenciesList = scope.allCurrenciesList;
                } else {
                    scope.currenciesList = [];
                    for (var i = 0; i < scope.restrictedCurrencies.length; i++) {
                        if (containsCurrency(scope.restrictedCurrencies[i])) {
                            scope.currenciesList.splice(0, 0, { content: scope.restrictedCurrencies[i] });
                        }
                    }
                    /*
                    if (scope.currenciesList.length > 0) {
                        if ()
                    }
                */
                }
            }

            function containsCurrency(currency) {
                for (var i = 0; i < scope.allCurrenciesList.length; i++) {
                    if (scope.allCurrenciesList[i].content == currency) {
                        return true;
                    }
                }
                return false;
            }

            scope.cleanValidationErrors = function(form, fieldName) {
                    if (!form[fieldName].$valid) {
                        for ( var key in form[fieldName].$error ) {
                            console.log('Clear validation error: ' + key);
                            form[fieldName].$setValidity(key, true);
                        }
                    }
            }

            scope.$watch('restrictedCurrencies', function(newVal, oldVal) {
                restrictCurrencies();
            })

            scope.$watch('accountId', function(newVal, oldVal) {
                if(newVal != null && (newVal != oldVal)) {
                    var amount = scope.ngModel;
                    scope.ngModel = null;
                    scope.ebForm[scope.ebName].$setPristine();
                    scope.ebForm[scope.ebName].$setUntouched();
                    $timeout(function() {
                        scope.ngModel = amount;
                    }) //workaround for server errors reset
                }

            })

            if(!angular.isDefined(attrs.ebMinAmount)) {
                scope.minAmount = 0.01;
            }

            if(!angular.isDefined(attrs.ebMaxAmount)) {
                scope.maxAmount = 999999999999.99;
            }

            if (scope.ebPlaceholder == undefined || scope.ebPlaceholder.length <= 0) {
                scope.ebPlaceholder = "0";
            }
            // Ponieważ w stylach dla placeholdera jest ustawione direction: rtl; co jest jedynym sposobem,
            // żeby na starszych wersjach chrome i safari, w szczególności na tabletach, teskt wyświetlał
            // się z prawej strony muszę obrócić napis, żeby się poprawnie wyświetlał. Jak ktoś ma lepszy
            // pomysł to niech poprawi. I tak text-align nie działa.
            scope.ebPlaceholderR = scope.ebPlaceholder.split(' ').reverse().join(' ');

            /*if (!angular.isDefined(scope.maxDigitsMsg)) {
                scope.faultAmountMsg = translate.property("platform.component.amount.max_digits");
            }*/

            var viewInput = angular.element(element.querySelectorAll('.view-input')[0]);
            var editInput = angular.element(element.querySelectorAll('.edit-input')[0]);
        }

    };
});