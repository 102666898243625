/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbElixirCharactersValidator
 *
 * @description
 * cbElixirCharactersValidator
 */

angular.module( 'halyk-ebanking-shared').directive('cbElixirCharactersValidator', function(ELIXIR_CHARACTERS_REGEX) {
	var regexp = new RegExp(ELIXIR_CHARACTERS_REGEX);
	return {
		restrict: 'A',
		require:'ngModel',
    	link: function(scope, elem, attrs, ctrl){
      		ctrl.$validators.elixirCharactersOnly = function(value){
	        	if(value == null) return true;
	        	return regexp.test(value);
     	 	};
    	}
	};
});
