/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbNumericValidators
 *
 * @description
 * cbNumericValidators
 */
angular.module( 'halyk-ebanking-shared').directive('cbNumericValidators', function( $timeout, domService) {
    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            var maxAmount = 0;
            var minAmount = 0;

            attr.$observe('maxAmount', function(value) {
                maxAmount = parseFloat(value.replace(',', '.')) || Number.MAX_VALUE;
                ctrl.$validate();
            });

            attr.$observe('minAmount', function(value) {
                minAmount = parseFloat(value.replace(',', '.')) || 0.01;
                ctrl.$validate();
            });

            ctrl.$validators.maxAmount = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var numberStr = viewValue.replace(',', '.').replace(/\s/g, '').replace(/,(..?)$/, '.\\1');
                        if (!isNaN(numberStr)) {
                            var parsedNumber = parseFloat(numberStr);
                            return parsedNumber <= maxAmount;
                        }
                    }
                    return true;
                }
            };

            ctrl.$validators.minAmount = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var numberStr = viewValue.replace(',', '.').replace(/\s/g, '').replace(/,(..?)$/, '.\\1');
                        if (!isNaN(numberStr)) {
                            var parsedNumber = parseFloat(numberStr);
                            return parsedNumber >= minAmount;
                        }
                    }
                    return true;
                }
            };

            ctrl.$validators.noDigits = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var digitsAmount = viewValue.replace(/\D/g, '').length;
                        return digitsAmount > 0;
                    }
                    return true;
                }
            };

        }
    };
});
