'use strict';

angular.module( 'halyk-ebanking-shared' ).directive('cbSortList', function ( $compile
                                                                            , $rootScope
                                                                            , pathService
                                                                            , $filter ) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            columnName: "@",
            columnLabel: "@",
            sourceList: "@"
        },
        templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbSortList/cbSortList.html',

        link: function postLink ( scope , element , attrs ) {

            var orderBy = $filter('orderBy');
            scope.lastOrdered = "";
            scope.reverse = false;
            scope.backupList = [];
            scope.orderRestoreCounter = 0;
            scope.order = function ( input ) {
                
                if ( scope.orderRestoreCounter === 1
                    && input !== "initOrder"
                    && scope.lastOrdered === input ) {
                        scope.sourceList = scope.backupList;
                        scope.orderRestoreCounter = -1;
                        scope.reverse = false;
                        scope.lastOrdered = "";
                } else {
                    scope.orderRestoreCounter++;
                    var reverse = false;

                    if ( input === "initOrder" ) {
                        scope.backupList = scope.sourceList;
                        scope.orderRestoreCounter = -1;
                    }
                    
                    if ( scope.lastOrdered === input ) {
                        reverse = !scope.reverse;
                        scope.reverse = reverse;
                    } else {
                        scope.orderRestoreCounter = 0;
                        scope.reverse = false;
                    }
                    scope.lastOrdered = input;

                    scope.sourceList = orderBy ( scope.sourceList 
                                                            , input 
                                                            , reverse );
                }
            };
            scope.needFlip = function ( input ) {
                return scope.reverse 
                    && scope.orderRestoreCounter % 2 == 1 
                    && scope.lastOrdered === input;
            }
            scope.needShowIcon = function ( input ) {
                return scope.lastOrdered === input;
            }

        }
    }
});

