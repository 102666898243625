/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAmountRangeSelect
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Component displays two input fields allowing user to edit lower and upper bounds of amount. Bound fields are connected to parent form models.
 * Component validates entered values and displays provided message when invalid.
 *
 * @param {object=} ebForm Parent form model.
 * @param {object=} amountFrom Model of the lower bound value.
 * @param {object=} amountTo Model of the upper bound value.
 * @param {string} amountRangeMsg Message to bew displayed when user edited value is out of the range.
 *
 * @example
 * ```js
    <div>
        <cb-amount-range-select eb-form="paymentFilterForm" amount-from="ngModel.amountFrom" amount-to="ngModel.amountTo></cb-amount-range-select>
    </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbAmountRangeSelect', function($compile, $rootScope, $timeout, $log, $http,$filter, $templateCache, pathService, translate) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ebForm: "=",
            amountFrom: "=",
            amountTo: "=",
            amountRangeMsg: "@ebFaultAmountMsg"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAmountRangeSelect/cbAmountRangeSelect.html',

        link: function postLink(scope) {
            scope.errors = false;
            scope.focusInput = function(inputId) {
                var element = angular.element(document.querySelector('#' + inputId))[0];
                if (element !== undefined) {
                    element.focus();
                }
            };

            if (!angular.isDefined(scope.amountRangeMsg)) {
                scope.amountRangeMsg = translate.property("amount_range_select.validation.amount_to_bigger_than_from");
            }

            scope.$watchGroup(['amountFrom', 'amountTo'], function(newValue, oldValue) {
                if (newValue[0] != null && newValue[1] != null && parseFloat(newValue[0]) > parseFloat(newValue[1])) {
                    scope.errors = true;
                    scope.rangeError = true;
                    scope.ebForm.$valid = false;
                } else {
                    scope.errors = false;
                    scope.rangeError = false;
                    scope.ebForm.$valid = true;
                }
            }, true);
        }
    }
});

angular.module( 'halyk-ebanking-shared').directive('cbAmountRangeSelectRangeValidator', function() {
    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {


            attr.$observe('amountRangeError', function(value) {
                ctrl.$validate();
            });

            ctrl.$validators.amountRangeError = function(modelValue, viewValue) {
                if (scope.amountFrom != null && scope.amountTo != null) {
                    if (parseFloat(scope.amountFrom) > parseFloat(scope.amountTo))
                        return false;
                    else
                        return true;
                }
                return true;
            };
        }
    };
});
