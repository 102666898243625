/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbFilterInputAmount
 * @restrict EA
 * @scope
 *
 * @description
 * Input field for amounts with validation messages tweaked for use in filters. For description of attribues
 * see {@link halyk-ebanking-shared.directive:cbInputAmount cbInputAmount}. The directive registers default validation messages making
 * coresponding attributes optional.
 *
 */

angular.module( 'halyk-ebanking-shared').directive('cbFilterInputAmount', function (pathService, $filter, $timeout, currenciesService, translate) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebAmountModel: "=", // prawdopodobnie nieużywane
            ebCurrencyModel: "@",
            ebSelectCurrency: "=",
            ebForm: "=",
            maxAmount: "=?ebMaxAmount",
            minAmount: "=?ebMinAmount",
            ebDisabled: "=",
            ngModel: "=",
            ngCurrencyModel: "=",
            ebRequired: '=',
            ebId: "=",
            ebName: "@",
            ebInputStyle: "@",
            ebPlaceholder: "@",
            minAmountMsg: '@ebMinAmountMsg',
            maxAmountMsg: '@ebMaxAmountMsg',
            missingAmountMsg: '@ebMissingAmountMsg',
            faultAmountMsg: '@ebFaultAmountMsg',
            accountId: "@",
            accountCurrency: "@",
            overrideNumberPattern: "@",
            ebType: "@",
            maxLength: "@",
            minLength: "@",
            callback: "&"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbFilterInputAmount/cbFilterInputAmount.html',
        link: function postLink(scope, element, attrs) {

            scope.mdl = {};
            scope.selectCurrency = false;
            if (angular.isDefined(attrs.missingAmountMsg)) {
                scope.missingAmountMsg = attrs.missingAmountMsg;
            }

            if (!angular.isDefined( attrs.ebType) ){
                scope.ebType = 'text';
            }
            if (!angular.isDefined( attrs.maxLength) ){
                scope.maxLength = '2000';
            }
            if ( !angular.isDefined ( attrs.minLength ) ){
                scope.minLength = '0';
            }

            if (angular.isDefined(attrs.currency)) {
                scope.selectCurrency = true;
                scope.currenciesPromise = currenciesService.search().then(function (data) {
                    scope.currenciesList = data.content;
                    scope.ngCurrencyModel = scope.ngCurrencyModel || 'EUR';
                    if(scope.currenciesList.length == 1) {
                    	scope.selectCurrency = false;
                    	scope.ebCurrencyModel = scope.currenciesList[0].content;
                    	scope.ngCurrencyModel = scope.ebCurrencyModel;
                    }

                });
                scope.$watch('ngCurrencyModel', function () {
                    scope.mdl.currency = scope.ngCurrencyModel;
                });
                scope.selectCurrency = function (item, model) {
                    scope.ngCurrencyModel = item.content;
                }


            }

            scope.inputChange = function(){
                if ( angular.isDefined( scope.callback ) ){
                    scope.callback({});
                }
            };

            scope.$watch('accountId', function(newVal, oldVal) {
                if(newVal != null && (newVal != oldVal)) {
                    var amount = scope.ngModel;
                    scope.ngModel = null;
                    scope.ebForm[scope.ebName].$setPristine();
                    scope.ebForm[scope.ebName].$setUntouched();
                    $timeout(function() {
                        scope.ngModel = amount;
                    }) //workaround for server errors reset
                }

            })

            if(!angular.isDefined(attrs.ebMinAmount)) {
                scope.minAmount = 0.01;
            }

            if(!angular.isDefined(attrs.ebMaxAmount)) {
                scope.maxAmount = 999999999999.99;
            }

            if (scope.ebPlaceholder == undefined || scope.ebPlaceholder.length <= 0) {
                scope.ebPlaceholder = "0";
            }
            // Ponieważ w stylach dla placeholdera jest ustawione direction: rtl; co jest jedynym sposobem,
            // żeby na starszych wersjach chrome i safari, w szczególności na tabletach, teskt wyświetlał
            // się z prawej strony muszę obrócić napis, żeby się poprawnie wyświetlał. Jak ktoś ma lepszy
            // pomysł to niech poprawi. I tak text-align nie działa.
            scope.ebPlaceholderR = scope.ebPlaceholder.split(' ').reverse().join(' ');

            if (!angular.isDefined(scope.minAmountMsg)) {
                scope.minAmountMsg = translate.property("platform.component.amount.min");
            }

            if (!angular.isDefined(scope.maxAmountMsg)) {
                scope.maxAmountMsg = translate.property("platform.component.amount.max");
            }

            if (!angular.isDefined(scope.missingAmountMsg)) {
                scope.missingAmountMsg = translate.property("platform.component.amount.missing");
            }

            if (!angular.isDefined(scope.faultAmountMsg)) {
                scope.faultAmountMsg = translate.property("platform.component.amount.fault");
            }

            /*if (!angular.isDefined(scope.maxDigitsMsg)) {
                scope.faultAmountMsg = translate.property("platform.component.amount.max_digits");
            }*/

            // var viewInput = angular.element(element.querySelectorAll('.view-input')[0]);
            // var editInput = angular.element(element.querySelectorAll('.edit-input')[0]);


            scope.$watch('ebForm.$submitted',function(newVal, oldVal){
                if(newVal!=oldVal && newVal && scope.ebType!="tel"&&scope.ebType!="personalNumber"&&scope.ngModel!=undefined){
                    scope.ngModel=parseFloat(scope.ngModel);
                }
            });
        }

    };
});

angular.module( 'halyk-ebanking-shared').directive('amountValidators', function( $timeout, domService) {
    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            var maxAmount = 0;
            var minAmount = 0;

            attr.$observe('maxAmount', function(value) {
                maxAmount = parseFloat(value.replace(',', '.')) || Number.MAX_VALUE;
                ctrl.$validate();
            });

            attr.$observe('minAmount', function(value) {
                minAmount = parseFloat(value.replace(',', '.')) || 0.01;
                ctrl.$validate();
            });

            ctrl.$validators.maxAmount = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var numberStr = viewValue.replace(',', '.').replace(/\s/g, '').replace(/,(..?)$/, '.\\1');
                        if (!isNaN(numberStr)) {
                            var parsedNumber = parseFloat(numberStr);
                            return parsedNumber <= maxAmount;
                        }
                    }
                    return true;
                }
            };

            ctrl.$validators.minAmount = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var numberStr = viewValue.replace(',', '.').replace(/\s/g, '').replace(/,(..?)$/, '.\\1');
                        if (!isNaN(numberStr)) {
                            var parsedNumber = parseFloat(numberStr);
                            return parsedNumber >= minAmount;
                        }
                    }
                    return true;
                }
            };

            ctrl.$validators.noDigits = function (modelValue, viewValue) {
                if (!scope.isEditModeOn) {
                    if (viewValue !== undefined && viewValue != null && viewValue != "" && viewValue.replace) {
                        var digitsAmount = viewValue.replace(/\D/g, '').length;
                        return digitsAmount > 0;
                    }
                    return true;
                }
            };

        }
    };
});
