/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbMobileAppPromotionDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module('halyk-ebanking-shared').directive('cbMobileAppPromotionDialog', function (pathService, translate, viewStateService) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            close: '&ebOnClose',
            choices: '=ebChoices',
            callback: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbMobileAppPromotionDialog/cbMobileAppPromotionDialog.html"
        , link: function postLink(scope, element, attrs) {
            element[0].parentNode.classList.add("mobile-app-dialog");
            element[0].parentNode.parentNode.classList.add("mobile-app-dialog");
        }
    };
});
