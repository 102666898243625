/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbDecisionNoInput
 *
 * @description
 * cbDecisionNoInput
 */

angular.module( 'halyk-ebanking-shared').directive('cbDecisionNoInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            ngModel: '=',
            insurancePaymentType: '=',
            maxLength: '@',
            placeholder: '@',
            globalRequired: '=isRequired',
            cbName: '@',
            cbForm: '='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbDecisionNoInput/cbDecisionNoInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            scope.maxLength = scope.maxLength || 15;

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input.value = ngModelController.$viewValue || '';
                    input[0].value = ngModelController.$viewValue || '';
                });
            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            if(angular.isDefined(scope.globalRequired)){
                scope.isRequired = scope.globalRequired;
            }

            scope.$watch('insurancePaymentType', function(newVal) {
                if (newVal == 'TYPE_S' || newVal == 'TYPE_M' || !newVal) {
                    scope.ngModel = undefined;
                    scope.isDisabled = true;
                    scope.isRequired = false;
                } else {
                    scope.isDisabled = false;
                    if(angular.isDefined(scope.globalRequired)){
                        scope.isRequired = scope.globalRequired && true;
                    }else{
                        scope.isRequired = true;
                    }

                }
            });

        }
    };

});
