angular.module( 'halyk-ebanking-shared').directive( 'cbGoToStepState', function( viewStackService) {
    return {
        restrict: 'A',
        scope: false,
        link: function( scope, element, attrs) {
            var handler = function() {
                viewStackService.goToStepState( attrs.cbGoToStepState);
            };
            var unbindHandler = function () {
                element.unbind( 'click', handler);
            };
            element.bind( 'click', handler);
            scope.$on( '$destroy', unbindHandler);
        }
    };
});