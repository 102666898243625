/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTitleValidator
 *
 * @description
 * cbTitleValidator
 */

angular.module( 'halyk-ebanking-shared').directive('cbTitleValidator', function(PAYMENT_TITLE_REGEX,PAYMENT_TITLE_REGEX_SWIFT) {
    var regexp = new RegExp(PAYMENT_TITLE_REGEX);
    var regexpS = new RegExp(PAYMENT_TITLE_REGEX_SWIFT);
    return {
        restrict: 'A',
        require:'ngModel',
        link: function(scope, elem, attrs, ctrl){
            ctrl.$validators.paymentCharactersOnly = function(value){
                if(value) {
                    if(scope.form == 'DOMESTIC' || scope.form == 'OWN'){
                        return regexp.test(value);
                    }
                    if(scope.form == 'SEPA' || scope.form == 'SWIFT'){
                        return regexpS.test(value);
                    }
                }
                return true;
            };
        }
    };
});
