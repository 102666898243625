/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbPaymentListDialog
 * @restrict EA
 * @scope
 *
 * @description
 * A dialog presenting list of choices.
 *
 * @param {string} title Title of the dialog.
 * @param {string} message Message for the user.
 * @param {string} icon Icon name.
 * @param {expression} close Action to be executed on close.
 * @param {object=} ebChoices Model of the choices list.
 */

angular.module('halyk-ebanking-shared').directive('cbPaymentListDialog',
    function (pathService
        , isNullCheckService) {
        return {
            restrict: 'EA',
            replace: true,
            transclude: true,
            scope: {
                title: '@ebTitle'
                , paymentList: '='
                , buttonName: '='
                , close: '&ebOnClose'
                , callback: '&'
                , whoIAm: '='
                , isNotEnoughPaymentLimit: '='
                , noPaymentsToSend: '='
                , notEnoughMoney: '='
                , backState: '@'
            }
            , templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared')
                + "/directives/cbPaymentListDialog/cbPaymentListDialog.html"
            , link: function postLink(scope, element, attrs) {
                scope.waiting = false;
                scope.answered = false;

                scope.wrapCallback = function (sms) {
                    scope.waiting = true;
                    return scope.callback({sms: sms}).then(
                        function (result) {

                            if (result != null
                                && result.paymentResultList != null
                                && isNullCheckService.isNotEmptyArray(result.paymentResultList)) {

                                scope.paymentList.content = result.paymentResultList;
                                scope.waiting = false;
                                scope.answered = true;

                            } else {
                                return result;
                            }

                        }
                    );
                }

                scope.isBulk = function (payment) {

                    if (payment.bulkId == null) return false;
                    if (String(payment.bulkId).length < 3) return false;
                    return true;
                }
                scope.isForeignBulk = function (payment) {

                    if ($scope.isBulk(payment))
                        if ((payment.bulk.bulkType == 'BULK_FOREIGN') || (payment.bulk.bulkType == 'MT103FOR')) return true;
                    return false;
                }
                scope.isStatement = function (payment) {

                    return payment.productRequestId != null;
                }

                scope.isPayBillOnly = function (payment) {

                    return payment.transactionType != null && payment.transactionType.toUpperCase() == "MAKE_PAYMENT";
                }
            }
        };
    }
);
