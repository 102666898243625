/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSectionHeader
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Header for section of form.
 *
 * @param {string} cbHeader I18n key of header content.
 * @param {string} cbHeaderExtraLabel Label of extra data displayed on the header area beaneath the header content.
 * @param {string} cbHeaderExtraValue Extra data displayed on the header area beaneath the header content.
 * @param {string} backState Name of previous state - the one that click on the back icon will navigate to.
 * @param {object=} params Parameters assosiated with back state.
 * @param {string} cbHideClose If this attribute is not empty the close icon will be hidden.
 * @param {string} cbHideBack If  this attribute is not empty the back icon will be hidden.
 * @param {string} titleWidth Width of the title part of the header.
 * @param {string} cbNowrap If this attribute is not empty prevents braking header line.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSectionHeader', function (pathService, $state, ebTheming, $timeout, viewStackService) {

    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            header: '@cbHeader',
            headerExtraLabel: '@cbHeaderExtraLabel',
            headerExtraValue: '@cbHeaderExtraValue',
            backState: '@',
            params: '=',
            close: '@cbHideClose',
            back: '@cbHideBack',
            titlewidth: '@titleWidth',
            cbNowrap: '@'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSectionHeader/cbSectionHeader.html',
        link: function preLink(scope, element, attrs) {
            ebTheming.applyTheme(element);

            scope.enableOptions = attrs.options != null ? true : false;

            scope.goBack = function() {
                var action = null;
                if (!viewStackService.isStackEmpty()) {
                    action = viewStackService.goToPreviousState;
                }
                else {
                    if(scope.backState != null) {
                        action = function() {
                            var params = scope.params || {};
                            $state.go(scope.backState, params);
                        };
                    }
                }
                if (action) {
                    $timeout( action);
                }
            }
        }
    }
});
