/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbActionFooterWithCancel
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Section with submit and cancel action buttons to be placed at the bottom of a container.
 *
 * @param {string} cancelLabel Label display on the button.
 * @param {string} submitLabel Action to be executed when the button is clicked.
 * @param {string} submitIcon Label display on the button.
 * @param {expression} submitAction Action to be executed when the submit button is clicked.
 * @param {expression} cancelAction Action to be executed when the cancel button is clicked.
 *
 * @example
 * ```js
    <div>
        <cb-action-footer submit-label="cb.calendar.form.accept.delete.submit" submit-action="accept()"></cb-action-footer>
    </div>
 * ```
 */
angular.module( 'halyk-ebanking-shared').directive('cbActionFooterWithCancel', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            cancelLabel: '@',
            submitLabel: '@',
            submitIcon: '@',
            submitAction: '&',
            cancelAction: '&'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbActionFooterWithCancel/cbActionFooterWithCancel.html",
        link: function postLink(scope, element, attrs) {
          ebTheming.applyTheme(element);
        }
    };
});
