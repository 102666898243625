/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAccountSelect
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down for currency selection. Available currencies are loaded from accoutsService.
 *
 * @param {string} ebOppositeAccount Id of a target account - this account will not be available on the drop down list.
 * @param {object=} account Target model for currently selected account.
 * @param {string} accountForPrepaid Id of a target account for topup - this account will not be available on the drop down list.
 * @param {string} accountForInsuranceAndTax Id of a target account for tax transfer - this account will not be available on the drop down list.
 * @param {string} placeholder A placeholder displayed when no account is selected.
 * @param {string} currency Currency to filter available accounts.
 * @param {string=} ngModel Target model in parent scope for id of selected account.
 * @param {string=} primaryAccountNumber A preferred account object - if no account is selected and no ebOppositeAccount is defined then this account will be set as default.
 * @param {number} minAccessibleAssets Level of available assets to filter accounts - accounts with assets below the level will removed from list.
 * @param {string} cbMenuPosition Allows to set where dropped list is to appear - possible values: up, down.
 *
 *
 * @example
 * ```js
    <div>
        <cb-account-select name="settlementAccountId"
            ng-model="depositForm.formModel.settlementAccountId"
            ng-required="true"
            placeholder="Select account"
            account="depositForm.selectedAccount"
            currency="{{deposit.currency}}"
            primary-account-number="deposit.settlementAccountNo"
            cb-menu-position="up">
        </cb-account-select>
    </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbAccountSelect', function($rootScope, $filter, pathService, accountsService) {

    return {
        restrict: 'EA',
        replace: true,
        require: 'ngModel',
        scope: {
            ebOppositeAccount: '@',
            account: "=?",
            accountForPrepaid: "@", // TODO prawdopodonie nie używane nigdzie - czym by to się miało różnić od account
            accountForInsuranceAndTax: "@", // TODO j.w.
            currency: "@",
            model: "=ngModel",
            primaryAccountNumber: "=?",
            minAccessibleAssets: "@",
            cbMenuPosition: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAccountSelect/cbAccountSelect.html',

        link: function postLink(scope, element, attrs, ngModel) {

            scope.params = {
                currency: scope.currency,
                fields: 'accountId,accountName,accountNo,accessibleAssets,currency',
                accountForPrepaid: scope.accountForPrepaid,
                accountForInsuranceAndTax: scope.accountForInsuranceAndTax,
                pageSize: 10000
            };

            scope.placeholder = attrs.placeholder;
            scope.currency = attrs.currency;

            scope.accountList = [];

            var adjustAccountsToMinAccessibleAssets = function() {
                if(scope.minAccessibleAssets) {
                    scope.accountList = $filter('filter')(scope.accountList, function(account) {
                        return !scope.minAccessibleAssets || account.accessibleAssets >= scope.minAccessibleAssets;
                    });

                    if((scope.account == null || $rootScope.getObjectFromListBy("accountId", scope.account.accountId, scope.accountList) == null) && scope.accountList.length > 0) {
                        scope.model = scope.accountList[0].accountId;
                    }
                }
            };
            scope.$watch("minAccessibleAssets", adjustAccountsToMinAccessibleAssets);

            scope.accountsPromise = accountsService.search(scope.params).then(function(accountList) {
                scope.accountList = accountList.content;
                adjustAccountsToMinAccessibleAssets();

                if (attrs.ebOppositeAccount == null && scope.account == null) {
                    if(scope.primaryAccountNumber) {
                         for (var i = scope.accountList.length - 1; i >= 0; i--) {
                            if (scope.accountList[i].accountNo == scope.primaryAccountNumber) {
                                scope.account = scope.accountList[i];
                            }
                        }
                    } else {
                        for (var i = scope.accountList.length - 1; i >= 0; i--) {
                            if (scope.accountList[i].customerProductData && scope.accountList[i].customerProductData.defaultForDispositions && ngModel.$viewValue == null) {
                                scope.account = scope.accountList[i];
                            } else if (scope.accountList[i].accountId == ngModel.$viewValue) {
                                scope.account = scope.accountList[i];
                            }
                        }
                    }

                    if (scope.account == null) {
                        scope.account = scope.accountList[0];
                    }
                    scope.model = scope.account.accountId;
                }
            });

            scope.$watch('ebOppositeAccount', function(newVal, oldVal) {
                if (scope.account == null ||
                    (newVal != null && newVal != oldVal && newVal == scope.account.accountId)) {
                    scope.accountsPromise.then(function() {
                        for (var i = scope.accountList.length - 1; i >= 0; i--) {
                            if (scope.accountList[i].accountId != newVal && newVal != null && newVal != '') {
                                scope.account = scope.accountList[i];
                                scope.model = scope.account.accountId;
                                break;
                            }
                        }
                    });
                }
            });

            scope.selectAccount = function(item, model) {
                scope.account = item;
                /*if (model == scope.ebSecondAccount) {
                    scope.ebSecondAccount = null;
                }*/
            };

        }
    };
});
