/* globals angular */

/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:maskNrbFilterLong
 *
 * @description
 * maskNrbFilterLong
 */

 angular.module( 'halyk-ebanking-shared').filter('maskNrbFilterLong', function( cbSmartphoneDetectionService) {

    var formatShort = function( number) {
        return number;
        // return number.substring(0, 2) +
        //         " " + number.substring(2, 6) +
        //         " ... " +
        //         number.substring(number.length-6, number.length - 4) +
        //         " " + number.substring(number.length-4, number.length);
    };

    var formatLong = function( number) {
        return number;
        // var result = number.substring(0, 2), i;
        // for (i=0; i<6; i++) {
        //     result += " " + number.substring(2 + i*4, 6 + i*4);
        // }
        // return result;
    };

    return function( number) {
        if (number != null) {
            number = number.replace(/\s+/g,'');
            if(number.length > 0) {
                if (cbSmartphoneDetectionService.isSmartphone()) {
                    return formatShort( number);
                }
                else {
                    return formatLong( number);
                }
            }
        }
        return null;
    };

});
