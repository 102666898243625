/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbPdfService
 *
 * @description
 * An object providing methods creating url for reports.
 *
 * @requires gate, httpService, language, logger, $q, $filter
 */

angular.module('halyk-ebanking-shared').service("cbPdfService", function (gate, httpService, language, logger, $q, $filter, userService) {

    var constants = {
        formatType: "pdf",
        contentDisposition: "download"
    };

    var constantsPrint = {
        formatType: "pdf",
        contentDisposition: "view"
    };

    var constantsPrintTxt = {
        formatType: "txt",
        contentDisposition: "view"
    };

    var constantsDownloadPdf = {
        formatType: "pdf",
        contentDisposition: "download"
    };

    var constantsDownloadXsl = {
        formatType: "xls",
        contentDisposition: "download"
    };

    var constantsDownloadTxt = {
        formatType: "txt",
        contentDisposition: "download"
    };

    var printDownloadLinks = [
        {
            miniApp: 'accounts',
            link: 'download_account_requisites_report',
            _links: {
                "download_account_requisites_report": {
                    "href": "/api/account/downloads/download_account_requisites_report.json"
                }
            }
        },
        {
            miniApp: 'loans',
            link: 'download_credit_requisites_report',
            _links: {
                "download_credit_requisites_report": {
                    "href": "/api/credit/downloads/download_credit_requisites_report.json"
                }
            }
        },
        {
            miniApp: 'deposits',
            link: 'download_deposit_requisites_report',
            _links: {
                "download_deposit_requisites_report": {
                    "href": "/api/deposit/downloads/download_deposit_requisites_report.json"
                }
            }
        },
        {
            miniApp: 'bulkpayment',
            link: 'download_bulk_payment_list',
            _links: {
                "download_bulk_payment_list": {
                    "href": "/api/le_all/downloads/download_bulk_payment_list.json"
                }
            }
        }
    ];

    function Anchor(href, target) {
        this.href = undefined;
        this.target = undefined;

        if (arguments.length >= 2) {
            this.href = href;
            this.target = target;
        }
    }

    var emptyAnchor = new Anchor();

    function getAnchorSetPromise(ctx, params, rel) {

        var userServicePromise = userService.getUserDetails()
            , gateGetHrefPromise = gate.getHref(rel, ctx);

        var promise = $q.all([userServicePromise
            , gateGetHrefPromise])
            .then(
                function (result) {
                    var href = result [1];

                    if (href == undefined) {
                        return angular.copy(emptyAnchor);
                    }

                    var target = getTargetForReports();

                    var extraParams = {
                        language: language.get()
                        , customerId: result [0].userIdentityId.id
                    };

                    var theHref1 = prepareLink(href
                        , angular.extend({}
                            , constantsPrint
                            , params
                            , extraParams
                        )
                    );

                    var theHref2 = prepareLink(href
                        , angular.extend({}
                            , constantsDownloadPdf
                            , params
                            , extraParams
                        )
                    );
                    var theHref3 = prepareLink(href
                        , angular.extend({}
                            , constantsDownloadXsl
                            , params
                            , extraParams
                        )
                    );
                    var theHref4 = prepareLink(href
                        , angular.extend({}
                            , constantsDownloadTxt
                            , params
                            , extraParams
                        )
                    );

                    var theHref5 = prepareLink(href
                        , angular.extend({}
                            , constantsPrintTxt
                            , params
                            , extraParams
                        )
                    );
                    return {
                        printAnchor: new Anchor(theHref1, target),
                        printTxtAnchor: new Anchor(theHref5, target),
                        downloadPdfAnchor: new Anchor(theHref2, target),
                        downloadXlsAnchor: new Anchor(theHref3, target),
                        downloadTxtAnchor: new Anchor(theHref4, target)
                    }
                }).catch(function () {
                    logger.warn(arguments);
                    return angular.copy(emptyAnchor);
                }
            );

        return promise;
    }

    function getAnchorPromise(ctx, params, rel) {
        var href;
        var promise = gate.hasRelation(rel, ctx).then(function (yes) {

            if (yes) {
                return gate.getHref(rel, ctx);
            } else {
                //return $q.rejected( "Ctx '" + ctx + "' does not contain '" + rel + "' link!" );
                return;
            }

        }).then(function (hrefValue) {
            href = hrefValue;
            return userService.getUserDetails();
        }).then(function () {
            if (href == undefined) {
                return angular.copy(emptyAnchor);
            }

            var paramsToExtend = {
                formatType: constants.formatType,
                contentDisposition: constants.contentDisposition,
                language: language.get(),
                customerId: detail.userIdentityId.id
            };

            var extendParams = angular.extend(paramsToExtend, params);

            var theHref = prepareLink(href, extendParams);
            return new Anchor(theHref, getTargetForReports());
        }).catch(function () {
            logger.warn(arguments);
            return angular.copy(emptyAnchor);
        });

        return promise;
    }

    var paramDateFilter = function (params) {
        var par = angular.copy(params);
        for (var w in par) {

            if (par.hasOwnProperty(w) && par[w] instanceof Date) {
                par[w] = $filter('date')(par[w], 'yyyy-MM-dd');
            }
        }
        return par;
    };

    function prepareLink(href, params) {
        if (href && href.startsWith('http')) {
            return href + httpService.serializeParams(paramDateFilter(params), true);
        } else {
            return httpService.appContext + href + httpService.serializeParams(paramDateFilter(params), true);
        }
    }

    function getTargetForReports() {
        return isIE() ? "" : "_blank";
    }

    function isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!ua.match(/Trident.*rv\:11/))
            return true;
        else
            return false;
    }

    var getPrintDownloadAnchors = function (ctx, documentType, params, miniApp) {
        var info = null;
        angular.forEach(printDownloadLinks, function (item) {
            if (miniApp == item.miniApp) {
                info = item;
            }
        });
        if (!gate.hasRelation(info.link, ctx)) {
            ctx._links = info._links;
        }
        params = angular.extend({documentType: documentType}, params);
        getAnchorSetPromise(ctx, params, info.link).then(function (anchors) {
            ctx.anchors = anchors;
        });
    };

    return {
        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbPdfService#getAnchorPromise
         *
         * @desciption
         * This method returns a promise of object containing parametrs `href` and `target` for an anchor element providing a report.
         *
         * @param {object} ctx An object of context - it contains data of current context and links to related actions.
         * @param {object} params Object of parameters.
         * @param {string} rel Name of target relation.
         */
        getAnchorPromise: function (ctx, params, rel) {
            return getAnchorPromise(ctx, params, rel);
        },

        getAnchorSetPromise: function (ctx, params, rel) {
            return getAnchorSetPromise(ctx, params, rel);
        },
        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbPdfService#getPaymentConfAnchor
         *
         * @desciption
         * This method calls {@link halyk-ebanking-shared.service:cbPdfService#getAnchorPromise} for given payment.
         *
         * @param {object} payment A payment object.
         */
        // funkcja zwraca promise, który zwraca obiekt zawierający element href i target dla pojedynczej transakcji
        // jeśli transakcja nie posiada linka do funkcji pobierania wtedy zwracany jest obiekt
        // ale wartości są undefined
        getPaymentConfAnchor: function (payment) {
            return getAnchorPromise(payment, {id: payment.id}, "download_payment_details");
        },
        getPrintDownloadAnchors: function (ctx, documentType, params, rel) {
            return getPrintDownloadAnchors(ctx, documentType, params, rel);
        },

        printPdf: function (params, url) {
            userService.getUserDetails().then(function (result) {
                angular.extend(params, {
                    formatType: 'pdf',
                    contentDisposition: 'view',
                    language: language.get(),
                    customerId: result.userIdentityId.id
                })
                const href = prepareLink(url, params);
                window.open(href, '_blank');
            });
        },

        download: function (params, formatType, url) {
            userService.getUserDetails().then(function (result) {
                angular.extend(params, {
                    formatType: formatType,
                    contentDisposition: 'download',
                    language: language.get(),
                    customerId: result.userIdentityId.id
                })
                window.location = prepareLink(url, params);
                window.target = '_blank';
            });
        },

        downloadXls: function (params, url) {
            this.download(params, 'xls', url);
        },

        downloadPdf: function (params, url) {
            this.download(params, 'pdf', url);
        }

    }
});
