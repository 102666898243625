/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbFocused
 *
 * @description
 * cbFocused
 */

angular.module( 'halyk-ebanking-shared').directive('cbFocused', function($timeout, $parse){

  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var isFocusedAttr = $parse(attrs.cbFocused);

      scope.$watch(isFocusedAttr, function(value) {
        if(value === true) {
          $timeout(function() {
            element[0].focus();
          });
        }
      });

    }
  };
});
