'use strict';

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbInputAccountNo
 * @restrict EA
 * @scope
 *
 * @description
 * Input field for entering account number.
 *
 * @param {string=} ngModel The model for account number.
 * @param {string} cbName Name for the input field.
 * @param {string} cbForm Name of the form to which the fiald will be incorporated.
 * @param {boolean=} cbRequired If `true` the account number will be required.
 * @param {string} placeholder A text to be presented inside the field when no user data is entered.
 * @param {expression} readOnly Sets the readOnly attribute on the element, if the expression is truthy.
 */

angular.module( 'halyk-ebanking-shared').directive('cbInputAccountNo', function (pathService) {
	return {
		restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
        	ngModel: '=',
        	cbName: '@',
        	cbForm: '=',
        	cbRequired: '=',
        	cbMinlength: '@', // NOT USED
        	placeholder: "@",
        	readOnly: "@"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbInputAccountNo/cbInputAccountNo.html',
        link: function postlink(scope, element, attrs, ctrl) {
        	var input = element.find("input");
        	var keyCode = undefined;

			scope.$watch('ngModel', function(newVal, oldVal, event) {
				if (newVal != null) {
					var selStart = input[0].selectionStart;
                    var selEnd = input[0].selectionEnd;
					newVal = newVal.toString().replace(/\s+/g, '')
					for (var i = 2; i <= 27; i += 5) {
						if(newVal.length > i && newVal.charAt(i) != " ")
							newVal = newVal.slice(0,i) + " " + newVal.slice(i);
					}
					input.val(newVal);
					if (keyCode == 46 || keyCode == 8) {
						input[0].selectionStart = selStart;
						input[0].selectionEnd = selEnd;
					}
					/*else if (newVal.length == 4 || newVal.length == 9 || newVal.length == 14 || newVal.length == 19 || newVal.length == 24 || newVal.length == 29) {
						input[0].selectionStart = selStart + 1;
						input[0].selectionEnd = selEnd + 1;
					}
					else {
						input[0].selectionStart = selStart;
						input[0].selectionEnd = selEnd;
					}*/
				}
			});    

			input.bind('keydown', function(event) {
				keyCode = event.keyCode;
				if(event.keyCode == 32)
					event.preventDefault();
			});   

			/*ctrl.$validators.min = function (ngModel) {
				if(ngModel.length < 32)
					return true;
				return false;
			}	*/

        }
	};
});