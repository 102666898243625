/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAuthorization
 *
 * @restrict EA
 * @scope
 *
 * @description
 * cbAuthorization
 *
 * @param {object=} ebModel model
 */

angular.module( 'halyk-ebanking-shared').directive('cbAuthorization', function(pathService, ebTheming, authorizationRequestsService, formService, $q) {

    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        scope: {
            ebModel: '=',
            ebDispositionId: '=?',
            ebResourceType: '@?',
            ebRelation: '@?',
            ebErrorCode: '=',
            ebText: '=',
            ebForm: '=',
            ebRequired: '=',
            ebDisabled: '=',
            ebShowSmsAuthNo: '='
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbAuthorization/cbAuthorization.html',

        link: function postLink(scope, element, attrs) {

            ebTheming.applyTheme(element);

            scope.ngModel = {};

            scope.$watch('ebDispositionId', function(newVal) {
                if(newVal) {
                    scope.authPromise = authorizationRequestsService.action({
                        resourceId: newVal,
                        resourceType: scope.ebResourceType,
                        relation: scope.ebRelation
                    }, "create").then(function(authId) {
                        scope.authPromise = authorizationRequestsService.get(authId.content, {
                            resourceId: authId.content,
                            authorizationRequestId: authId.content
                        }).then(function(data) {
                            scope.authData = data;
                            scope.ebModel.authenticationAttemptId = scope.authData.authenticationAttemptId;

                            if (scope.ebShowSmsAuthNo && scope.authData.authenticationMethod == 'SMS') {
                                var pattern = /(\d{2})(\d{2})(\d{4})/;
                                var st = scope.authData.authenticationAttributes.authDate;
                                scope.authDateValue = new Date(st.replace(pattern, '$3-$2-$1'));
                            }
                        }, function(error) {
                            var letters = /([a-zA-Z])/;
                            scope.ebErrorCode = letters.test(error.text) ? '' : error.text;
                            return $q.reject();
                        });
                    }, function(error) {
                        var letters = /([a-zA-Z])/;
                        scope.ebErrorCode = letters.test(error.text) ? '' : error.text;
                        return $q.reject();
                    });
                } else {
                    scope.authData = scope.ebModel;
                    scope.authPromise = {$$state: {status: 1}}
                }
            });

            scope.setTanErrors = function() {
                scope.tanErrors = angular.extend({}, scope.ebForm['tanX'].$error, scope.ebForm['tanY'].$error);
            };

            scope.$watch("ebForm.$submitted", function(newVal) {
                if (newVal == true && scope.authData && scope.authData.authenticationMethod == 'TAN') {
                    scope.setTanErrors();
                }
            }, true);

            scope.$watchGroup(["ngModel.tanX", "ngModel.tanY"], function(newVal) {
                if (newVal[0] || newVal[1]) {
                    if (scope.ebModel) {
                        scope.ebModel.credentials = newVal[0] + "" + newVal[1];
                        scope.ebForm["tanX"].$setValidity("errorCode", true);
                        scope.ebForm["tanY"].$setValidity("errorCode", true);
                    }
                    scope.setTanErrors();
                }
            }, true);

            scope.$watch("ngModel.sms", function(newVal) {
                if (newVal && scope.ebModel) {
                    scope.ebModel.credentials = newVal;
                    scope.ebForm["sms"].$setValidity("errorCode", true);
                }
                scope.ebErrorCode = undefined;
            }, true);

            scope.$watch("ngModel.token", function(newVal) {
                if (newVal && scope.ebModel) {
                    scope.ebModel.credentials = newVal;
                    scope.ebForm["token"].$setValidity("errorCode", true);
                }
                scope.ebErrorCode = undefined;
            }, true);

            scope.$watch("ebErrorCode", function(newVal) {
                if (newVal) {
                    var letters = /([a-zA-Z])/;
                    scope.ebErrorCode = letters.test(newVal) ? '' : newVal;
                    if (scope.ebForm["sms"]) {
                        scope.ebForm["sms"].$setValidity("errorCode", false);
                    }
                    if (scope.ebForm["token"]) {
                        scope.ebForm["token"].$setValidity("errorCode", false);
                    }
                    if (scope.ebForm["tanX"]) {
                        scope.ebForm["tanX"].$setValidity("errorCode", false);
                    }
                    if (scope.ebForm["tanY"]) {
                        scope.ebForm["tanY"].$setValidity("errorCode", false);
                    }

                    if (scope.authData && scope.authData.authenticationMethod == 'TAN') {
                        scope.setTanErrors();
                    }
                }
            }, true);

        }
    }
});
