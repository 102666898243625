/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbSmartphoneDetectionService
 *
 * @description
 * This service provides method `isSmartphone` to check if width or height of screen is as small as of smartphone.
 */

angular.module( 'halyk-ebanking-shared').factory( 'cbSmartphoneDetectionService', function( $window, viewConfig) {

    return {
        isSmartphone: function() {
            return $window.innerWidth <= viewConfig.SMARTFON_SCREEN_MAX_WIDTH || 
            		$window.innerHeight <= viewConfig.SMARTFON_SCREEN_MAX_WIDTH;
        }
    };

});
