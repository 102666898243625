/* globals angular */

/**
 * @ngdoc filter
 * @name ebFilterModule.filter:cbNrbIbanFilter
 *
 * @description
 * cbNrbIbanFilter
 */

angular.module( 'halyk-ebanking-shared').filter('cbNrbIbanFilter', function() {
  return function(text) {
    var patt = /^[a-z]{2}/gi;

    var formatByLength = function(number) {
       return formatMod4x(number, number.length == 27 ? 1 : 0, number.length % 4);
    }

    var formatMod4x = function(number, cut1, cut2) {
      var x1 = (cut1 > 0) ? number.substring(0, cut1) + ' ' : '';
      var x2 = (cut2 > 0) ? number.substring(cut1, cut2) + ' ' : '';
      var x3 = number.substring(cut2, number.length).replace(/(.{4})=?/g, '$& ').replace(/\s\s*$/, '');
      var x4 = "" + x1 + x2 + x3 + "";
      return x4;
    }

    if (text != undefined && angular.isString(text)) {
      var number = text.trim();
      number = number.replace(/\s+/g,'');
      //var patt = /^[a-z]{2}/gi;
      number = number.substring(0, 2) + number.substring(2, number.length).replace(/\D/g, '');
      if(!number.match(patt)) {
        return formatByLength(number);
      } else {
        return formatByLength(number);
      }
    }
    return text;
  };
});
