/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbListHeader
 * @restrict: 'EA'
 *
 * @description
 * Header of list.
 */

angular.module( 'halyk-ebanking-shared').directive('cbListHeader', function(pathService, ebTheming) {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbListHeader/cbListHeader.html",
        link: function (scope, element, attrs, ctrl, transclude) {
			ebTheming.applyTheme(element);
        }
    };
});
