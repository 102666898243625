/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbTabs
 * @restrict EA
 * @scope
 *
 * @description
 * $scope.tabsConfig = [
 *      {
 *          tabDivId: "allRates",
 *          title: "currencyrates.tab.all.rates"
 *      },
 *      {
 *          tabDivId: "ratesCalc",
 *          title: "currencyrates.tab.calculator"
 *      }
 *      ,
 *      {
 *          tabDivId: "ratesHistory",
 *          title: "currencyrates.tab.history",
 *          onClick: $scope.loadHistory
 *      }
 * ];
 * <cb-tabs config="tabsConfig"></cb-tabs>
 * @param {config} settings with tab name i18n codes, tab div-element id value, onClick function if needed
 */

angular.module( 'halyk-ebanking-shared').directive('cbTabs', function(pathService, ebTheming, $window, $timeout) {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
        	config: "="
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + "/directives/cbTabs/cbTabs.html",
        link: function postLink($scope, element, attrs) {
            ebTheming.applyTheme(element);

            $scope.openTab = function (evt, tabDivId, onActive) {
                // Declare all variables
                var i, tabcontent, tablinks;

                // Get all elements with class="tabcontent" and hide them
                tabcontent = document.getElementsByClassName("tabcontent");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }

                // Get all elements with class="tablinks" and remove the class "active"
                tablinks = document.getElementsByClassName("tablinks");
                if (evt) {//evt === null for initial execution
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" active", "");
                    }
                }

                // Show the current tab, and add an "active" class to the link that opened the tab
                document.getElementById(tabDivId).style.display = "block";
                if(evt){//evt === null for initial execution
                    evt.currentTarget.className += " active";
                }
                if(onActive){
                    onActive();
                }
            }

            $scope.openTab(null, $scope.config[0].tabDivId);//initial execution

        }


    };
});
