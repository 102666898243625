/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAccountsCurrencySelect
 * @restrict EA
 * @scope
 *
 * @description
 * Drop down for currency selection. Available currencies are loaded from `accoutsService`.
 *
 * @param {string} placeholder A placeholder displayed in the component when no currency is selected - passed through attribute value.
 *
 * @example
 * ```js
    <div>
        <cb-accounts-currency-select ng-model="ngModel.currency" placeholder="Select"></cb-accounts-currency-select>
    </div>
 * ```
*/

angular.module( 'halyk-ebanking-shared').directive('cbHalykCurrencySelect', function(
                                                                                    $compile
                                                                                , $rootScope
                                                                                , $timeout
                                                                                , $log
                                                                                , $http
                                                                                , $templateCache
                                                                                , pathService
                                                                                ) {

    return {
        restrict: 'EA',
        replace: true,
        scope: {
            placeholder: "@",
            currenciesList: "=",
            callback: "&"
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbHalykCurrencySelect/cbHalykCurrencySelect.html',

        link: function postLink(scope, element, attrs, ngModel) {

            // scope.params = {
            //     fields: 'currency',
            //     pageSize: 10000
            // };
            //
            scope.selectCurrency = function(item, model) {
                scope.callback(item);
            };

        }
    };
});
