/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSearchBar
 *
 * @description
 * Component for adding search functionality to miniapp views. It displays search icon. On click input field for search phrase is toggled.
 *
 * @param {string=} searchText Model for search phrase.
 * @param {boolean=} ebDisableSearch Model controlling availability of search.
 * @param {string} ebSearchPlaceholder Placeholder for search phrase.
 */

angular.module( 'halyk-ebanking-shared').directive('cbSearchBar', function(pathService, $window, $document, ebTheming, $rootScope){

	return {
		restrict: 'EA',
		replace: true,
		transclude: true,
		scope: {
			searchText: '=?',
            addUrl: '@', // chyba nie używane
            addTitle: '@', // chyba nie używane
            addIconName: '@', // chyba nie używane
            ebDisableAdd: "=?", // chyba nie używane
            ebDisableSearch: "=?",
            ebSearchPlaceholder: '@?'
		},
		templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSearchBar/cbSearchBar.html',
		link: function postLink(scope, element) {
            ebTheming.applyTheme(element);

            scope.searchShown = true;

            if(!angular.isDefined(scope.ebSearchPlaceholder)) {
                scope.ebSearchPlaceholder = 'Wpisz szukany tekst';
            }

            $document.on('click', function(evt) {
                if(evt.target.name !== 'accountSearchInput' && scope.searchShown) {
                    scope.$apply(scope.hideSearch);
                }
            });

            scope.hideSearch = function() {
                if(scope.searchText === undefined || scope.searchText.length == 0) {
                    scope.toggleSearchBox();
                }
            };

            scope.toggleSearchBox = function() {
                scope.searchShown = !scope.searchShown;
            };

            scope.showSearchBox = function(evt) {
                scope.toggleSearchBox();
                evt.stopPropagation();
            }
            $rootScope.$on('$stateChangeStart', function(){
                scope.searchText = "";
                if(scope.searchShown)
                scope.toggleSearchBox();
            });
            $rootScope.$on('pageChangeSuccess', function(){
                scope.searchText = "";
                if(scope.searchShown)
                    scope.toggleSearchBox();
            });
        }
	}
});
