/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbComplementaryIdInput
 *
 * @description
 * cbComplementaryIdInput
 */

angular.module( 'halyk-ebanking-shared').directive('cbComplementaryIdInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            complementaryIdType: '=',
            maxLength: '@',
            placeholder: '@',
            cbName: '@',
            cbForm: '=',
            dependentValue: '@'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbComplementaryIdInput/cbComplementaryIdInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input[0].value = ngModelController.$viewValue || '';
                });
            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            scope.maxLength = scope.maxLength || 14;

            scope.$watch('complementaryIdType', function(newVal) {
                if (newVal == 'NONE') {
                    ngModelController.$setViewValue('');
                    ngModelController.$render();
                    scope.isDisabled = true;
                } else {
                    scope.isDisabled = false;
                    if (angular.isDefined(newVal)) {
                        ngModelController.$validate();
                    }
                }


            });

        }
    };


});

angular.module( 'halyk-ebanking-shared').directive('cbComplementaryIdValidator', function() {

    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            var isPeselValid = function(pesel) {
                var weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
                var result = 0;

                for (var i = 0; i < weights.length; i++) {
                    result += pesel[i] * weights[i];
                }

                return (result % 10 == 0);
            }
            var isRegonValid = function(regon) {
                if (regon.length == 9) {
                    var weights = [8, 9, 2, 3, 4, 5, 6, 7];
                    var result = 0;

                    for (var i = 0; i < weights.length; i++) {
                        result += regon[i] * weights[i];
                    }

                    var mod = (result % 11 == 10 ? 0 : result % 11);
                    return (mod == regon[8]);
                } else {
                    var weights = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
                    var result = 0;

                    for (var i = 0; i < weights.length; i++) {
                        result += regon[i] * weights[i];
                    }

                    var mod = (result % 11 == 10 ? 0 : result % 11);
                    return (mod == regon[13]);
                }
            }
            var isIdCardValid = function(idCard) {
                var weights = [7, 3, 1, 7, 3, 1, 7, 3];
                var result = 0;
                var firstDigitIndex = idCard.match(/\d/).index;

                for (var i = 0; i < firstDigitIndex; i++) {
                    result += (idCard[i].charCodeAt(0) - 55) * weights[i];
                }
                for (var i = firstDigitIndex; i < weights.length; i++) {
                    result += idCard[i + 1] * weights[i];
                }

                return (result % 10 == idCard[3]);
            }
            var isPassportValid = function(passport) {
                for (var i = 0; i < passport.length; i++) {
                    if (i < 2) {
                        if (!isNaN(passport[i])) {
                            return false;
                        }
                    } else {
                        if (isNaN(passport[i])) {
                            return false;
                        }
                    }
                }

                var weights = [7, 3, 9, 1, 7, 3, 1, 7, 3];
                var result = 0;

                for (var i = 0; i < weights.length; i++) {
                    if (i < 2) {
                        result += (passport[i].charCodeAt(0) - 55) * weights[i];
                    } else {
                        result += passport[i] * weights[i];
                    }
                }

                return (result % 10 == 0);
            }
            var isNipValid = function(nip) {
                var weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                var result = 0;

                for (var i = 0; i < weights.length; i++) {
                    result += nip[i] * weights[i];
                }

                var mod = (result % 11 == 10 ? 0 : result % 11);
                return (mod == nip[9]);
            }

            ctrl.$validators.complementaryValidNIP = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);
                if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }
                    if (dependentValue == "NIP") {
                        if (value.length == 10) {
                            return isNipValid(value);
                        }
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            }

            ctrl.$validators.complementaryValidPESEL = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);
                if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }
                    if (dependentValue == "PESEL") {
                        if (value.length == 11) {
                            return isPeselValid(value);
                        }
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            }

            ctrl.$validators.complementaryValidREGON = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);
                if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }
                    if (dependentValue == "REGON") {
                        if (value.length == 14 || value.length == 9) {
                            return isRegonValid(value);
                        }
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            }

            ctrl.$validators.complementaryValidIDCARD = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);
                if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }
                    if (dependentValue == "ID_CARD") {
                        if (value.length == 9) {
                            return isIdCardValid(value.toUpperCase());
                        }
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            }

            ctrl.$validators.complementaryValidPASSPORT = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);
                if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }
                    if (dependentValue == "PASSPORT") {
                        if (value.length == 9) {
                            return isPassportValid(value.toUpperCase());
                        }
                        return false;
                    } else {
                        return true;
                    }
                }

                return true;
            }

            ctrl.$validators.complementaryValid = function (value) {
                var dependentValue = scope.$eval(attr.dependentValue);

            	if (angular.isDefined(value) && angular.isDefined(dependentValue)) {
                    if (value == null) {
                        value = '';
                    }

                    if (dependentValue == "NONE") {
                        return true;
                    } else if (dependentValue == "NIP") {

                        if (value.length == 10) {
                            return isNipValid(value);
                        }

                        return false;
                    } else if (dependentValue == "PESEL") {

                        if (value.length == 11) {
                            return isPeselValid(value);
                        }

                        return false;
                    } else if (dependentValue == "REGON") {

                        if (value.length == 14 || value.length == 9) {
                            return isRegonValid(value);
                        }

                        return false;
                    } else if (dependentValue == "ID_CARD") {

                        if (value.length == 9) {
                            return isIdCardValid(value.toUpperCase());
                        }

                        return false;
                    } else if (dependentValue == "PASSPORT") {

                        if (value.length == 9) {
                            return isPassportValid(value.toUpperCase());
                        }

                        return false;
                    }
            	}

                return true;
            };

        }
    }

});
