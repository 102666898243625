/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbAgeementSpoiler
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Content view header to be used on full screen widget. It contain navigation elements - close and back, view title/information
 * (icon, title and extra label/value), and action panel where view control elements (like "new", "edit", 'history", etc.) are transcluded.
 *
 * @param {string} headerText Text to displayed as header title/information.
 * @param {string} headerExtraLabel Optional label of extra data to be displayed in the header.
 * @param {string} headerExtraValue Optional extra data to be displayed in the header.
 * @param {string} backState Name of target state when user clicks back navigation.
 * @param {string} dashboardState Name of target state when user clicks close navigation.
 * @param {object=} params Optional parameters for backState navigation.
 * @param {string} appIcon Name of icon.
 * @param {boolean=} toRight Action panel aligment - if `true` panel will be aligned to the right, else to the left side of the header.
 * @param {boolean=} actionPanel Flag cotrolling appearance of action panel.
 * @param {boolean=} actionPanelHideXs Flag cotrolling appearance of action panel on very small devices.
 * @param {expression} onBack Action to be executed when user clicks back navigation.
 * @param {expression} onClose Action to be executed when user clicks close navigation.
 */

angular.module('halyk-ebanking-shared').directive('cbAgreementSpoiler', function (pathService, $sce, $modal, translate) {

        return {
            restrict: 'EA',
            transclude: true,
            scope: {
                textLink: "@",
                textSpoiler: "@",
                checkboxDisabled: "@",
                checkboxChecked: "@",
                agreement: "=ngModel"
            },
            templateUrl: pathService.generateTemplatePath("halyk-ebanking-shared") + '/directives/cbAgreementSpoiler/cbAgreementSpoiler.html',
            link: function preLink(scope, element, attrs) {
                scope.opened = false;
                scope.agreement = (scope.checkboxChecked === "true");
                scope.sce = $sce;

                scope.selectinput = function () {
                    scope.agreement = !scope.agreement;
                };

                scope.spoilerClick = function (agreement = false) {
                    // var spoiler = document.getElementsByClassName('agreement-spoiler-body')[0];
                    if(agreement){
                        return;
                    }
                    // spoiler.className = "agreement-spoiler-body agreement-spoiler-inline-shadow";
                    var acceptedDialog = $modal.open({
                        template: '<cb-text-spoiler eb-message="{{ message }}" eb-on-close="cancel()" callback="accept()" ></cb-text-spoiler>',
                        windowClass: 'eb-make-payment-dialog',
                        backdropClass: 'always-on',
                        controller: function ($scope) {
                            angular.extend($scope, {
                                message: scope.textSpoiler,
                                accept: function () {
                                    scope.agreement = true;
                                    acceptedDialog.dismiss();
                                },
                                cancel: function () {
                                    acceptedDialog.dismiss();
                                }
                            });
                        }
                    });

                }
            }
        }
    }
);
