/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbSimpleInput
 *
 * @restrict EA
 * @scope
 *
 * @description
 * Simple imput
 *  - standard width
 *  - no internal validation messages
 *  - validation message positioning
 *  - validation styles
 *  - triggering validation by additional field value changed
 *
 * @param {string=} ngModel Source model for input value. Every changes in source value will be published in the input.
 * @param {object=} dependentValue A model to observe - change of the model value triggers validation on ngModel.
 * @param {number} maxLength Maximum number of chars entered to the field (default 35).
 * @param {string} placeholder I18n key for placeholder text.
 * @param {string} cbName Name of the input field.
 * @param {object=} cbForm Model of parent form.
 * @param {expression} ebRequired Passes the expression to the fields ng-required attribute.
 * @param {expression} ebDisabled Passes the expression to the fields ng-disabled attribute.
 * @param {string} extraClass Extends the field class list by passing provided string to ng-class attribute.
 *
 * @example
 * ```js
        <div cb-simple-input
            cb-simple-input-validator
        <!-- validator -->
            cb-declaration-no-validator
        <!-- data field and form identification -->
            name="declarationNo" cb-name="declarationNo" cb-form="paymentTemplateAddForm" ng-model="formData.formModel.declarationNo"
        <!-- additional field that trigger validation (on field value changed) --
            dependent-value="formData.formModel.paymentType"
        <!-- example field required by validator -->
            insurance-payment-type="formData.formModel.paymentType"
        <!-- placeholder -->
            placeholder="db.payments.templates.new.step1.row.declaration_no.placeholder"
        <!-- max input length -->
            max-length=14
        <!-- required info -->
            eb-required="false">
        </div>

        <div cb-simple-input
            cb-declaration-no-validator
            name="declarationNo" cb-name="declarationNo" cb-form="paymentTemplateAddForm" ng-model="formData.formModel.declarationNo"
            dependent-value="formData.formModel.paymentType"
            insurance-payment-type="formData.formModel.paymentType"
            placeholder="db.payments.templates.new.step1.row.declaration_no.placeholder"
            max-length=14
            eb-required="false">
        </div>

        <div cb-simple-input
            cb-simple-input-validator
            name="declarationNo" cb-name="declarationNo" cb-form="paymentTemplateAddForm" ng-model="formData.formModel.declarationNo"
            placeholder="db.payments.templates.new.step1.row.declaration_no.placeholder"
            max-length=14
            eb-required="false">
        </div>
 * ```
 */

angular.module( 'halyk-ebanking-shared').directive('cbSimpleInput', function(pathService) {

    return {
        restrict: 'EA',
        require: 'ngModel',
        replace: true,
        scope: {
            ngModel: '=',
            dependentValue: '=',
            maxLength: '@',
            placeholder: '@',
            cbName: '@',
            cbForm: '=',
            ebRequired: '@',
            ebDisabled: '@',
            extraClass: '@'
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbSimpleInput/cbSimpleInput.html',
        link: function postLink(scope, element, attr, ngModelController) {

            var input = angular.element(element.querySelectorAll('.simple-input')[0]);

            scope.maxLength = scope.maxLength || 35;
            scope.ebDisabled = scope.ebDisabled || false;

            scope.theVal = '';

            ngModelController.$render = function() {
                scope.$evalAsync(function() {
                    input.value = ngModelController.$viewValue || '';
                    input[0].value = ngModelController.$viewValue || '';
                });

            };

            input.on('blur', function() {
                ngModelController.$setViewValue(this.value);
            });

            scope.$on("$destroy", function() {
                element.off();
            });

            scope.$watch('ngModel', function(newVal, oldVal) {
                if (newVal != null) {
                    scope.theVal = newVal;
                }
            });

            scope.$watch('ebDisabled', function(newVal, oldVal) {
                scope.ebDisabled = newVal;
            });

            scope.$watch('dependentValue', function(newVal) {
                if (angular.isDefined(newVal)) {
                    ngModelController.$validate();
                }
            });
        }
    }

});

angular.module( 'halyk-ebanking-shared').directive('cbSimpleInputValidator', function() {

    return {
        require: 'ngModel',
        link: function postLink(scope, element, attr, ctrl) {

            ctrl.$validators.required = function (value) {
                var ebRequired = scope.$eval(attr.ebRequired);

                if (ebRequired == true) {
                    if (value == undefined) {
                        return true;
                    } else if (value == null || value == '') {
                        return false;
                    } else {
                        return true;
                    }
                }
                return true;
            };

        }
    }

});
