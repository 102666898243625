/* globals angular */

/**
 * @ngdoc directive
 * @name halyk-ebanking-shared.directive:cbComplementaryIdTypeSelect
 *
 * @description
 * cbComplementaryIdTypeSelect
 */

angular.module( 'halyk-ebanking-shared').directive('cbComplementaryIdTypeSelect', function(pathService, cbComplementaryIdTypesDictionary, cbUtilsService) {

    return {
        restrict: 'EA',
        replace: false,
        transclude: true,
        scope: {
            ngModel: "=",
            monitorValue:"=?",
            complementaryIdTypesKey: "@",
            placeholder: "@",
            idNumberController: "="
        },
        templateUrl: pathService.generateTemplatePath('halyk-ebanking-shared') + '/directives/cbComplementaryIdTypeSelect/cbComplementaryIdTypeSelect.html',

        link: function postLink(scope, element, attrs) {
            scope.selectedValue = {
                selected : undefined
            };
            scope.complementaryIdTypes = cbComplementaryIdTypesDictionary;
            scope.$watch("ngModel", function (newValue, oldValue) {
                var reevaluating = false;
                if (newValue != undefined && (newValue != oldValue || scope.selectedValue.selected == undefined)) {
                     for (var i = 0; i < scope.complementaryIdTypes.length; i++) {
                        if(scope.complementaryIdTypes[i].key == newValue ) {
                            scope.selectedValue.selected = scope.complementaryIdTypes[i];
                            scope.ngModel = scope.complementaryIdTypes[i].model;
                            reevaluating = true;
                            break;
                        }
                    }
                    if(!reevaluating && scope.monitorValue != undefined) {
                        scope.monitorValue = newValue;
                    }
                    scope.selectedValue.selected = newValue;

                    cbUtilsService.clearServiceValidationErrors(scope.idNumberController);

                }
            });
            scope.selectComplementaryIdType = function(item, model) {
                scope.ngModel = model;
            };

        }
    };
});
