/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbLazyLoadingService
 *
 * @description
 * This service provides method `isLazyLoadingMode` to check if data should be loaded lazy.
 */

angular.module( 'halyk-ebanking-shared').factory( 'cbLazyLoadingService', function( cbSmartphoneDetectionService) {

    return {
        isLazyLoadingMode: function() {
            return cbSmartphoneDetectionService.isSmartphone();
        }
    };

});
