/* globals angular */

/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:maskCardAlternative
 *
 * @description
 * maskCardAlternative
 */
angular.module('halyk-ebanking-shared').filter('maskCardAlternative', function( cbSmartphoneDetectionService) {

  return function(number) {
    return 'XXXX XXXX XXXX '+number.substring(number.length -4, number.length);
  };

});


/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:maskCardNoFilter
 *
 * @description
 * maskCardNoFilter
 */

 angular.module('halyk-ebanking-shared').filter('maskCardNoFilter', function( cbSmartphoneDetectionService) {

    var formatShort = function(number) {
        return number.substring(0, 4) +
               " XXXX XXXX " +
               number.substring(number.length-4, number.length);
    };

    var formatLong = function(number) {
        return number.substring(0, 4) +
               " XXXX XXXX " +
               number.substring(number.length-4, number.length);
    };

    function replaceAll(text, find, replace) {
      return text.replace(new RegExp(find, 'g'), replace);
    }

    return function(number) {
        if (number != null) {
            number = number.replace(/\s+/g,'');
            number = replaceAll(number, ' ','');
            number = replaceAll(number, '&nbsp;','');
            if(number.length > 0) {
                if (cbSmartphoneDetectionService.isSmartphone()) {
                    return formatShort( number);
                }
                else {
                    return formatLong( number);
                }
            }
        }
        return null;
    };

});

/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:maskCardNoShortFilter
 *
 * @description
 * maskCardNoShortFilter
 */

 angular.module('halyk-ebanking-shared').filter('maskCardNoShortFilter', function( cbSmartphoneDetectionService) {

    var formatShort = function(number) {
        return number.substring(0, 4) +
               " X...X " +
               number.substring(number.length-4, number.length);
    };

    function replaceAll(text, find, replace) {
      return text.replace(new RegExp(find, 'g'), replace);
    }

    return function(number) {
        if (number != null) {
            number = number.replace(/\s+/g,'');
            number = replaceAll(number, ' ','');
            number = replaceAll(number, '&nbsp;','');
            if(number.length > 0) {
                return formatShort( number);
            }
        }
        return null;
    };

});

/**
 * @ngdoc filter
 * @name halyk-ebanking-shared.filter:maskCardNoFilter
 *
 * @description
 * maskCardNoFilter
 */

 angular.module('halyk-ebanking-shared').filter('maskCardNoShorterFilter', function( cbSmartphoneDetectionService) {

    var formatShort = function(number) {
        return number.substring(0, 4) +
               "..." +
               number.substring(number.length-4, number.length);
    };

    var formatLong = function(number) {
        return number.substring(0, 4) +
               "..." +
               number.substring(number.length-4, number.length);
    };

    var format4Digits = function(number) {
         return "..." +
             number.substring(number.length-4, number.length);
     };

    function replaceAll(text, find, replace) {
      return text.replace(new RegExp(find, 'g'), replace);
    }

    return function(number) {
        if (number != null) {
            number = number.replace(/\s+/g,'');
            number = replaceAll(number, ' ','');
            number = replaceAll(number, '&nbsp;','');
            if(number.length > 0) {
                return format4Digits( number);
                //if (cbSmartphoneDetectionService.isSmartphone()) {
                //    return formatShort( number);
                //}
                //else {
                //    return formatLong( number);
                //}
            }
        }
        return null;
    };

});
