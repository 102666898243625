/* globals angular */

/**
 * @ngdoc service
 * @name halyk-ebanking-shared.service:cbNotificationService

 * @description
 * An object providing methods for showing vaious kinds of dialogs.
 */
angular.module('halyk-ebanking-shared').service('cbNotificationService', function ($modal, $state, isNullCheckService, translate, viewStateService, cbPdfService) {
    var icoClassSuccess = 'eb-ico-confirm eb-halyk-theme confirm-ico';
    var icoClassError = 'eb-error eb-halyk-theme error-ico';
    var icoClassWarning = 'eb-error eb-halyk-theme ic-powiadomienia';
    var icoClassInfo = 'eb-confirm eb-halyk-theme eb-ico-widget-cards';

    return {
        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbNotificationService#showPaymentRealizationDialog
         *
         * @desciption
         * This method shows a dialog allowing user to confirm realization of a payment.
         *
         * @param {object} data An object of data to initiate state of the dialog - it should have two properties:
         *      `status` and `lastRealizationDesc` meaning current status of the payment (`"REJECTED"`, `"REALIZED"` or `"IN_PROGRESS"`)
         *      and last payment description text entered by user, respectively.
         * @param {object} payment The payment object.
         * @param {object} context An object containig related texts and states.
         */
        showPaymentRealizationDialog: function (data, payment, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}" eb-message="{{confirmationText}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = icoClassSuccess;
                    var confirmationText = '';
                    var showPdf = false;

                    var choiceNew = {
                        type: 'full-rounded positive primary eb-button eb-halyk-theme',
                        name: translate.property(context.text.createNew),
                        make: function () {
                            acceptedDialog.dismiss($state.go(context.state.paymentsForm, {formType: payment.transferType}));
                        }
                    };

                    var choiceDownload = {
                        type: 'full-rounded positive primary eb-button eb-halyk-theme download-ico',
                        name: translate.property(context.text.downloadConfirmation),
                        make: function () {
                            cbPdfService.getPaymentConfAnchor(payment).then(function (anchor) {
                                window.location = anchor.href;
                                window.target = anchor.target;
                            });
                        }
                    };

                    var choiceTemplate = {
                        type: 'full-rounded positive secondary eb-button eb-halyk-theme',
                        name: translate.property(context.text.saveTemplate),
                        make: function () {
                            acceptedDialog.dismiss(goToTemplate(payment));
                        }
                    };

                    var goToTemplate = function (payment) {
                        viewStateService.setInitialState(context.state.templateForm, payment);
                        viewStateService.setInitialState(context.state.templateForm + ".fromPayment", payment);
                        $state.go(context.state.templateForm);
                    }

                    var choiceBack = {
                        type: 'full-text link eb-button eb-halyk-theme hand',
                        name: translate.property(context.text.back),
                        make: function () {
                            acceptedDialog.dismiss($state.go(context.state.dashboard));
                        }
                    };

                    var choicesArray = [choiceNew];
                    if (context.pdfAvailable) {
                        choicesArray.push(choiceDownload);
                    }
                    if (!context.internal) {
                        choicesArray.push(choiceTemplate);
                    }
                    choicesArray.push(choiceBack);

                    switch (data.status) {
                        case "REJECTED":
                            icoClass = icoClassError;
                            confirmationText = translate.property(context.text.confirmationTextRejected) + ' ' + data.lastRealizationDesc;
                            break;
                        case "REALIZED":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property(context.text.confirmationTextRealized);
                            break;
                        case "IN_PROGRESS":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property(context.text.confirmationTextInProgress);
                            break;
                        default:
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property(context.text.confirmationTextDefault);
                    }

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: choicesArray,
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            });
        },

        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbNotificationService#showTemplateCreatedDialog
         *
         * @desciption
         * This method shows a dialog allowing user to to create new payment template.
         *
         * @param {object} context An object containig related texts and states.
         */
        showTemplateCreatedDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}" eb-message="{{confirmationText}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = icoClassSuccess;
                    var confirmationText = translate.property(context.text.confirmationText);

                    var goToTemplate = function () {
                        viewStateService.resetInitialState(context.state.templatesForm);
                        viewStateService.setInitialState(context.state.templatesForm, {
                            isEdition: false,
                            templateId: null
                        });
                        $state.go(context.state.templatesForm);
                    }

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.createNew),
                                make: function () {
                                    acceptedDialog.dismiss(goToTemplate());
                                }
                            },
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            });
        },

        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbNotificationService#showTemplateEditedDialog
         *
         * @desciption
         * This method shows a dialog presenting confirmation of a payment template changes.
         *
         * @param {object} context An object containig related texts and states.
         */
        showTemplateEditedDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    var icoClass = icoClassSuccess;

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        icoClass: icoClass,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },

        /**
         * ngdoc function
         * @name halyk-ebanking-shared.service:cbNotificationService#showTemplateDeletedDialog
         *
         * @desciption
         * This method shows a dialog presenting confirmation of a payment template deletion.
         *
         * @param {object} context An object containig related texts and states.
         */
        showTemplateDeletedDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    var icoClass = icoClassSuccess;

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        icoClass: icoClass,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },

        showDepositsAcceptedDialog: function (activeTab, data, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}" eb-message="{{::confirmationText}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = icoClassSuccess;
                    var confirmationText = '';
                    switch (data.status) {
                        case "REJECTED":
                            icoClass = icoClassError;
                            confirmationText = translate.property("deposits.new.notification.rejected") + data.reason;
                            break;
                        case "REALIZED":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property("deposits.new.notification.realized");
                            break;
                        case "INTRODUCED":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property(data.reason) || translate.property("deposits.new.notification.introduced");
                            break;
                        default:
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property("deposits.new.notification.default");
                            break;
                    }
                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                            if (activeTab === 3) {
                                acceptedDialog.dismiss($state.go('cu.accounts.content'));
                            } else {
                                acceptedDialog.dismiss($state.go('cu.deposits.content.active'));
                            }
                        },
                        // choices: [{
                        //         type: 'full-rounded positive primary eb-button eb-halyk-theme',
                        //         name: translate.property(context.text.createNew),
                        //         make: function () {
                        //             acceptedDialog.dismiss( $state.go(context.state.depositsForm, { currentObject: null }) );
                        //         }
                        //     }, {
                        //         type: 'full-text link eb-button eb-halyk-theme hand',
                        //         name: translate.property(context.text.back),
                        //         make: function () {
                        //             acceptedDialog.dismiss( $state.go(context.state.dashboard) );
                        //         }
                        //     }
                        // ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            })
        },
        showDepositsAcceptedTerminatedDialog: function (data, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{::\'deposits.new.notification.title\'|translate}}" eb-message="{{::confirmationText}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {

                    var icoClass = icoClassSuccess;
                    var confirmationText = '';
                    switch (data.status) {
                        case "REJECTED":
                            icoClass = icoClassError;
                            confirmationText = translate.property("deposits.terminate.notification.rejected") + data.reason;
                            break;
                        case "REALIZED":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property("deposits.terminate.notification.realized");
                            break;
                        case "WAITING":
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property("deposits.terminate.notification.default");
                            break;
                        default:
                            icoClass = icoClassSuccess;
                            confirmationText = translate.property("deposits.terminate.notification.default");
                            break;
                    }
                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-text link eb-button eb-halyk-theme hand',
                            name: translate.property(context.text.back),
                            make: function () {
                                acceptedDialog.dismiss($state.go(context.state.dashboard));
                            }
                        }
                        ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            })
        },

        showPrepaidAcceptedDialog: function (prepaid, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{::title}}" eb-message="{{::message}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-rounded positive primary eb-button eb-halyk-theme',
                            name: translate.property(context.text.createNew),
                            make: function () {
                                acceptedDialog.dismiss();
                                $state.go(context.state.createNew);
                            }
                        }, {
                            type: 'full-rounded positive secondary eb-button eb-halyk-theme',
                            name: translate.property(context.text.saveAsTemplate),
                            make: function () {
                                acceptedDialog.dismiss();
                                viewStateService.setInitialState(context.state.saveAsTemplate, {prepaid: prepaid});
                                $state.go(context.state.saveAsTemplate);
                            }
                        }, {
                            type: 'full-text link eb-button eb-halyk-theme hand',
                            name: translate.property(context.text.back),
                            make: function () {
                                acceptedDialog.dismiss();
                                $state.go(context.state.back);
                            }
                        }]
                    });
                }
            });
        },
        showPrepaidTemplateAcceptedDialog: function (template, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{::title}}" eb-message="{{::message}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                keyboard: false,
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                            if (context.state) {
                                $state.go(context.state.back);
                            }
                        },
                        choices: [{
                            type: 'full-rounded positive primary eb-button eb-halyk-theme',
                            name: translate.property(context.text.createNew),
                            make: function () {
                                acceptedDialog.dismiss();
                                if (context.state) {
                                    $state.go(context.state.createNew);
                                } else if (context.successCallBack) {
                                    context.successCallBack();
                                }
                            }
                        }, {
                            type: 'full-rounded full-text link eb-button eb-halyk-theme hand',
                            name: translate.property(context.text.back),
                            make: function () {
                                acceptedDialog.dismiss();
                                if (context.state) {
                                    $state.go(context.state.back);
                                }
                            }
                        }]
                    });
                }
            });
        },
        showPrepaidTemplateDeletedDialog: function (template, context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{::title}}" eb-message="{{::message}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-text link eb-button eb-halyk-theme hand',
                            name: translate.property(context.text.back),
                            make: function () {
                                acceptedDialog.dismiss();
                                $state.go(context.state.back);
                            }
                        }]
                    });
                }
            });
        },
        showWarningDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<eb-short-dialog eb-icon="error-icon"  eb-message="{{ message }}" eb-on-close="close()"></eb-short-dialog>',
                windowClass: '.eb-warning-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    angular.extend($scope, {
                        message: context.preparedMessage ? context.preparedMessage : translate.property(context.message),
                        close: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        },
        showPaymentEditedDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },
        showTopUpWarningDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="eb-error eb-halyk-theme error-ico" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-on-confirm="cancel()" eb-action-name="{{ confirmText }}"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassError,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        confirmText: translate.property(context.text.confirm),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        },
        showPaymentCancelledDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<eb-short-dialog eb-icon="ok-icon"  eb-message="{{ message }}" eb-on-close="close()"></eb-short-dialog>',
                windowClass: 'eb-short-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        message: translate.property(context.text.confirmationText),
                        close: function () {
                            if (context.state && context.state.dashboard) {
                                acceptedDialog.dismiss($state.go(context.state.dashboard));
                            } else {
                                acceptedDialog.dismiss();
                            }
                        }
                    });
                }
            });
        },
        showResultDialogAndReload: function (context) {
            var acceptedDialog = $modal.open({
                template: '<eb-short-dialog eb-icon="ok-icon"  eb-message="{{ message }}" eb-on-close="close()"></eb-short-dialog>',
                windowClass: 'eb-short-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        message: translate.property(context.text.confirmationText),
                        close: function () {
                            if (context.state && context.state.dashboard) {
                                acceptedDialog.dismiss($state.reload());
                            } else {
                                acceptedDialog.dismiss();
                            }
                        }
                    });
                }
            });
        },

        showBulkPaymentRealizationDialog: function (context, newPayment) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.newpayment),
                                make: function () {
                                    newPayment();
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.back));
                                }
                            }
                        ]
                    });
                }
            });
        },

        showNotificationRejectRemoveDialog: function (context, dismissCallback) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="eb-error eb-halyk-theme error-ico" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-on-confirm="cancel()" eb-action-name="{{ confirmText }}"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        confirmText: translate.property(context.text.confirm),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                    });
                }
            });
            acceptedDialog.result.then(function () {
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        },

        showNotificationConfirmRemoveDialog: function (context, successCallback, dismissCallback, successCallbackParams) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: context.icoInfo ? icoClassInfo : icoClassSuccess,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function () {
                if (successCallback) {
                    successCallback(successCallbackParams);
                }
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        },

        showAcceptDismissDialog: function (context, successCallback, dismissCallback, successCallbackParams) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassInfo,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded confirm-text positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded confirm-text positive secondary eb-button eb-halyk-theme',
                                name: translate.property(context.text.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function () {
                if (successCallback) {
                    successCallback(successCallbackParams);
                }
                console.log('Modal success at:' + new Date(), successCallbackParams);
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        },

        showDigitalCardSmsCodeVerificationDialog: function (context, card) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-sms-verification="true" eb-card="{{card}}"  eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassInfo,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        card: card,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                    });
                }
            });
        },

        showSmsCodeVerificationDialog: function (context, successCallbackParams) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-sms-dialog eb-title="{{ title }}" custom-input="customInput" callback="wrapCallback(sms)" eb-message="{{ message }}" eb-on-close="cancel()"></cb-choice-sms-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassInfo,
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        customInput: context.customInput,
                        wrapCallback: function (sms) {
                            return context.callback(sms, successCallbackParams).then(
                                function (result) {
                                    acceptedDialog.close();
                                    return result;
                                }
                            );
                        },
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                    });
                }
            });
        },

        showValidationErrorDialog: function (context, successCallback, dismissCallback) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{::message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassError,
                        title: translate.property(context.title),
                        message: translate.property(context.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function () {
                if (successCallback) {
                    successCallback();
                }
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        },


        showCreditRepaymentDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}" eb-message="{{confirmationText}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = icoClassSuccess;
                    var confirmationText = translate.property(context.text.confirmationText);

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            });
        },

        showCardConfirmationDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}"' +
                    ' eb-message="{{confirmationText}}" eb-on-close="cancel()"' +
                    ' eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = icoClassInfo;
                    var confirmationText = translate.property(context.text.confirmationText);

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'confirm-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.confirm),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            },
                            {
                                type: 'confirm-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.cancel),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            });
        },

        showPaybylinkCancelDialog: function (context) {
            console.log(context);
            var acceptedDialog = $modal.open({
                template: '<eb-choice-dialog eb-title="{{title}}" eb-message="{{confirmationText}}" eb-choices="choices" eb-on-close="cancel()"></eb-choice-dialog>',
                windowClass: 'eb-choice-dialog yes-no',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    var icoClass = 'eb-icon-confirm-success success';
                    var confirmationText = translate.property(context.text.message);

                    $scope.cancel = function () {
                        acceptedDialog.dismiss();
                    };

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.yes),
                                make: function () {
                                    (context.action.yes || angular.noop)();
                                    acceptedDialog.dismiss();
                                }
                            },
                            {
                                type: 'full-rounded positive secondary eb-button eb-halyk-theme',
                                name: translate.property(context.text.no),
                                make: function () {
                                    (context.action.no || angular.noop)();
                                    acceptedDialog.dismiss();
                                }
                            }
                        ],
                        icoClass: icoClass,
                        confirmationText: confirmationText
                    });
                }
            });
        },

        showPasswordChangedDialog: function (context, succes) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    var icoClass = succes === true ? icoClassSuccess : icoClassError;

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        icoClass: icoClass,
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },

        showCardActionDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    var icoClass = icoClassSuccess;

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        icoClass: icoClass,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },

        showCommonActionDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                controller: function ($scope) {
                    var icoClass = icoClassSuccess;

                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.confirmationText),
                        icoClass: icoClass,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-text link eb-button eb-halyk-theme hand',
                                name: translate.property(context.text.back),
                                make: function () {
                                    acceptedDialog.dismiss($state.go(context.state.dashboard));
                                }
                            }
                        ]
                    });
                }
            });
        },

        showTimeWarningDialog: function (context, successCallback, dismissCallback) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassWarning,
                        title: translate.property(context.title),
                        message: translate.property(context.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function () {
                if (successCallback) {
                    successCallback();
                }
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        }, showMessageDialog: function (context, successCallback) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{ message }}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: context.icon ? context.icon : icoClassSuccess,
                        title: translate.property(context.title),
                        message: context.translatedMessage || translate.property(context.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.ok),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function () {
                if (successCallback) {
                    successCallback();
                }
                console.log('Modal success at:' + new Date());
            });
        }
        , showInputDialog: function (context, successCallback, dismissCallback) {
            var result = "";
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{::message }}" eb-input="input" eb-on-close="cancel()" eb-choices="choices" ng-model="input"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: icoClassInfo,
                        title: translate.property(context.title),
                        message: translate.property(context.message),
                        input: context.input,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function (input) {

                if (successCallback) {
                    successCallback();
                }
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        }
        , showRadioButtonsDialog: function (context, successCallback, dismissCallback) {
            var result = "";
            var acceptedDialog = $modal.open({
                template: '<cb-radio-dialog eb-icon="{{icoClass}}" eb-title="{{ title }}" eb-message="{{::message }}" eb-on-close="cancel()" eb-choices="choices" radio-buttons="radioButtons"></cb-radio-dialog>',
                windowClass: 'eb-radio-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        icoClass: context.icoInfo ? context.icoInfo : icoClassInfo,
                        title: translate.property(context.title),
                        message: translate.property(context.message),
                        radioButtons: context.radioButtons,
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.confirm),
                                make: function () {
                                    acceptedDialog.close();
                                }
                            },
                            {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.cancel),
                                make: function () {
                                    acceptedDialog.dismiss();
                                }
                            }
                        ]
                    });
                }
            });
            acceptedDialog.result.then(function (input) {

                if (successCallback) {
                    successCallback();
                }
                console.log('Modal success at:' + new Date());
            }, function () {
                if (dismissCallback) {
                    dismissCallback();
                }
                console.log('Modal dismissed at: ' + new Date());
            });
        }
        , showMakePaymentDialog: function (context) {
            var result = "";
            var acceptedDialog = $modal.open({
                template: '<cb-make-payment-dialog eb-title="{{ title }}" eb-on-close="cancel()" callback="wrapCallback(sms)" from-account="fromAccount" payment-list="paymentList" is-legal-entity="isLegalEntity" is-individual-customer="isIndividualCustomer"></cb-make-payment-dialog>',
                windowClass: 'eb-make-payment-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    $scope.incorrectSmsInput = 0;

                    angular.extend($scope, {
                        title: translate.property(context.title),
                        fromAccount: context.fromAccount,
                        paymentList: context.paymentList,
                        isLegalEntity: context.isLegalEntity,
                        isIndividualCustomer: context.isIndividualCustomer,
                        wrapCallback: function (sms) {
                            return context.callback(sms).then(
                                function (result) {
                                    if (result.resultCode == 'server.messages.otp.validation.failed') {
                                        $scope.incorrectSmsInput++;

                                        if ($scope.incorrectSmsInput >= 3) {
                                            result.resultCode = 'server.messages.otp.validation.limit';
                                            result.payABill = false;
                                            acceptedDialog.close();
                                            return result;
                                        }

                                        result.payABill = true;
                                        return result;
                                    }
                                    acceptedDialog.close();
                                    return result;
                                }
                            );
                        },
                        cancel: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        }

        , showPensionDialog: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-show-pension-dialog show-pension="showPension" eb-on-close="cancel()" callback="wrapCallback(checked)"></cb-show-pension-dialog>',
                windowClass: 'eb-show-pension-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {

                    angular.extend($scope, {
                        showPension: context.showPension,
                        wrapCallback: function (checked) {
                            context.callback(checked);
                            acceptedDialog.close();
                        },
                        cancel: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        }

        , showPensionNotifications: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-show-pension-notifications eb-on-close="cancel()" callback="updateNotReadCount(notReadCount)" eb-notifications-list="notificationsList"></cb-show-pension-notifications>',
                windowClass: 'eb-show-pension-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        notificationsList: context.notificationsList,
                        updateNotReadCount: (notReadCount) => context.callback(notReadCount),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        }
        , showConfirmBeforeUnload: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-choice-dialog eb-icon="{{icoClass}}" eb-title="{{title}}" eb-message="{{message}}" eb-on-close="cancel()" eb-choices="choices"></cb-choice-dialog>',
                windowClass: 'eb-choice-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        title: translate.property(context.text.title),
                        message: translate.property(context.text.message),
                        cancel: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-rounded positive primary eb-button eb-halyk-theme',
                            name: translate.property(context.text.continue),
                            make: function () {
                                context.continueCallback();
                                acceptedDialog.close();
                            }
                        }
                            , {
                                type: 'full-rounded positive primary eb-button eb-halyk-theme',
                                name: translate.property(context.text.close),
                                make: function () {
                                    if (angular.isDefined(context.closeCallback) && angular.isFunction(context.closeCallback)) {
                                        context.closeCallback();
                                    }
                                    acceptedDialog.close();
                                }
                            }
                        ],
                        icoClass: icoClassInfo
                    });
                }
            });
        }
        , showPaymentListDialog: function (context) {
            var result = "";
            var acceptedDialog = $modal.open({
                template: '<cb-payment-list-dialog eb-title="{{ title }}" eb-on-close="cancel()" callback="wrapCallback( sms )" payment-list="paymentList" button-name="buttonName" who-i-am="whoIAm" is-not-enough-payment-limit="isNotEnoughPaymentLimit" back-state="{{ backState }}" no-payments-to-send="noPaymentsToSend" not-enough-money="notEnoughMoney"></cb-payment-list-dialog>',
                windowClass: 'eb-make-payment-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        title: translate.property(context.title),
                        paymentList: context.paymentList,
                        buttonName: context.buttonName,
                        whoIAm: context.whoIAm,
                        isNotEnoughPaymentLimit: context.isNotEnoughPaymentLimit,
                        noPaymentsToSend: context.noPaymentsToSend,
                        notEnoughMoney: context.notEnoughMoney,
                        backState: context.backState,
                        wrapCallback: function (sms) {
                            return context.callback(sms).then(
                                function (result) {
                                    if (result != null
                                        && result.paymentResultList != null
                                        && isNullCheckService.isNotEmptyArray(result.paymentResultList)) {
                                    } else {
                                        //acceptedDialog.close();
                                    }
                                    return result;
                                }
                            );
                        },
                        cancel: function () {
                            acceptedDialog.dismiss();
                        }
                    });
                }
            });
        },
        showMobileAppPromotion: function (context) {
            var acceptedDialog = $modal.open({
                template: '<cb-mobile-app-promotion-dialog eb-on-close="close()" eb-choices="choices"></cb-mobile-app-promotion-dialog>',
                windowClass: 'eb-short-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope) {
                    angular.extend($scope, {
                        message: translate.property(context.text),
                        close: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-rounded positive secondary eb-button eb-halyk-theme',
                            name: translate.property(context.secondaryBtn),
                            make: function () {
                                acceptedDialog.dismiss();
                            }
                        }, {
                            type: 'full-rounded positive primary eb-button eb-halyk-theme',
                            name: translate.property(context.primaryBtn),
                            make: function () {
                                if (context.device == 'android') {
                                    window.open('https://play.google.com/store/apps/details?id=com.halyk.mobileapp', '_blank');
                                } else if (context.device == 'ios') {
                                    window.open('https://apps.apple.com/us/app/halyk-bank-georgia/id1496747464', '_blank');
                                }
                                acceptedDialog.dismiss();
                            }
                        }]
                    });
                }
            });
        },

        showTermsAgreement: function (context, successCallback) {
            var acceptedDialog = $modal.open({
                template: '<cb-terms-agreement-dialog callback="wrapCallback(agreement)" terms="{{terms}}" eb-message="{{message}}" eb-title="{{title}}"  eb-on-close="close()" eb-choices="choices"></cb-terms-agreement-dialog>',
                windowClass: 'eb-short-dialog',
                backdropClass: 'always-on',
                backdrop: 'static',
                controller: function ($scope, $timeout) {
                    angular.extend($scope, {
                        message: translate.property(context.message),
                        title: translate.property(context.title),
                        terms: context.terms,
                        wrapCallback: function (agreement){
                            acceptedDialog.close();
                            successCallback(agreement);
                        },
                        close: function () {
                            acceptedDialog.dismiss();
                        },
                        choices: [{
                            type: 'full-rounded positive secondary eb-button eb-halyk-theme',
                            name: translate.property(context.secondaryBtn),
                            make: function () {
                                acceptedDialog.dismiss();
                            }
                        }, {
                            type: 'full-rounded positive primary eb-button eb-halyk-theme',
                            name: translate.property(context.primaryBtn),
                            make: function (){
                                $timeout(function (){
                                    document.getElementsByClassName('agreement-spoiler-link')[0].click();
                                })
                            }
                        }]
                    });
                }
            });
        },

    }
});
