angular.module( 'halyk-ebanking-shared').value('cbInsurancePaymentTypesDictionary', [{
    key: "TYPE_S",
    model: "TYPE_S"
}, {
    key: "TYPE_M",
    model: "TYPE_M"
}, {
    key: "TYPE_U",
    model: "TYPE_U"
}, {
    key: "TYPE_T",
    model: "TYPE_T"
}, {
	key: "TYPE_D",
    model: "TYPE_D"
}, {
    key: "TYPE_E",
    model: "TYPE_E"
}, {
    key: "TYPE_A",
    model: "TYPE_A"
}, {
    key: "TYPE_B",
    model: "TYPE_B"
}]);
